import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { constants, translations } from 'gipsy-misc'
import { datePickerStyles, FixedTooltip, Icon, WhenDatePicker } from 'gipsy-ui'

import { ButtonContainer, ButtonContent, ButtonPopup } from './commonUIComponents'

interface Props {
  onSelect: (date: string) => void
  shrinked: boolean
}

export default function ScheduleButton({ onSelect, shrinked }: Props) {
  const [active, setActive] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)

  const toggleActive = () => {
    setActive((prev) => !prev)
  }

  const handleChange = (dateData: { paramName: string; value: string }) => {
    onSelect(dateData.value)
    setActive(false)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (!containerRef?.current?.contains(e.target as Node)) {
      setActive(false)
    }
  }

  return (
    <ButtonContainer active={active} fillIcon onClick={toggleActive} ref={containerRef}>
      <ButtonContent>
        {shrinked ? (
          <FixedTooltip
            content={translations.general.schedule}
            horizontalOffset={-10}
            horizontalPosition='left'
            portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
            style={{
              padding: '8px',
            }}
            verticalOffset={-12}
            verticalPosition='bottom'>
            <Icon className='ButtonIcon ButtonIcon--shrinked' icon='CalendarEmpty' size={14} />
          </FixedTooltip>
        ) : (
          <>
            <Icon className='ButtonIcon' icon='CalendarEmpty' size={14} />
            {translations.general.schedule}
          </>
        )}
      </ButtonContent>
      {active && (
        <StyledButtonPopup onClickOutside={handleClickOutside}>
          <WhenDatePicker className='SchedulePicker' onChange={handleChange} value={moment().format()} />
        </StyledButtonPopup>
      )}
    </ButtonContainer>
  )
}

const StyledButtonPopup = styled(ButtonPopup)`
  border-radius: 16px;
  ${datePickerStyles};

  .SchedulePicker {
    border-radius: 16px;
  }
`

StyledButtonPopup.displayName = 'StyledButtonPopup'
