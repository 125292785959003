import React from 'react'

import { translations } from 'gipsy-misc'
import { Tag } from 'gipsy-misc/types'
import { TagDropdownSelector } from 'gipsy-ui'

import { IndeterminateStateMap, ItemsPerEntry } from 'logic/batchActions'

import DropdownButton from './DropdownButton'
interface Props {
  activeTags: Tag[]
  onSelect: (tags: Tag[], indeterminateIds: IndeterminateStateMap) => void
  selectedItemsPerTag: ItemsPerEntry
  shrinked: boolean
  totalSelectedItems: number
}

export default function ProjectsButton({
  activeTags,
  onSelect,
  selectedItemsPerTag,
  shrinked,
  totalSelectedItems,
}: Props) {
  return (
    <DropdownButton<Tag>
      activeItems={activeTags}
      buttonText={translations.general.tags}
      dropdownComponent={TagDropdownSelector}
      emptyLabel={translations.tags.noTags}
      icon='Tag'
      onSelect={onSelect}
      selectedItemsPerActiveEntry={selectedItemsPerTag}
      shrinked={shrinked}
      totalSelectedItems={totalSelectedItems}
    />
  )
}
