import moment from 'moment'

import { shortDayNames } from 'models/date'
import { Schedules } from 'models/recurrency'
import translations from 'translations'
import { areElementsUniqueAndMatch } from 'utils/integers'
import { updateMonthlyDetailsForCurrentWhenDate } from 'utils/recurrency/monthly'
import { MonthlyRecurrence, RecurrencyDetails, WeeklyRecurrence } from 'utils/recurrency/types'
import { updateWeeklyDetailsForCurrentWhenDate } from 'utils/recurrency/weekly'
import { capitalize } from 'utils/string'

export const getDaysChosenLabel = (days: number[]): string => {
  const chosenDays = days.map((dayNumber: number) => {
    const dayName = shortDayNames[dayNumber]
    return capitalize(dayName)
  })

  return translations.sprint.recurrency.weeklyOn.replace('__', chosenDays.join(', '))
}

export const getRecurrencyLabel = (details: RecurrencyDetails): string => {
  switch (details.schedule) {
    case Schedules.Daily: {
      return translations.sprint.recurrency.daily
    }

    case Schedules.Weekly: {
      details = details as WeeklyRecurrence
      const { days } = details.weeklyOptions

      switch (true) {
        case days.length === 1:
          return translations.sprint.recurrency.weeklyOn.replace('__', capitalize(shortDayNames[days[0]]))
        case days.length === 5 && areElementsUniqueAndMatch(days, [1, 2, 3, 4, 5]):
          return translations.sprint.recurrency.everyWeekday
        default:
          return getDaysChosenLabel(days)
      }
    }

    case Schedules.Monthly: {
      details = details as MonthlyRecurrence
      const { day, onOrdinal, onSchedule } = details.monthlyOptions

      if (onSchedule) {
        const dayName = translations.days.label[onSchedule]
        const label = translations.sprint.recurrency.monthlyOn.replace('__', dayName)
        return label.replace('#', translations.sprint.recurrency.dayNumber[onOrdinal])
      } else {
        return `${translations.general.monthlyOnThe} ${translations.general.day.singular} ${day}`
      }
    }

    default: {
      return ''
    }
  }
}

export const getOrdinalNumbersForDayInMonth = (date: string): number[] => {
  const momentDate = moment(date)
  const targetDayName = momentDate.format('dddd')
  const ordinalNumbers: number[] = []
  const currentMonthDay = moment(momentDate).startOf('month')
  let occurrenceCount = 0

  while (currentMonthDay.format('dddd') !== targetDayName) {
    currentMonthDay.add(1, 'day')
  }

  while (currentMonthDay.isSame(momentDate, 'month')) {
    occurrenceCount++
    ordinalNumbers.push(occurrenceCount)
    currentMonthDay.add(7, 'days')
  }

  ordinalNumbers[ordinalNumbers.length - 1] = -1
  return ordinalNumbers
}

export const getOrdinalNumberForStartTime = (startTime: string): number => {
  const ordinalNumbersForMonth = getOrdinalNumbersForDayInMonth(startTime)
  const momentStartTime = moment(startTime)
  const currentMonthDay = moment(momentStartTime).startOf('month')
  const dayName = momentStartTime.format('dddd')
  let ocurrenceIdx = -1

  while (currentMonthDay.isSameOrBefore(momentStartTime)) {
    if (currentMonthDay.format('dddd') === dayName) {
      ocurrenceIdx++
    }

    currentMonthDay.add(1, 'day')
  }

  return ordinalNumbersForMonth[ocurrenceIdx]
}

export const updateDetailsForCurrentWhenDate = (whenDate: string, details: RecurrencyDetails): RecurrencyDetails => {
  if (details.schedule === Schedules.Monthly) {
    return updateMonthlyDetailsForCurrentWhenDate(whenDate, details as MonthlyRecurrence)
  }

  if (details.schedule === Schedules.Weekly) {
    return updateWeeklyDetailsForCurrentWhenDate(whenDate, details as WeeklyRecurrence)
  }

  return details
}
