import moment from 'moment'
import { produce } from 'immer'

import { getOrdinalNumberForStartTime } from 'utils/recurrency/details'
import { MonthlyOptions, MonthlyRecurrence, MonthlySchedule } from 'utils/recurrency/types'

export const getMonthlyFirstOccurrence = (creationDate: string, options: MonthlyOptions): string => {
  if (options.day) {
    return getMonthlyFirstOccurrenceForDayOfMonth(creationDate, options)
  } else if (options.onSchedule !== undefined) {
    return getMonthlyFirstOccurrenceForSchedule(creationDate, options)
  } else {
    return ''
  }
}

const getMonthlyFirstOccurrenceForDayOfMonth = (creationDate: string, options: MonthlyOptions): string => {
  const day = options.day as number

  const creationMoment = moment(creationDate)

  const dateThisMonth = moment()
    .set('year', creationMoment.year())
    .set('month', creationMoment.month())
    .set('date', day)

  if (day >= creationMoment.date()) {
    // happens this month
    return dateThisMonth.format('YYYY-MM-DD')
  }
  // happens next month
  return dateThisMonth.add(1, 'month').format('YYYY-MM-DD')
}

const getMonthlyFirstOccurrenceForSchedule = (creationDate: string, options: MonthlyOptions): string => {
  const schedule = options.onSchedule as MonthlySchedule
  const ordinal = options.onOrdinal as number

  const creationMoment = moment(creationDate)
  const monthStart = moment(creationDate).startOf('month')

  let initDate = monthStart
  let toCount = ordinal // the number we have to count before selecting the date
  let increment = 1

  // for a negative ordinal ('last') we switch the initDate to the end of the month,
  // the leftToCount to 1 and the increment to -1 (we go backwards)
  if (ordinal < 0) {
    initDate = initDate.add(1, 'month').add(-1, 'day')
    toCount = 1
    increment = -1
  }

  let candidate = scanMonth(initDate.format('YYYY-MM-DD'), schedule, toCount, increment)

  // if it happens this month, we return it
  if (moment(candidate).date() >= creationMoment.date()) {
    return moment(candidate).format('YYYY-MM-DD')
  }

  // use next month
  initDate = monthStart.add(1, 'month')
  if (ordinal < 0) {
    initDate = initDate.add(1, 'month').add(-1, 'day')
  }
  candidate = scanMonth(initDate.format('YYYY-MM-DD'), schedule, toCount, increment)

  return moment(candidate).format('YYYY-MM-DD')
}

// scanMonth scans the given month to find the date matching the schedule
// it checks if each date follows the given schedule,
const scanMonth = (
  initDate: string, // the first date to scan
  schedule: MonthlySchedule,
  toCount: number,
  increment: number
): string => {
  const initMoment = moment(initDate)
  let date = moment(initDate)
  let leftToCount = toCount

  // we scan the current month by `increment` and stop either when the month is finished, or we have counted
  // toCount occurrences of a date verifying the predicate
  while (date.month() == initMoment.month() && leftToCount > 0) {
    const ok = dayMeetsCondition(date.format('YYYY-MM-DD'), schedule)
    if (ok) {
      leftToCount--
    }
    if (leftToCount > 0) {
      date = date.add(increment, 'day')
    }
  }
  if (leftToCount > 0) {
    return ''
  }
  return date.format('YYYY-MM-DD')
}

const isWeekendDay = (weekdayNumber: number): boolean => {
  // will need to consider i18n (Israel, Arab countries, etc.) http://www.geographylists.com/list19s.html
  return weekdayNumber == 0 || weekdayNumber == 6
}

const dayMeetsCondition = (date: string, schedule: MonthlySchedule): boolean => {
  const dateMoment = moment(date)
  const dayOfWeek = getDayOfWeek(schedule)
  if (dayOfWeek > -1) {
    return dateMoment.day() == dayOfWeek
  }
  switch (schedule) {
    case MonthlySchedule.Day:
      return true
    case MonthlySchedule.Weekday:
      return !isWeekendDay(dateMoment.day())
    case MonthlySchedule.WeekendDay:
      return isWeekendDay(dateMoment.day())
    default:
      return false
  }
}

const getDayOfWeek = (schedule: MonthlySchedule): number => {
  switch (schedule) {
    case MonthlySchedule.Sunday:
      return 0
    case MonthlySchedule.Monday:
      return 1
    case MonthlySchedule.Tuesday:
      return 2
    case MonthlySchedule.Wednesday:
      return 3
    case MonthlySchedule.Thursday:
      return 4
    case MonthlySchedule.Friday:
      return 5
    case MonthlySchedule.Saturday:
      return 6
    default:
      return -1
  }
}

export const getMonthlyScheduleUntil = (start: string, details: MonthlyRecurrence, until: string): string[] => {
  let result: string[] = []
  const options = details.monthlyOptions
  if (options.day) {
    result = getMonthlyScheduleForDayOfMonthUntil(start, details, until)
  } else if (options.onSchedule !== undefined) {
    result = getMonthlyScheduleForScheduleUntil(start, details, until)
  }
  return result
}

const getMonthlyScheduleForDayOfMonthUntil = (start: string, details: MonthlyRecurrence, until: string): string[] => {
  const every = details.monthlyOptions.every
  const firstOccurrence = details.firstOccurrence
  const momentStart = moment(start)
  const momentUntil = moment(until)

  const scheduledDates: string[] = []
  let currentOccurrence = 0
  let next = moment(firstOccurrence)
  while (next.isSameOrBefore(momentUntil)) {
    if (next.isSameOrAfter(momentStart) && next.date() === moment(firstOccurrence).date()) {
      scheduledDates.push(next.format('YYYY-MM-DD'))
    }
    currentOccurrence += 1
    next = moment(firstOccurrence).add(currentOccurrence * every, 'month')
  }
  return scheduledDates
}

const getMonthDiff = (d1: string, d2: string): number => {
  const d1Moment = moment(d1)
  const d2Moment = moment(d2)
  return 12 * (d1Moment.year() - d2Moment.year()) + d1Moment.month() - d2Moment.month()
}

const getMonthlyScheduleForScheduleUntil = (start: string, details: MonthlyRecurrence, until: string): string[] => {
  const options = details.monthlyOptions
  const schedule = options.onSchedule as MonthlySchedule
  const ordinal = options.onOrdinal as number
  const firstOccurrence = details.firstOccurrence
  const every = options.every

  const startFirstDayOfMonth = moment(start).startOf('month').format('YYYY-MM-DD')
  const firstOccurrenceFirstDayOfMonth = moment(firstOccurrence).startOf('month').format('YYYY-MM-DD')

  const monthDiff = getMonthDiff(startFirstDayOfMonth, firstOccurrenceFirstDayOfMonth)
  const quot = monthDiff / every
  let nextMonthDiff = quot * every

  let initDate = moment(firstOccurrenceFirstDayOfMonth).add(nextMonthDiff, 'month')
  let toCount = ordinal // the number we have to count before selecting the date
  let increment = 1

  // for a negative ordinal ('last') we switch the initDate to the end of the month,
  // the leftToCount to 1 and the increment to -1 (we go backwards)
  if (ordinal < 0) {
    initDate = initDate.add(1, 'month').add(-1, 'day')
    toCount = 1
    increment = -1
  }

  // we only need to schedule the next 2 occurrences
  let next = scanMonth(initDate.format('YYYY-MM-DD'), schedule, toCount, increment)

  const scheduledDates: string[] = []

  while (next <= until) {
    if (next >= start) {
      scheduledDates.push(next)
    }

    nextMonthDiff += every
    initDate = moment(firstOccurrenceFirstDayOfMonth).add(nextMonthDiff, 'month')
    if (ordinal < 0) {
      initDate = initDate.add(1, 'month').add(-1, 'day')
    }

    next = scanMonth(initDate.format('YYYY-MM-DD'), schedule, toCount, increment)
  }

  return scheduledDates
}

export const updateMonthlyDetailsForCurrentWhenDate = (
  whenDate: string,
  details: MonthlyRecurrence
): MonthlyRecurrence => {
  const options = details.monthlyOptions
  const momentWhenDate = moment(whenDate)
  const whenDateName = momentWhenDate.format('dddd').toLocaleLowerCase()
  const whenDateOrdinal = getOrdinalNumberForStartTime(whenDate)
  const updatedFirstOcurrence = momentWhenDate.format('YYYY-MM-DD')

  const { day, onOrdinal, onSchedule } = options

  if (onSchedule && onSchedule !== whenDateName) {
    return produce(details, (draft: MonthlyRecurrence) => {
      draft.firstOccurrence = updatedFirstOcurrence
      draft.monthlyOptions.onOrdinal = whenDateOrdinal
      draft.monthlyOptions.onSchedule = whenDateName as MonthlySchedule
    })
  }

  if (onOrdinal && onOrdinal !== whenDateOrdinal) {
    return produce(details, (draft: MonthlyRecurrence) => {
      draft.firstOccurrence = updatedFirstOcurrence
      draft.monthlyOptions.onOrdinal = whenDateOrdinal
    })
  }

  if (day && day !== momentWhenDate.date()) {
    return produce(details, (draft: MonthlyRecurrence) => {
      draft.firstOccurrence = updatedFirstOcurrence
      draft.monthlyOptions.day = momentWhenDate.date()
    })
  }

  return details
}
