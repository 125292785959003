import moment from 'moment'
import { produce } from 'immer'

import { WeeklyRecurrence } from 'utils/recurrency/types'

import { getWeekDay, addDaysToDate } from 'utils/date'

export const getWeeklyFirstOccurrence = (creationDate: string, details: WeeklyRecurrence): string => {
  const dayMap = toMap(details.weeklyOptions.days)
  const date = moment(creationDate)

  while (!dayMap.get(date.day())) {
    date.add(1, 'day')
  }

  return date.format('YYYY-MM-DD')
}

export const getWeeklyScheduleUntil = (start: string, details: WeeklyRecurrence, until: string): string[] => {
  const options = details.weeklyOptions
  let result: string[] = []

  const nextWeek = getStartDateOfNextWeek(start, details)
  let startOfNextWeek: string = nextWeek.startOfNextWeek
  const isStartInNextWeek: boolean = nextWeek.isStartInNextWeek
  const dayMap = toMap(options.days)
  if (isStartInNextWeek) {
    for (let i = 0; i < 7 - getWeekDay(start); i++) {
      const current = addDaysToDate(start, i)
      result = appendUntilIfInMap(result, current, dayMap, until)
    }
  }
  while (startOfNextWeek <= until) {
    for (let i = 0; i < 7; i++) {
      const current = addDaysToDate(startOfNextWeek, i)
      result = appendUntilIfInMap(result, current, dayMap, until)
    }
    startOfNextWeek = addDaysToDate(startOfNextWeek, options.every * 7)
  }

  return result
}

// // getStartDateOfNextWeek return the date of the beginning of the next week for which the taks should be scheduled.
// // it also returns whether or not start is in a week that should be considered for scheduling.
const getStartDateOfNextWeek = (
  start: string,
  details: WeeklyRecurrence
): { isStartInNextWeek: boolean; startOfNextWeek: string } => {
  const firstOccMoment = moment(details.firstOccurrence)
  const firstOccWeekday = firstOccMoment.day()
  const startMoment = moment(start)
  const startWeekday = startMoment.day()
  const deltaFirstOcc = startMoment.diff(firstOccMoment, 'days')

  let numDiffWeek = 0
  if (firstOccWeekday > startWeekday) {
    numDiffWeek = Math.floor(deltaFirstOcc / 7) + 1
  } else {
    numDiffWeek = Math.floor(deltaFirstOcc / 7)
  }

  const options = details.weeklyOptions
  const remainderDiffWeeks = numDiffWeek % options.every

  const nextPlanWeek = options.every - remainderDiffWeeks
  const nextPlanDay = nextPlanWeek * 7 - startWeekday
  const startOfNextWeek = addDaysToDate(start, nextPlanDay)

  const result = { startOfNextWeek, isStartInNextWeek: remainderDiffWeeks === 0 }

  return result
}

const toMap = (intArr: number[]): Map<number, boolean> => {
  const m = new Map()
  for (const i of intArr) {
    m.set(i, true)
  }
  return m
}

const appendUntilIfInMap = (dates: string[], date: string, dayMap: Map<number, boolean>, until: string): string[] => {
  const dateMoment = moment(date)
  const dateWeekday = dateMoment.day()
  if (dayMap.get(dateWeekday) && date <= until) {
    dates.push(date)
  }
  return dates
}

export const updateWeeklyDetailsForCurrentWhenDate = (
  whenDate: string,
  details: WeeklyRecurrence
): WeeklyRecurrence => {
  const options = details.weeklyOptions
  const momentWhenDate = moment(whenDate)
  const whenDateWeekDay = momentWhenDate.day()
  const updatedFirstOcurrence = momentWhenDate.format('YYYY-MM-DD')

  const { days } = options

  if (days.length === 1 && days[0] !== whenDateWeekDay) {
    return produce(details, (draft: WeeklyRecurrence) => {
      draft.firstOccurrence = updatedFirstOcurrence
      draft.weeklyOptions.days = [whenDateWeekDay]
    })
  }

  return details
}
