import { createAction } from 'redux-actions'

import types from './types'

const _hideUndoToast = createAction(types.HIDE_UNDO_TOAST)
const _launchUndoToast = createAction(types.LAUNCH_UNDO_TOAST)

export const hideUndoToast = () => (dispatch) => {
  dispatch(_hideUndoToast())
}

export const launchUndoToast = (toastSettings) => (dispatch) => {
  dispatch(_launchUndoToast(toastSettings))
}
