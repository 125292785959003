import React from 'react'
import styled, { css } from 'styled-components'

import {
  AppTitleContainer,
  AppTitle,
  AppDescription,
  IntegrationRow,
  IntegrationHeaderContent,
} from 'features/integrations/components'

import { PrimaryButton } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

export const BlockHeader = React.memo(
  ({ hasInvalidCredentials, iconSrc, title, description, isInstalled, installLabel, onClickInstall }) => {
    return (
      <IntegrationRow>
        <IntegrationIconContainer>
          <IntegrationIcon alt={'logo'} src={iconSrc} />
        </IntegrationIconContainer>
        <StyledIntegrationHeaderContent>
          <AppTitleContainer>
            <AppTitle>{title}</AppTitle>
          </AppTitleContainer>
          <AppDescription>{description}</AppDescription>
        </StyledIntegrationHeaderContent>
        <IntegrationActions>
          {isInstalled ? (
            <StatusCircle color={hasInvalidCredentials ? styles.colors.redColor : ''} />
          ) : (
            <PrimaryButton onClick={onClickInstall} text={installLabel} width={150} height={32} />
          )}
        </IntegrationActions>
      </IntegrationRow>
    )
  }
)

const headerContentPadding = 11
const StyledIntegrationHeaderContent = styled(IntegrationHeaderContent)`
  padding: 0 ${headerContentPadding}px;
`

const integrationImgSize = 30
const IntegrationIcon = styled.img`
  width: ${integrationImgSize}px;
  height: ${integrationImgSize}px;
`

const IntegrationIconContainer = styled.div`
  flex-shrink: 1;
  align-items: flex-start;
`

const IntegrationActions = styled.div`
  flex-shrink: 1;
  display: flex;
  align-items: center;
`

const StatusCircle = styled.div`
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background-color: ${(props) => props.color || styles.colors.greenColor};
`

export const IntegrationInfoRowWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  padding: 0 16px;
  width: 100%;
`

export const IntegrationInfoRow = styled.div`
  margin: 8px 0;

  ${({ invalid }) =>
    invalid &&
    css`
      ${IntegrationInfoRowWrapper} {
        background-color: ${styles.colors.pinkColor}1A;
        padding: 8px 16px;
      }
    `}
`

export const IntegrationInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${headerContentPadding + integrationImgSize}px;
  width: 100%;
`

export const BlockFooter = React.memo(({ isExpanded, onClickExpand, onClickAction, actionLabel }) => {
  return (
    <IntegrationInfoFooter>
      <IntegrationExpandButton onClick={onClickExpand}>
        {isExpanded ? translations.general.hide : translations.general.seeMore}
      </IntegrationExpandButton>
      {isExpanded && <PrimaryButton onClick={onClickAction} text={actionLabel} width={150} height={32} />}
    </IntegrationInfoFooter>
  )
})

export const IntegrationSubtitle = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 32px;
  margin-top: ${(props) => props.marginTop || 18}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${styles.colors.textDarkColor};
  text-transform: capitalize;
`

const IntegrationInfoFooter = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const IntegrationExpandButton = styled.span`
  font-size: 13px;
  cursor: pointer;
  color: ${styles.colors.primaryColor};
`
