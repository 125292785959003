import React from 'react'

import { constants, styles } from 'gipsy-misc'
import { FixedTooltip, Icon } from 'gipsy-ui'

import { ButtonContainer, ButtonContent } from './commonUIComponents'

interface Props {
  activeColor?: string
  fillIcon?: boolean
  icon: string
  onClick: () => void
  shrinked: boolean
  strokeIcon?: boolean
  text: string
}

export default function Button({
  activeColor = styles.colors.primaryColor,
  fillIcon = true,
  icon,
  onClick,
  shrinked,
  strokeIcon,
  text,
}: Props) {
  return (
    <ButtonContainer activeColor={activeColor} fillIcon={fillIcon} onClick={onClick} strokeIcon={strokeIcon}>
      <ButtonContent>
        {shrinked ? (
          <FixedTooltip
            content={text}
            horizontalOffset={-10}
            horizontalPosition='left'
            portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
            style={{
              padding: '8px',
            }}
            verticalOffset={-12}
            verticalPosition='bottom'>
            <Icon className='ButtonIcon ButtonIcon--shrinked' icon={icon} size={14} />
          </FixedTooltip>
        ) : (
          <>
            <Icon className='ButtonIcon' icon={icon} size={14} />
            {text}
          </>
        )}
      </ButtonContent>
    </ButtonContainer>
  )
}
