import { combineReducers } from 'redux'
import accounts from 'store/accounts/reducer'
import batchActions from 'store/batchActions/reducer'
import integrations from 'store/integrations/reducer'
import mobileNavbar from 'store/mobileNavbar/reducer'
import popup from 'store/popup/reducer'
import session from 'store/session/reducer'
import projects from 'store/project/reducer'
import shortcuts from 'store/shortcuts/reducer'
import calendar from 'store/calendar/reducer'
import tags from 'store/tag/reducer'
import taskPanel from 'store/taskPanel/reducer'
import sprintComposer from 'store/sprintComposer/reducer'
import layout from 'store/layout/reducer'
import snackbar from 'store/snackbar/reducer'
import supportPanel from 'store/supportPanel/reducer'
import items from 'store/items/reducer'
import undoToast from 'store/undoToast/reducer'
import { withReduxStateSync } from 'redux-state-sync'
const allReducers = combineReducers({
  accounts,
  batchActions,
  integrations,
  items,
  mobileNavbar,
  popup,
  session,
  projects,
  calendar,
  tags,
  layout,
  taskPanel,
  sprintComposer,
  shortcuts,
  snackbar,
  supportPanel,
  undoToast,
})

/* add the ability to reset the store (usefull for tests) */
const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined
  }

  return allReducers(state, action)
}

export default withReduxStateSync(rootReducer)
