import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import HomebaseLine from 'features/list/components/line'
import { innerLeftPadding, innerRightPadding } from 'features/layout/pages'
import { DragDropContext } from 'gipsy-ui'
import { remapData } from 'logic/draganddrop'
import { models, utils } from 'gipsy-misc'

class DraggableList extends PureComponent {
  static propTypes = {
    /* if dragging a task in the page */
    list: PropTypes.array.isRequired,
    highlightIds: PropTypes.array,
    onComplete: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onDrop: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onDragStart: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func,
    startSprintCreation: PropTypes.func,
    session: PropTypes.object,
  }

  state = {}

  onMouseEnter = () => {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter()
    }
  }

  onMouseLeave = () => {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave()
    }
  }

  /* data passed to onDragStart, onDrop */
  getChildPayload = (index) => {
    const { list } = this.props

    return { item: list[index], index }
  }

  updatesFromDragging = (data) => {
    this.setState({
      draggedItem: data.from === 'dragEnd' ? null : data.draggedItem,
    })
  }

  render() {
    const {
      list,
      highlightIds,
      onComplete,
      onCompleteFromFS,
      onClick,
      onSave,
      onDelete,
      onDrop,
      onDragEnd,
      onDropOnDraggableOver,
      updateTaskInState,
      onMoveToTop,
      showSprintInfo,
      startSprintCreation,
      ignoreOutsideClicks,
      onDragStart,
      editingTask,
      onCancelEdit,
      canBlockToCalendar,
      onEditStart,
      onTogglePin,
      onClickFocusSession,
      onUpdateFocusSession,
      onClickDeleteFocusSession,
      setDraggableListRef,
      session,
      showCalendar,
      dragging,
      onSelectItem,
      selectedItems,
      selectMode,
    } = this.props

    const focusedTaskId = utils.session.getFSTaskIdFromSession(session)
    let filteredList = list
    if (!!focusedTaskId) {
      filteredList = filteredList.filter((item) => item.id !== focusedTaskId)
    }

    if (dragging) {
      filteredList = remapData(filteredList, this.state.draggedItem)
    }

    return (
      <ListBody
        ref={setDraggableListRef}
        className='list-body'
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}>
        <DragDropContext
          items={list}
          onSave={onSave}
          onDrop={onDrop}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onDropOnDraggableOver={onDropOnDraggableOver}
          updatesFromDragging={this.updatesFromDragging}>
          <Droppable droppableId={JSON.stringify({ id: 'taskList' })}>
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {filteredList.map((todoItem, index) => {
                  return (
                    <Draggable draggableId={todoItem.id} index={index} key={todoItem.id}>
                      {(draggableProvided, snapshot) => {
                        const isEditingTask = editingTask && todoItem.id === editingTask.id
                        const itemData = isEditingTask ? editingTask : todoItem

                        return (
                          <HomebaseLine
                            componentSource={models.item.type.TASK}
                            isCalendarDraggable
                            showSprintInfo={showSprintInfo && todoItem.sprintInfo && todoItem.sprintInfo.id}
                            innerRef={draggableProvided.innerRef}
                            draggableProps={draggableProvided.draggableProps}
                            dragHandleProps={draggableProvided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            draggableStyle={draggableProvided.draggableProps.style}
                            item={itemData}
                            startEdition={isEditingTask}
                            onCancelEditTitle={isEditingTask && todoItem.title}
                            highlight={highlightIds.indexOf(todoItem.id) !== -1}
                            onMoveToTop={index > 0 ? onMoveToTop : undefined}
                            onClick={onClick}
                            onComplete={onComplete}
                            onCompleteFromFS={onCompleteFromFS}
                            onSave={onSave}
                            onCancel={onCancelEdit}
                            startSprintCreation={startSprintCreation}
                            onDelete={onDelete}
                            updateTaskInState={updateTaskInState}
                            isDraggable={true}
                            animateComplete={true}
                            keepJustCompleted={true}
                            innerLeftPadding={innerLeftPadding}
                            innerRightPadding={innerRightPadding}
                            ignoreOutsideClicks={ignoreOutsideClicks}
                            canBlockToCalendar={canBlockToCalendar}
                            onEditStart={onEditStart}
                            onTogglePin={showCalendar ? onTogglePin : undefined}
                            onClickFocusSession={onClickFocusSession}
                            onUpdateFocusSession={onUpdateFocusSession}
                            onDeleteFocusSession={onClickDeleteFocusSession}
                            estimatedTime={itemData?.estimatedTime}
                            pin={itemData?.pin}
                            onSelect={onSelectItem}
                            selected={!!selectedItems?.[itemData.id]}
                            selectMode={selectMode}
                          />
                        )
                      }}
                    </Draggable>
                  )
                })}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListBody>
    )
  }
}

const ListBody = styled.div`
  margin-top: 2px;
  transition: min-height 0.2s linear;
  min-height: 0px;
  width: 100%;
  border-radius: 8px;

  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: visible;
    cursor: grab;
  }
  .smooth-dnd-container.vertical > *:last-child,
  .smooth-dnd-container.vertical > *:last-child .line-container {
    margin-bottom: 0px;
  }
`

export default DraggableList
