import React, { useCallback, useState } from 'react'
import { Flipped } from 'react-flip-toolkit'

import { models, translations } from 'gipsy-misc'
import { AnimateHeight } from 'gipsy-ui'

import HomebaseLine from 'features/list/components/line'
import { TaskObjectGroups } from 'logic/today/models'
import { SubGroupTitleSeparator } from 'pages/today/active/components'

function CompletedItems({
  archiveAllButtonRef,
  getTaskProps,
  groupSelectedItems,
  groupTotalItems,
  isDragging,
  items,
  onCheckboxClicked,
  pageSource,
  selectMode,
  setArchiveAllButtonVisible,
  withSelect,
}) {
  const [expanded, setExpanded] = useState(true)

  const handleHoverStart = useCallback(() => {
    if (!archiveAllButtonRef.current) return

    if (!isDragging) {
      setArchiveAllButtonVisible(true)
    }
  }, [archiveAllButtonRef, isDragging, setArchiveAllButtonVisible])

  const handleHoverEnd = useCallback(
    (e) => {
      if (!archiveAllButtonRef.current) return

      const landedArchiveAllButton = archiveAllButtonRef.current === e.relatedTarget
      if (!landedArchiveAllButton) {
        setArchiveAllButtonVisible(false)
      }
    },
    [archiveAllButtonRef, setArchiveAllButtonVisible]
  )

  return (
    <>
      <SubGroupTitleSeparator
        groupSelectedItems={groupSelectedItems}
        groupTotalItems={groupTotalItems}
        isExpanded={expanded}
        onCheckboxClicked={(checked) => onCheckboxClicked(checked, [TaskObjectGroups.COMPLETED])}
        onClick={() => setExpanded(!expanded)}
        selectMode={selectMode}
        title={translations.general.completed}
        withSelect={withSelect}
      />

      <AnimateHeight duration={300} height={expanded ? 'auto' : 0}>
        {items.map((item) => (
          <CompletedItem
            item={item}
            key={item.id}
            pageSource={pageSource}
            taskProps={{ ...getTaskProps({ item }), endHover: handleHoverEnd, startHover: handleHoverStart }}
          />
        ))}
      </AnimateHeight>
    </>
  )
}

const CompletedItem = React.memo(({ item, pageSource, taskProps }) => (
  <Flipped flipId={item.id} spring={'gentle'}>
    {(flippedProps) => (
      <HomebaseLine
        componentSource={models.item.type.TASK}
        flippedProps={flippedProps}
        pageSource={pageSource}
        {...taskProps}
      />
    )}
  </Flipped>
))

export default React.memo(CompletedItems)
