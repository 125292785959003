import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { produce } from 'immer'

import { mixpanel as mixpanelApi, tags as tagsApi } from 'gipsy-api'
import { mixpanel, models, translations, utils } from 'gipsy-misc'

import { batchActionTypes } from 'features/batchActionsBar'
import { storageAvailable } from 'utils/storage'
import { handleAPIError } from 'store/app/actions'
import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import useBatchSelectionHandler from 'features/hooks/useBatchSelectionHandler'
import { pageSource } from 'features/source'
import { updateTaskProjects, updateTaskTags } from 'logic/batchActions'
import { hideBatchActions, showBatchActions, updateBatchActions } from 'store/batchActions/actions'
import { setHighlightedEventId } from 'store/calendar/actions'
import { OPTIONS as sortOptions, sortOptionsKey } from 'features/filters/SortDropdown'
import usePageActions from 'features/hooks/usePageActions2'
import { updateItems } from 'store/items/actions'
import { getTasks } from 'store/items/selectors'
import { sortTaskList } from 'logic/tag'
import { launchUndoToast } from 'store/undoToast/actions'

const componentName = 'activeTagTasksPageContainer'
const TaskObjectGroups = { ALL_ITEMS: 'allItems' }

function ActiveTagTasksPageContainer({ children, focusedTaskProps, tagId, showTaskCreationAlert }) {
  const {
    cancelCalendarTaskAction,
    cancelTaskAction,
    clearLocalTaskState,
    creatingCalendarTask,
    creatingTask,
    editingCalendarTask,
    editingTask,
    ignoreOutsideClicks,
    isCreatingInlineTask,
    isDragging,
    keepCreatingTasks,
    onCreateCalendarTask,
    onDragEnd,
    onDragStart,
    onDeleteCalendarTaskCallback,
    onSaveCalendarTask,
    onTaskEditStart,
    onTogglePin,
    onTogglePinFromCalendarPanel,
    showCalendar,
    sprintComposerProps,
    startInlineTaskCreation,
  } = useCalendarPanelContext()

  const dispatch = useDispatch()
  const {
    addTasksToSprint,
    completeTask,
    completeTaskFromFS,
    completeTasks,
    createInlineTask,
    createSprint,
    deleteItems,
    deletionPopup,
    editTasks,
    findItemById,
    getFocusedTaskId,
    handleCompletedSession,
    isTaskCreationAlertShown,
    onClickDelete,
    onClickDeleteFocusSession,
    onClickFocusSession,
    onTitleChange,
    rescheduleItems,
    saveTask,
    uncompleteTask,
    updateFocusSession,
    pushShortcutsGroup,
    popShortcutsGroup,
  } = usePageActions()
  const session = useSelector((state) => state.session)
  const allTasks = useSelector((state) => getTasks(state.items))

  const [tag, setTag] = useState({})
  const [showBatchSelectShortcut, setShowBatchSelectShortCut] = useState(false)
  const [sortSelected, setSortSelected] = useState(sortOptions.NONE)

  useEffect(() => {
    if (storageAvailable('localStorage')) {
      const value = window.localStorage.getItem(sortOptionsKey)
      if (value && sortOptions[value]) {
        setSortSelected(sortOptions[value])
      }
    }
  }, [])

  const itemsData = useMemo(() => {
    const filteredTasks = []
    const nextTaskInstanceMap = {}
    let pastItems = 0

    allTasks.forEach((item) => {
      if (
        item.type === models.item.type.SPRINT ||
        item?.completed === 1 ||
        (tagId && !item.tagsId?.find?.((id) => id === tagId))
      )
        return

      const isPast = utils.task.isPast(item)
      if (isPast) {
        pastItems++
      }

      if (!utils.task.isRecurrent(item) || isPast) {
        filteredTasks.push(
          produce(item, (draft) => {
            if (tag) {
              draft.tag = tag
            }
          })
        )
        return
      }

      const recTaskId = utils.task.getRecTaskId(item)
      const nextInstance = nextTaskInstanceMap[recTaskId]

      if (nextInstance) {
        if (utils.task.isScheduledBefore(item, nextInstance)) {
          nextTaskInstanceMap[recTaskId] = item
        }
      } else {
        nextTaskInstanceMap[recTaskId] = item
      }
    })

    Object.values(nextTaskInstanceMap).forEach((item) => {
      filteredTasks.push(
        produce(item, (draft) => {
          if (tag) {
            draft.tag = tag
          }
        })
      )
    })

    setShowBatchSelectShortCut(pastItems >= 3)

    const pageItems = sortTaskList(sortSelected, filteredTasks, tagId)
    return {
      pageItems,
      totalSelectableItems: pageItems.length,
    }
  }, [allTasks, sortSelected, tag, tagId])

  const { pageItems, totalSelectableItems } = itemsData

  const completeBatch = useCallback(() => {
    deletionPopup(
      {
        cancelLabel: translations.batchActions.complete.cancel,
        confirmLabel: translations.batchActions.complete.confirm,
        text: translations.batchActions.complete.prompt,
        title: translations.batchActions.complete.prompt,
      },
      {
        onConfirmed: () => {
          const selectedItems = Object.keys(selectedItemsRef.current)
          completeTasks(selectedItems)
          mixpanelApi.track(
            { event: mixpanel.performedBatchActionEvent },
            {
              actionType: batchActionTypes.COMPLETE,
              batchSource: '',
              pageSource: pageSource.tag,
              selectedItems: selectedItems.length,
            }
          )
        },
      }
    )
  }, [completeTasks, deletionPopup])

  const deleteBatch = useCallback(() => {
    deletionPopup(
      {
        cancelLabel: translations.batchActions.delete.cancel,
        confirmLabel: translations.batchActions.delete.confirm,
        text: translations.batchActions.delete.prompt,
        title: translations.batchActions.delete.prompt,
      },
      {
        onConfirmed: () => {
          const selectedItems = Object.keys(selectedItemsRef.current)
          deleteItems(selectedItems)
          mixpanelApi.track(
            { event: mixpanel.performedBatchActionEvent },
            {
              actionType: batchActionTypes.DELETE,
              batchSource: '',
              pageSource: pageSource.tag,
              selectedItems: selectedItems.length,
            }
          )
        },
      }
    )
  }, [deleteItems, deletionPopup])

  const addBatchToFocusBlock = useCallback(
    (sprint) => {
      const selectedItems = Object.keys(selectedItemsRef.current)
      const { saveToBackend } = addTasksToSprint(sprint.id, selectedItems)
      saveToBackend()
      mixpanelApi.track(
        { event: mixpanel.performedBatchActionEvent },
        {
          actionType: batchActionTypes.ADD_TO_FB,
          batchSource: '',
          pageSource: pageSource.tag,
          selectedItems: selectedItems.length,
        }
      )
    },
    [addTasksToSprint]
  )

  const setProjectsToBatch = useCallback(
    (projects, indeterminateIds) => {
      const selectedItems = Object.keys(selectedItemsRef.current)
      const { saveToBackend } = editTasks(selectedItems, (t) => updateTaskProjects(t, projects, indeterminateIds))
      saveToBackend()
      mixpanelApi.track(
        { event: mixpanel.performedBatchActionEvent },
        {
          actionType: batchActionTypes.SELECT_PROJECT,
          batchSource: '',
          pageSource: pageSource.tag,
          selectedItems: selectedItems.length,
        }
      )
    },
    [editTasks]
  )

  const setDateToBatch = useCallback(
    (newDate) => {
      const selectedItems = Object.keys(selectedItemsRef.current)
      const { saveToBackend } = rescheduleItems(selectedItems, newDate)
      saveToBackend()
      mixpanelApi.track(
        { event: mixpanel.performedBatchActionEvent },
        {
          actionType: batchActionTypes.SCHEDULE,
          batchSource: '',
          pageSource: pageSource.tag,
          selectedItems: selectedItems.length,
        }
      )
    },
    [rescheduleItems]
  )

  const setTagsToBatch = useCallback(
    (tags, indeterminateIds) => {
      const tagSelected = tags.find((p) => p.id === tag.id)
      const selectedItems = Object.keys(selectedItemsRef.current)
      const { saveToBackend, undoAction } = editTasks(selectedItems, (t) => updateTaskTags(t, tags, indeterminateIds))

      const saveAndTrackChanges = () => {
        saveToBackend()
        mixpanelApi.track(
          { event: mixpanel.performedBatchActionEvent },
          {
            actionType: batchActionTypes.SELECT_TAG,
            batchSource: '',
            pageSource: pageSource.tag,
            selectedItems: selectedItems.length,
          }
        )
      }

      if (!tagSelected) {
        dispatch(launchUndoToast({ action: saveAndTrackChanges, undoAction }))
        return
      }

      saveAndTrackChanges()
    },
    [dispatch, editTasks, tag]
  )

  const launchBatchActions = useCallback(() => {
    dispatch(
      showBatchActions({
        onComplete: completeBatch,
        onDelete: deleteBatch,
        onFocusBlockSelected: addBatchToFocusBlock,
        onProjectsSelected: setProjectsToBatch,
        onScheduleSelected: setDateToBatch,
        onTagsSelected: setTagsToBatch,
      })
    )
  }, [addBatchToFocusBlock, completeBatch, deleteBatch, dispatch, setDateToBatch, setProjectsToBatch, setTagsToBatch])

  const { onSelectItem, onToggleAllItemsSelection, selectedItems, selectMode } = useBatchSelectionHandler({
    findItemById,
    getItemGroup: () => TaskObjectGroups.ALL_ITEMS,
    hasNestedItems: false,
    pageGroupsOrder: { [TaskObjectGroups.ALL_ITEMS]: 0 },
    pageItemsByGroup: { [TaskObjectGroups.ALL_ITEMS]: pageItems },
    startBatchActions: launchBatchActions,
  })

  const selectedItemsRef = useRef(selectedItems)
  selectedItemsRef.current = selectedItems

  const totalSelectedItems = useMemo(() => {
    return Object.keys(selectedItems).length
  }, [selectedItems])

  const onCreateInlineTask = useCallback(
    async (task, { componentSource = 'inlineAddTask', dontShowCreationAlert } = {}) => {
      const response = await createInlineTask({
        context: { componentSource, pageSource: 'tag' },
        dontShowCreationAlert,
        task,
      })

      return response
    },
    [createInlineTask]
  )

  useEffect(() => {
    if (selectMode) return

    const shortcuts = [
      {
        key: 'q',
        label: translations.calendar.createTask,
        callback: (e) => {
          startInlineTaskCreation()
        },
      },
      {
        key: 'b',
        label: translations.calendar.createSprint,
        callback: (e) => {
          sprintComposerProps.startSprintCreation()
        },
      },
    ]

    if (showBatchSelectShortcut) {
      shortcuts.push({
        key: 'click',
        label: translations.batchActions.batchSelect,
        shiftKey: true,
        callback: () => {},
      })
    }

    pushShortcutsGroup(shortcuts, componentName)

    return () => {
      popShortcutsGroup(componentName)
    }
  }, [
    popShortcutsGroup,
    pushShortcutsGroup,
    selectMode,
    showBatchSelectShortcut,
    sprintComposerProps,
    startInlineTaskCreation,
  ])

  const onCreatePageTask = (task, _, { eventName }) => {
    clearLocalTaskState({ keepCreatingTasks: eventName !== utils.task.clickOutside })
    onCreateInlineTask(task)
  }

  const onComplete = useCallback(
    async ({ id, value }) => {
      if (value) {
        await completeTask({ id })
      } else {
        await uncompleteTask(id)
      }
    },
    [completeTask, uncompleteTask]
  )

  const onCreateSprint = useCallback(
    async (sprint, callback) => {
      const response = await createSprint(sprint)
      callback?.(response)
      return response
    },
    [createSprint]
  )

  const onDrop = useCallback(
    async (data) => {
      if (!data.source || !data.destination || sortSelected !== sortOptions.NONE) return

      const focusedTaskId = getFocusedTaskId()
      const focusedTaskIndex = pageItems.findIndex((item) => item.id === focusedTaskId)
      let sourceIndex = data.source.index
      let destinationIndex = data.destination.index

      if (focusedTaskIndex > -1 && sourceIndex >= focusedTaskIndex) {
        sourceIndex += 1
      }

      if (focusedTaskIndex > -1 && destinationIndex >= focusedTaskIndex) {
        destinationIndex += 1
      }

      const sourceItem = pageItems[sourceIndex]
      const updatedSourceItem = produce(sourceItem, (draft) => {
        draft.tagsRank = draft.tagsRank || {}
        draft.tagsRank[tagId] = destinationIndex + 1
      })

      let updatedItems = pageItems.filter((item) => item.id !== sourceItem.id)
      updatedItems.splice(destinationIndex, 0, updatedSourceItem)
      updatedItems = updatedItems.map((sectionItem, index) =>
        produce(sectionItem, (draft) => {
          draft.tagsRank = draft.tagsRank || {}
          draft.tagsRank[tagId] = index + 1
        })
      )

      dispatch(updateItems(updatedItems))

      try {
        await tagsApi.dragAndDropTasks(tagId, { taskId: data.item.id, toRank: destinationIndex + 1 })
      } catch (err) {
        console.error(err)
      }
    },
    [dispatch, getFocusedTaskId, pageItems, tagId, sortSelected]
  )

  const onMoveToTop = useCallback(
    async (taskId) => {
      const item = findItemById(taskId)

      if (!item) return

      const updatedItem = produce(item, (draft) => {
        draft.tagsRank[tagId] = 1
      })

      let updatedItems = pageItems.filter((pageItem) => pageItem.id !== updatedItem.id)
      updatedItems.splice(0, 0, updatedItem)
      updatedItems = updatedItems.map((sectionItem, index) =>
        produce(sectionItem, (draft) => {
          draft.tagsRank[tagId] = index + 1
        })
      )

      dispatch(updateItems(updatedItems))

      try {
        await tagsApi.dragAndDropTasks(tagId, { taskId: taskId, toRank: 1 })
      } catch (err) {
        console.error(err)
      }
    },
    [dispatch, findItemById, pageItems, tagId]
  )

  useEffect(() => {
    const fetchTag = async () => {
      try {
        const tags = await tagsApi.get()
        const tagData = tags.filter((tag) => tag.id === tagId)
        setTag(tagData.length > 0 ? tagData[0] : {})
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    }

    fetchTag()
  }, [dispatch, tagId])

  const onSelectSortingOption = useCallback((value) => {
    setSortSelected(value)
    if (storageAvailable('localStorage')) {
      window.localStorage.setItem(sortOptionsKey, value)
    }
  }, [])

  const onSavePageTask = useCallback(
    (task) => {
      clearLocalTaskState()
      saveTask(task)
    },
    [clearLocalTaskState, saveTask]
  )

  useEffect(() => {
    if (!selectMode) return

    dispatch(
      updateBatchActions({
        onComplete: completeBatch,
        onDelete: deleteBatch,
        onFocusBlockSelected: addBatchToFocusBlock,
        onProjectsSelected: setProjectsToBatch,
        onScheduleSelected: setDateToBatch,
        onTagsSelected: setTagsToBatch,
      })
    )
  }, [
    addBatchToFocusBlock,
    completeBatch,
    deleteBatch,
    dispatch,
    selectMode,
    setDateToBatch,
    setProjectsToBatch,
    setTagsToBatch,
  ])

  useEffect(() => {
    return () => {
      dispatch(hideBatchActions())
    }
  }, [dispatch])

  return children({
    cancelCalendarTaskAction,
    cancelTaskAction,
    creatingCalendarTask,
    creatingTask,
    editingCalendarTask,
    editingTask,
    focusedTaskProps: {
      ...focusedTaskProps,
      handleCompletedSession,
      onClickFocusSession,
      onClickDeleteFocusSession,
      onTitleChange,
      onUpdateFocusSession: updateFocusSession,
      setHighlightedEventId,
      onCompleteFromFS: completeTaskFromFS,
    },
    ignoreOutsideClicks,
    isCreatingInlineTask,
    isDragging,
    isTaskCreationAlertShown,
    keepCreatingTasks,
    onClickDelete,
    onClickDeleteFocusSession,
    onComplete,
    onCompleteFromFS: completeTaskFromFS,
    onCreateCalendarTask,
    onCreatePageTask,
    onCreateSprint,
    onDeleteCalendarTaskCallback,
    onDragEnd,
    onDragStart,
    onDrop,
    onMoveToTop,
    onSave: onSavePageTask,
    onSaveCalendarTask,
    onSelectItem,
    onSelectSortingOption,
    onTaskEditStart,
    onToggleAllItemsSelection,
    onTogglePin,
    onTogglePinFromCalendarPanel,
    onUpdateFocusSession: updateFocusSession,
    selectedItems,
    selectMode,
    session,
    showCalendar,
    showTaskCreationAlert,
    sortSelected,
    sprintComposerProps: sprintComposerProps,
    tag,
    tasks: pageItems,
    totalSelectableItems,
    totalSelectedItems,
  })
}

export default React.memo(withRouter(ActiveTagTasksPageContainer))
