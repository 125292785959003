export enum Schedules {
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export enum DailyScheduleOptions {
  Custom = 'CUSTOM',
  Daily = 'DAILY',
  EveryWeekday = 'EVERY_WEEKDAY',
  MonthlyOnCurrentDay = 'MONTHLY_ON_CURRENT_DAY',
  NoRepeat = 'NO_REPEAT',
  WeeklyOnCurrentDay = 'WEEKLY_ON_CURRENT_DAY',
}

export enum InstanceOptions {
  All = 'all',
  Next = 'next',
  Single = 'single',
}
