import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { constants, styles, translations } from 'gipsy-misc'
import { CustomPopup, FixedTooltip, Icon } from 'gipsy-ui'

import { getInvalidAsanaIntegrations } from 'store/integrations/selectors'

import { NavBoxButton, NavBoxButtonIcon, NavLinkContainer, NavLinkContent, NavLinkIcon } from './commonUIComponents'

const integrationsRoute = '/integrations'
const settingsRoute = '/settings'

function NavBarMenu({ activePathname, showLeftBorder = true, showRightBorder = true, onLogout, session }) {
  const invalidAsanaAccounts = useSelector((state) => getInvalidAsanaIntegrations(state))

  const [expanded, setExpanded] = useState(false)

  const containerRef = useRef(null)
  const popupRef = useRef(null)

  const handlePopupExpansion = (e) => {
    if (popupRef.current?.contains?.(e.target)) return

    setExpanded(!expanded)
  }

  const handleClickOutside = (e) => {
    if (containerRef.current?.contains?.(e.target)) return

    setExpanded(false)
  }

  const unexpandMenu = () => {
    setExpanded(false)
  }

  const hasInvalidIntegrations = invalidAsanaAccounts.length !== 0

  return (
    <NavBoxButton
      active={expanded}
      onClick={handlePopupExpansion}
      ref={containerRef}
      showLeftBorder={showLeftBorder}
      showRightBorder={showRightBorder}>
      {hasInvalidIntegrations && <InvalidInfoIndicator />}
      <NavBoxButtonIcon icon='Burger' size={16} />
      {expanded && (
        <NavBarMenuPopup onClickOutside={handleClickOutside} ref={popupRef}>
          <InsideContainer>
            <PopupLinks>
              <NavLinkContainer onClick={unexpandMenu} to={integrationsRoute}>
                <NavLinkContent active={activePathname === integrationsRoute} colorFill>
                  <NavLinkIcon icon='Repeat' size={18} />
                  {translations.integrations.title}
                  {hasInvalidIntegrations && (
                    <FixedTooltip
                      content={translations.integrations.invalidCredentialsWarning}
                      horizontalOffset={10}
                      horizontalPosition='left'
                      portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
                      style={{
                        padding: '11px',
                        textAlign: 'right',
                        width: '220px',
                      }}
                      verticalOffset={-26}
                      verticalPosition='bottom'>
                      <Icon fill='none' icon='Warning' size={16} stroke={styles.colors.pinkColor} />
                    </FixedTooltip>
                  )}
                </NavLinkContent>
              </NavLinkContainer>
              <NavLinkContainer onClick={unexpandMenu} to={settingsRoute}>
                <NavLinkContent active={activePathname === settingsRoute} colorFill>
                  <NavLinkIcon icon='SettingsCog' size={18} />
                  {translations.navbar.settings}
                </NavLinkContent>
              </NavLinkContainer>
            </PopupLinks>
            <PopupUserSection>
              <UserName>{session?.user?.displayName}</UserName>
              <LogoutOuterContainer>
                <LogoutContainer onClick={onLogout}>
                  <LogoutIcon fill='none' icon='Logout' size={16} stroke={styles.colors.darkGrey} />
                  <LogoutText>{translations.settings.section.logout.title}</LogoutText>
                </LogoutContainer>
              </LogoutOuterContainer>
            </PopupUserSection>
          </InsideContainer>
        </NavBarMenuPopup>
      )}
    </NavBoxButton>
  )
}

const InvalidInfoIndicator = styled.div`
  border-radius: 50px;
  background-color: red;
  height: 8px;
  pointer-events: none;
  position: absolute;
  right: 21px;
  top: 18px;
  width: 8px;
  z-index: 1;
`

InvalidInfoIndicator.displayName = 'InvalidInfoIndicator'

const NavBarMenuPopup = styled(CustomPopup)`
  cursor: initial;
  right: 8px;
  top: calc(100% + 8px);

  .FixedTooltip__wrapper {
    margin-left: auto;
  }
`

const InsideContainer = styled.div`
  width: 230px;
  max-width: 230px;
`

NavBarMenuPopup.displayName = 'NavBarMenuPopup'

const PopupLinks = styled.div`
  border-bottom: 1px solid ${styles.colors.middleGrey};
  display: flex;
  flex-flow: column;
  padding: 15px 22px 0;

  ${NavLinkContainer} {
    margin-bottom: 15px;
  }
`

PopupLinks.displayName = 'PopupLinks'

const PopupUserSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 22px;
  flex-shrink: 1;
`

PopupUserSection.displayName = 'PopupUserSection'

const UserName = styled.span`
  font-size: 14px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
`

UserName.displayName = 'UserName'

const LogoutText = styled.span`
  color: ${styles.colors.primaryColor};
  margin-left: 5px;
`
const LogoutOuterContainer = styled.div`
  display: flex;
  margin-left: auto;
`
const LogoutContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
  ${LogoutText} {
    opacity: 0;
    max-width: 0;
    display: inline-block;
    transition: max-width 1s ease-out, opacity 1s ease-out;
  }
  path {
    transition: stroke 1s ease-out;
  }
  &:hover {
    path {
      stroke: ${styles.colors.primaryColor};
      transition: stroke 1s ease-out;
    }
    ${LogoutText} {
      opacity: 1;
      max-width: 150px;
      transition: max-width 1s ease-out, opacity 1s ease-out;
    }
  }
`

const LogoutIcon = styled(Icon)`
  display: inline-block;
  cursor: pointer;
  vertical-align: bottom;
  &:hover {
    path {
      stroke: ${styles.colors.primaryColor};
    }
  }
`

LogoutIcon.displayName = 'LogoutIcon'

export default NavBarMenu
