import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, models, translations, utils } from 'gipsy-misc'

import { batchActionTypes } from 'features/batchActionsBar'
import { TaskSubGroups } from 'logic/allTasks/models'
import {
  getFilteredTasksAndFirstSprintInstancesMap,
  getScheduledGroup,
  groupPositionMap,
  sortListByTypeAndScheduledTime,
} from 'logic/allTasks'
import { TaskObjectGroups } from 'logic/allTasks/models'
import { updateTaskProjects, updateTaskTags } from 'logic/batchActions'
import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import useBatchSelectionHandler from 'features/hooks/useBatchSelectionHandler'
import { initGroupList } from 'features/hooks/useBatchSelectionHandler/utils'
import usePageActions from 'features/hooks/usePageActions2'
import { pageSource } from 'features/source'
import { hideBatchActions, showBatchActions, updateBatchActions } from 'store/batchActions/actions'
import { setHighlightedEventId } from 'store/calendar/actions'
import { getSprints } from 'store/items/selectors'

const componentName = 'AllTasksPage'

export default function wrapper(Component) {
  function AllTasksContainer() {
    const { getDroppableId } = useCalendarPanelContext()
    const dispatch = useDispatch()
    const {
      addTasksToSprint,
      allItems,
      completeTask,
      completeTaskFromFS,
      completeTasks,
      createInlineTask,
      createSprint,
      deleteItems,
      deleteSprint,
      deletionPopup,
      editTasks,
      endSprint,
      findItemById,
      getFocusedTaskId,
      handleCompletedSession,
      isTaskCreationAlertShown,
      onClickDelete,
      onClickDeleteFocusSession,
      onClickFocusSession,
      onClickOutsideSprint,
      onClickSprint,
      onTaskDroppedInSprint,
      onTitleChange,
      popShortcutsGroup,
      pushShortcutsGroup,
      rescheduleItems,
      saveTask,
      sprintDeletePopup,
      startFsAndCreateTask,
      updateFocusSession,
    } = usePageActions()
    const session = useSelector((state) => state.session)
    const sprints = useSelector((state) => getSprints(state.items))

    const [showBatchSelectShortcut, setShowBatchSelectShortCut] = useState(false)

    const itemsData = useMemo(() => {
      const pageItems = initGroupList(TaskObjectGroups, [])
      const totalSelectableItemsByGroup = initGroupList(TaskObjectGroups, 0)
      let totalSelectableItems = 0
      let pastItems = 0

      const { filteredTasks, mapRecSprintIdFirstInstanceId } = getFilteredTasksAndFirstSprintInstancesMap(allItems)
      sprints.forEach((item) => {
        if (!item || item?.completed === 1 || item.completionTime) return

        if (utils.sprint.isRecurrent(item) && !item.tasks?.length) {
          const recSprintId = utils.sprint.getRecSprintId(item)
          const firstInstanceId = mapRecSprintIdFirstInstanceId[recSprintId]
          if (firstInstanceId !== item.id) return
        }

        const group = getScheduledGroup(item)
        pageItems[group].push(item)
        totalSelectableItems += 1
        totalSelectableItemsByGroup[group] += 1
        if (utils.sprint.isPast(item)) pastItems++

        if (item.tasks?.length) {
          totalSelectableItems += item.tasks.length
          totalSelectableItemsByGroup[group] += item.tasks.length
        }
      })

      filteredTasks.forEach((item) => {
        const group = getScheduledGroup(item)
        pageItems[group].push(item)
        totalSelectableItems += 1
        totalSelectableItemsByGroup[group] += 1
        if (utils.task.isPast(item)) pastItems++
      })

      Object.keys(pageItems).forEach((group) => {
        const groupItems = pageItems[group]
        if (groupItems.length < 2) return groupItems
        pageItems[group] = sortListByTypeAndScheduledTime(groupItems)
      })

      setShowBatchSelectShortCut(pastItems >= 3)

      return {
        pageItems,
        totalSelectableItems,
        totalSelectableItemsByGroup,
      }
    }, [allItems, sprints])

    const { pageItems, totalSelectableItems, totalSelectableItemsByGroup } = itemsData

    const completeBatch = useCallback(() => {
      deletionPopup(
        {
          cancelLabel: translations.batchActions.complete.cancel,
          confirmLabel: translations.batchActions.complete.confirm,
          text: translations.batchActions.complete.prompt,
          title: translations.batchActions.complete.prompt,
        },
        {
          onConfirmed: () => {
            const selectedItems = Object.keys(selectedItemsRef.current)
            completeTasks(selectedItems)
            mixpanelApi.track(
              { event: mixpanel.performedBatchActionEvent },
              {
                actionType: batchActionTypes.COMPLETE,
                batchSource: '',
                pageSource: pageSource.allTasksView,
                selectedItems: selectedItems.length,
              }
            )
          },
        }
      )
    }, [completeTasks, deletionPopup])

    const deleteBatch = useCallback(() => {
      deletionPopup(
        {
          cancelLabel: translations.batchActions.delete.cancel,
          confirmLabel: translations.batchActions.delete.confirm,
          text: translations.batchActions.delete.prompt,
          title: translations.batchActions.delete.prompt,
        },
        {
          onConfirmed: () => {
            const selectedItems = Object.keys(selectedItemsRef.current)
            deleteItems(selectedItems)
            mixpanelApi.track(
              { event: mixpanel.performedBatchActionEvent },
              {
                actionType: batchActionTypes.DELETE,
                batchSource: '',
                pageSource: pageSource.allTasksView,
                selectedItems: selectedItems.length,
              }
            )
          },
        }
      )
    }, [deleteItems, deletionPopup])

    const addBatchToFocusBlock = useCallback(
      (sprint) => {
        const selectedItems = Object.keys(selectedItemsRef.current)
        const { saveToBackend } = addTasksToSprint(sprint.id, selectedItems)
        saveToBackend()
        mixpanelApi.track(
          { event: mixpanel.performedBatchActionEvent },
          {
            actionType: batchActionTypes.ADD_TO_FB,
            batchSource: '',
            pageSource: pageSource.allTasksView,
            selectedItems: selectedItems.length,
          }
        )
      },
      [addTasksToSprint]
    )

    const setProjectsToBatch = useCallback(
      (projects, indeterminateIds) => {
        const selectedItems = Object.keys(selectedItemsRef.current)
        const { saveToBackend } = editTasks(selectedItems, (t) => updateTaskProjects(t, projects, indeterminateIds))
        saveToBackend()
        mixpanelApi.track(
          { event: mixpanel.performedBatchActionEvent },
          {
            actionType: batchActionTypes.SELECT_PROJECT,
            batchSource: '',
            pageSource: pageSource.allTasksView,
            selectedItems: selectedItems.length,
          }
        )
      },
      [editTasks]
    )

    const setDateToBatch = useCallback(
      (newDate) => {
        const selectedItems = Object.keys(selectedItemsRef.current)
        const { saveToBackend } = rescheduleItems(selectedItems, newDate)
        saveToBackend()
        mixpanelApi.track(
          { event: mixpanel.performedBatchActionEvent },
          {
            actionType: batchActionTypes.SCHEDULE,
            batchSource: '',
            pageSource: pageSource.allTasksView,
            selectedItems: selectedItems.length,
          }
        )
      },
      [rescheduleItems]
    )

    const setTagsToBatch = useCallback(
      (tags, indeterminateIds) => {
        const selectedItems = Object.keys(selectedItemsRef.current)
        const { saveToBackend } = editTasks(selectedItems, (t) => updateTaskTags(t, tags, indeterminateIds))
        saveToBackend()
        mixpanelApi.track(
          { event: mixpanel.performedBatchActionEvent },
          {
            actionType: batchActionTypes.SELECT_TAG,
            batchSource: '',
            pageSource: pageSource.allTasksView,
            selectedItems: selectedItems.length,
          }
        )
      },
      [editTasks]
    )

    const launchBatchActions = useCallback(() => {
      dispatch(
        showBatchActions({
          onComplete: completeBatch,
          onDelete: deleteBatch,
          onFocusBlockSelected: addBatchToFocusBlock,
          onProjectsSelected: setProjectsToBatch,
          onScheduleSelected: setDateToBatch,
          onTagsSelected: setTagsToBatch,
        })
      )
    }, [addBatchToFocusBlock, completeBatch, deleteBatch, dispatch, setDateToBatch, setProjectsToBatch, setTagsToBatch])

    const {
      onSelectItem,
      onToggleAllItemsSelection,
      onToggleItemsSelectionByGroups,
      selectedItems,
      selectMode,
    } = useBatchSelectionHandler({
      findItemById,
      getItemGroup: getScheduledGroup,
      pageGroupsOrder: groupPositionMap,
      pageItemsByGroup: pageItems,
      startBatchActions: launchBatchActions,
    })

    const selectedItemsRef = useRef(selectedItems)
    selectedItemsRef.current = selectedItems

    const totalSelectedItemsData = useMemo(() => {
      const countList = initGroupList(TaskObjectGroups, 0)
      let totalSelectedItems = 0
      let totalSelectedSprints = 0
      let totalSelectedTasks = 0

      Object.keys(selectedItems).forEach((id) => {
        const group = selectedItems[id]
        const item = findItemById(id)

        if (!item) return

        if (item.type === models.item.type.SPRINT) {
          totalSelectedSprints += 1
        } else {
          totalSelectedTasks += 1
        }

        countList[group] += 1
        totalSelectedItems += 1
      })

      return { totalSelectedItems, totalSelectedItemsByGroup: countList, totalSelectedSprints, totalSelectedTasks }
    }, [findItemById, selectedItems])

    const onCreateInlineTask = useCallback(
      async (task, { componentSource = 'inlineAddTask', tmpId, dontShowCreationAlert } = {}) => {
        const response = await createInlineTask({
          context: { componentSource, pageSource: pageSource.allTasksView },
          dontShowCreationAlert,
          task,
          tmpId,
        })

        return response
      },
      [createInlineTask]
    )

    const onCreateInlineTaskFromSprint = useCallback(
      async (task) => {
        if (!task.sprintInfo) return

        await createInlineTask({
          context: { componentSource: 'sprint', pageSource: pageSource.allTasksView },
          dontShowCreationAlert: true,
          task,
        })
      },
      [createInlineTask]
    )

    const handleStartFsAndCreateTask = useCallback(
      async (taskData, componentSource) => {
        await startFsAndCreateTask({ context: { componentSource, pageSource: pageSource.allTasksView }, taskData })
      },
      [startFsAndCreateTask]
    )

    const onComplete = useCallback(
      async ({ id, value }) => {
        if (value) {
          await completeTask({ id })
        }
      },
      [completeTask]
    )

    const onClickDeleteSprint = useCallback(
      (sprint) => {
        sprintDeletePopup(sprint, {
          onConfirmed: (recurrenceOption) => {
            deleteSprint(sprint.id, recurrenceOption)
          },
        })
      },
      [deleteSprint, sprintDeletePopup]
    )

    const onCreateSprint = useCallback(
      async (sprint, callback) => {
        const response = await createSprint(sprint)
        callback?.(response)
        return response
      },
      [createSprint]
    )

    const onRemoveFromSprint = useCallback(
      (task) => {
        const updatedTask = utils.task.computeTaskOnChange(task, {
          paramName: 'sprintInfo',
          value: null,
        })

        saveTask(updatedTask)
      },
      [saveTask]
    )

    const onDropTaskInSprint = useCallback(
      async ({ destinationIndex, itemId, sprintId }) => {
        if (!sprintId || !itemId) return

        await onTaskDroppedInSprint({
          destinationIndex,
          sprintId,
          taskId: itemId,
        })
      },
      [onTaskDroppedInSprint]
    )

    const onDrop = useCallback(
      (draggableItem) => {
        if (!draggableItem.source || !draggableItem.destination) return

        const { id: draggableItemId, type: draggableType } = JSON.parse(draggableItem.draggableId)
        const { extraParams, subgroup: destinationSubgroup } = getDroppableId(draggableItem.destination.droppableId)

        if (draggableType !== models.item.type.SPRINT && destinationSubgroup === TaskSubGroups.SPRINT_TASKS) {
          onDropTaskInSprint({
            destinationIndex: draggableItem.destination.index,
            itemId: draggableItemId,
            sprintId: extraParams.sprintId,
          })
        }
      },
      [getDroppableId, onDropTaskInSprint]
    )

    useEffect(() => {
      if (!selectMode) return

      dispatch(
        updateBatchActions({
          onComplete: completeBatch,
          onDelete: deleteBatch,
          onFocusBlockSelected: addBatchToFocusBlock,
          onProjectsSelected: setProjectsToBatch,
          onScheduleSelected: setDateToBatch,
          onTagsSelected: setTagsToBatch,
        })
      )
    }, [
      addBatchToFocusBlock,
      completeBatch,
      deleteBatch,
      dispatch,
      selectMode,
      setDateToBatch,
      setProjectsToBatch,
      setTagsToBatch,
    ])

    useEffect(() => {
      const shortcuts = []

      if (showBatchSelectShortcut) {
        shortcuts.push({
          key: 'click',
          label: translations.batchActions.batchSelect,
          shiftKey: true,
          callback: () => {},
        })
      }

      pushShortcutsGroup(shortcuts, componentName)

      return () => {
        popShortcutsGroup(componentName)
      }
    }, [popShortcutsGroup, pushShortcutsGroup, showBatchSelectShortcut])

    useEffect(() => {
      return () => {
        dispatch(hideBatchActions())
      }
    }, [dispatch])

    const toHideTaskId = getFocusedTaskId()

    return (
      <Component
        allItems={pageItems}
        handleCompletedSession={handleCompletedSession}
        isTaskCreationAlertShown={isTaskCreationAlertShown}
        onClickDelete={onClickDelete}
        onComplete={onComplete}
        onCompleteFromFS={completeTaskFromFS}
        onCreateInlineTask={onCreateInlineTask}
        onClickDeleteFocusSession={onClickDeleteFocusSession}
        onClickDeleteSprint={onClickDeleteSprint}
        onClickFocusSession={onClickFocusSession}
        onClickOutsideSprint={onClickOutsideSprint}
        onClickSprint={onClickSprint}
        onCreateInlineTaskFromSprint={onCreateInlineTaskFromSprint}
        onCreateSprint={onCreateSprint}
        onDrop={onDrop}
        onEndSprint={endSprint}
        onRemoveFromSprint={onRemoveFromSprint}
        onUpdateFocusSession={updateFocusSession}
        onSave={saveTask}
        onSelectItem={onSelectItem}
        onTitleChange={onTitleChange}
        onToggleAllItemsSelection={onToggleAllItemsSelection}
        onToggleItemsSelectionByGroups={onToggleItemsSelectionByGroups}
        selectedItems={selectedItems}
        selectMode={selectMode}
        session={session}
        setHighlightedEventId={setHighlightedEventId}
        startFsAndCreateTask={handleStartFsAndCreateTask}
        toHideTaskId={toHideTaskId}
        totalSelectableItems={totalSelectableItems}
        totalSelectableItemsByGroup={totalSelectableItemsByGroup}
        totalSelectedItemsData={totalSelectedItemsData}
      />
    )
  }

  return React.memo(AllTasksContainer)
}
