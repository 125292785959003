import { produce } from 'immer'

import types from './types'

const defaultState = {
  action: null,
  shown: false,
  timeout: 10000,
  undoAction: null,
}

function hide(state) {
  state.shown = false
  return state
}

function show(state, { action = null, timeout = 7000, undoAction = null } = {}) {
  state.action = action
  state.shown = true
  state.timeout = timeout
  state.undoAction = undoAction
  return state
}

function reducer(state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.HIDE_UNDO_TOAST: {
        return hide(draft)
      }

      case types.LAUNCH_UNDO_TOAST: {
        return show(draft, action.payload)
      }

      default: {
        return draft
      }
    }
  })
}

export default reducer
