import React from 'react'
import styled from 'styled-components'

import { translations } from 'gipsy-misc'
import { SquareCheckbox } from 'gipsy-ui'

import { innerLeftPadding } from 'features/layout/pages'

import { selectCheckboxLateralPaddingOffset } from './sharedUIComponents'

interface Props {
  onCheckboxClicked: (checked: boolean) => void
  selectedSprints?: number | undefined
  selectedTasks: number
  totalItems: number
}

export default function SelectAll({ onCheckboxClicked, selectedSprints, selectedTasks, totalItems }: Props) {
  const handleChange = ({ value }: { id: string; value: number }) => {
    onCheckboxClicked(!!value)
  }

  const selectedItems = selectedSprints ? selectedSprints + selectedTasks : selectedTasks
  const checked = selectedItems === totalItems ? true : false
  const indeterminate = selectedItems > 0 && selectedItems !== totalItems
  const sprintsLabel = selectedSprints
    ? `${selectedSprints} ${
        selectedSprints === 1 ? translations.general.sprint.singular : translations.general.sprint.plural
      },`
    : ''
  const tasksLabel = `${selectedTasks} ${
    selectedTasks === 1 ? translations.general.task.singular : translations.general.task.plural
  }`
  const label = `${sprintsLabel} ${tasksLabel} ${translations.general.selected}`

  return (
    <Container>
      <SquareCheckbox
        checked={checked}
        className='SelectAll__checkbox'
        indeterminate={indeterminate}
        onChange={handleChange}
        size={16}
      />
      <SelectedLabel>{label}</SelectedLabel>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  padding-left: ${innerLeftPadding - selectCheckboxLateralPaddingOffset}px;

  .SelectAll__checkbox {
    margin-right: 14px;
  }
`

Container.displayName = 'Container'

const SelectedLabel = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
`

SelectedLabel.displayName = 'SelectedLabel'
