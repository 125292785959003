import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import AccountInfo from 'features/integrations/components/AccountInfo'

import { asana } from 'gipsy-api'
import { translations } from 'gipsy-misc'

import { fetchIntegrationsData } from 'store/integrations/actions'

const AsanaAccountInfo = React.memo(
  ({ account, onClickGrantAccess, onSyncAccount, onUnsyncAccount, openPopup, closePopup, showModal }) => {
    const dispatch = useDispatch()

    const _onSyncAccount = async () => {
      try {
        await asana.sync(account.id)
        onSyncAccount && onSyncAccount(account.id)
      } catch (err) {
        dispatch(fetchIntegrationsData())
      }
    }

    const _onUnsyncAccount = async () => {
      await asana.unsync(account.id)
      onUnsyncAccount && onUnsyncAccount(account.id)
    }

    const onClickSelectProjects = useCallback(() => {
      showModal(account)
    }, [account, showModal])

    return (
      <AccountInfo
        areCredentialsInvalid={account.areCredentialsInvalid}
        grantAccessButtonLabel={translations.integrations.apps.asana.grantAccess}
        invalidCredentialsLabel={translations.integrations.apps.asana.invalidCredentialsLabel}
        name={account.name}
        picture={account.picture}
        onSyncAccount={_onSyncAccount}
        onUnsyncAccount={_onUnsyncAccount}
        openPopup={openPopup}
        closePopup={closePopup}
        lastSyncId={'lastAsanaSync_' + account.id}
        onClickGrantAccess={onClickGrantAccess}
        onClickSecondaryButton={onClickSelectProjects}
        secondaryButtonLabel={translations.integrations.apps.asana.manageProjects}
        unsyncPopupTitle={translations.general.areSure}
        unsyncPopupSubtitle={translations.integrations.apps.asana.popup.delete.prompt}></AccountInfo>
    )
  }
)

export default AsanaAccountInfo
