import React, { useCallback, useMemo, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Flipper } from 'react-flip-toolkit'
import styled from 'styled-components'

import {
  HeaderContainer,
  NavigationContainer,
  NavigationRightButton,
  NavigationTitle,
  PaddingContainer,
} from 'features/layout/pages'
import CredentialsAlert from 'features/auth/components/CredentialsAlert'
import InProgressSprintLine from 'features/list/components/inprogress-sprint-line'
import { TaskObjectGroups } from 'logic/today/models'
import { GroupContainer, CreatingItemsSection, SelectAll, TopSectionContainer } from 'pages/today/active/components'
import CompletedItems from './components/CompletedItems'
import OtherItems from './components/OtherItems'
import PinnedItems from './components/PinnedItems'
import { InProgressSprintLineDroppableId } from '../container'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { Icon, AnimateHeight } from 'gipsy-ui'
import { mixpanel, models, styles, translations } from 'gipsy-misc'

function TodaySection(props) {
  const {
    allItems,
    computeDroppableId,
    creatingCalendarTask,
    creationLineProps,
    editingCalendarTask,
    getCompletedTaskProps,
    getPinnedTaskProps,
    getRegularTaskProps,
    inProgressSprints,
    isCreatingInlineTask,
    isDragging,
    isTaskCreationAlertShown,
    onArchiveAllCompletedToday,
    onClickDelete,
    onComplete,
    onCompleteFromFS,
    onDragEnd,
    onDragStart,
    onDrop,
    onSave,
    onStartTimer,
    onToggleAllItemsSelection,
    onToggleItemsSelectionByGroups,
    selectedItems,
    selectMode,
    session,
    sprintLineProps,
    sprintTaskProps,
    startInlineTaskCreation,
    startSprintCreation,
    toHideTaskId,
    totalSelectableItems,
    totalSelectableItemsByGroup,
    totalSelectedItemsData,
    updateTaskInState,
  } = props

  const archiveAllButtonRef = useRef()
  const [isArchiveAllButtonVisible, setArchiveAllButtonVisible] = useState(false)

  const handleDragEnd = useCallback(
    (draggableItem) => {
      onDragEnd()
      onDrop(draggableItem)
    },
    [onDragEnd, onDrop]
  )

  const toHideTasksIdsMap = useMemo(() => {
    const idMap = {}

    if (toHideTaskId) {
      idMap[toHideTaskId] = true
    }

    if (editingCalendarTask) {
      idMap[editingCalendarTask.id] = true
    }

    if (session?.completedSession?.taskId) {
      idMap[session.completedSession.taskId] = true
    }

    inProgressSprints.forEach((inProgressSprint) => {
      idMap[inProgressSprint.id] = true
    })

    return idMap
  }, [editingCalendarTask, inProgressSprints, session?.completedSession?.taskId, toHideTaskId])

  const filteredPinnedItems = useMemo(() => {
    const filtered = allItems[TaskObjectGroups.PINNED].reduce((filteredList, item) => {
      if (toHideTasksIdsMap[item.id]) return filteredList

      if (item.type === models.item.type.SPRINT) {
        const filteredSprint = { ...item }
        filteredSprint.tasks = (filteredSprint.tasks || []).filter((task) => !toHideTasksIdsMap[task.id])
        filteredList.push(filteredSprint)
      } else {
        filteredList.push(item)
      }

      return filteredList
    }, [])

    return filtered
  }, [allItems, toHideTasksIdsMap])

  const filteredOtherItems = useMemo(() => {
    const filtered = allItems[TaskObjectGroups.OTHER].filter((item) => !toHideTasksIdsMap[item.id])
    return filtered
  }, [allItems, toHideTasksIdsMap])

  const filteredCompletedItems = useMemo(() => {
    const filtered = allItems[TaskObjectGroups.COMPLETED].filter((item) => !toHideTasksIdsMap[item.id])
    return filtered
  }, [allItems, toHideTasksIdsMap])

  const { totalSelectedItemsByGroup, totalSelectedSprints, totalSelectedTasks } = totalSelectedItemsData

  return (
    <>
      <HeaderContainer>
        <NavigationContainer>
          <NavigationTitle textEllipsis>{translations.todayView.todayFocus}</NavigationTitle>
          <NavigationRightButton
            onClick={() => mixpanelApi.track({ event: mixpanel.navigatedEvent }, mixpanel.clickedAllTasksEvent)}
            to={'/tasks/all'}
            label={translations.todayView.allTasks}
          />
        </NavigationContainer>
      </HeaderContainer>
      <PaddingContainer>
        <CredentialsAlert />
      </PaddingContainer>
      <div>
        <DragDropContext onDragStart={onDragStart} onDragEnd={handleDragEnd}>
          <Droppable droppableId={JSON.stringify({ id: InProgressSprintLineDroppableId })} isDropDisabled>
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {inProgressSprints.map((inProgressSprint, index) => (
                  <Draggable
                    draggableId={inProgressSprint.id}
                    index={index}
                    key={inProgressSprint.id}
                    type={models.item.type.SPRINT}>
                    {(draggableProvided, snapshot) => (
                      <InProgressSprintLine
                        draggableProps={draggableProvided.draggableProps}
                        draggableStyle={draggableProvided.draggableProps.style}
                        dragHandleProps={draggableProvided.dragHandleProps}
                        innerRef={draggableProvided.innerRef}
                        isCalendarDraggable={true}
                        isDragging={snapshot.isDragging}
                        sprint={inProgressSprint}
                        onComplete={onComplete}
                        onSave={onSave}
                        selected={!!selectedItems[inProgressSprint.id]}
                        selectMode={sprintLineProps.selectMode}
                        startSprintCreation={startSprintCreation}
                        onClickDelete={onClickDelete}
                        onCompleteFromFS={onCompleteFromFS}
                        onEndSprint={sprintLineProps.onEnd}
                        onRemoveFromSprint={sprintTaskProps.onRemoveFromSprint}
                        onClickEditSprint={sprintLineProps.onClickEdit}
                        onClickDeleteSprint={sprintLineProps.onClickDelete}
                        onDeleteFocusSession={sprintLineProps.onDeleteFocusSession}
                        onSelect={sprintLineProps.onSelect}
                        computeDroppableId={computeDroppableId}
                        sprintInlineTaskProps={sprintLineProps.sprintInlineTaskProps}
                        updateTaskInState={updateTaskInState}
                      />
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
          <GroupContainer>
            <TopSectionContainer>
              {selectMode ? (
                <SelectAll
                  onCheckboxClicked={onToggleAllItemsSelection}
                  selectedSprints={totalSelectedSprints}
                  selectedTasks={totalSelectedTasks}
                  totalItems={totalSelectableItems}
                />
              ) : (
                <CreatingItemsSection
                  session={session}
                  isCreatingInlineTask={isCreatingInlineTask}
                  isCreatingCalendarTask={!!creatingCalendarTask}
                  creationLineProps={creationLineProps}
                  isTaskCreationAlertShown={isTaskCreationAlertShown}
                  startInlineTaskCreation={startInlineTaskCreation}
                  startSprintCreation={startSprintCreation}
                  onStartTimer={onStartTimer}
                />
              )}
            </TopSectionContainer>

            <Flipper flipKey={allItems[TaskObjectGroups.COMPLETED]}>
              <PinnedItems
                getTaskProps={getPinnedTaskProps}
                groupSelectedItems={totalSelectedItemsByGroup[[TaskObjectGroups.PINNED]]}
                groupTotalItems={totalSelectableItemsByGroup[TaskObjectGroups.PINNED]}
                items={filteredPinnedItems}
                onCheckboxClicked={onToggleItemsSelectionByGroups}
                selectedItems={selectedItems}
                selectMode={selectMode}
                sprintProps={sprintLineProps}
                withSelect
              />
              <OtherItems
                canMoveToTopToday
                canScheduleToToday
                getTaskProps={getRegularTaskProps}
                groupSelectedItems={totalSelectedItemsByGroup[[TaskObjectGroups.OTHER]]}
                groupTotalItems={totalSelectableItemsByGroup[TaskObjectGroups.OTHER]}
                items={filteredOtherItems}
                onCheckboxClicked={onToggleItemsSelectionByGroups}
                selectMode={selectMode}
                withSelect
              />
              <CompletedItems
                archiveAllButtonRef={archiveAllButtonRef}
                getTaskProps={getCompletedTaskProps}
                groupSelectedItems={totalSelectedItemsByGroup[[TaskObjectGroups.COMPLETED]]}
                groupTotalItems={totalSelectableItemsByGroup[TaskObjectGroups.COMPLETED]}
                isDragging={isDragging}
                items={filteredCompletedItems}
                onCheckboxClicked={onToggleItemsSelectionByGroups}
                selectMode={selectMode}
                setArchiveAllButtonVisible={setArchiveAllButtonVisible}
                withSelect
              />
            </Flipper>

            <AnimateHeight duration={300} height={isArchiveAllButtonVisible ? 'auto' : 0}>
              <ArchiveAllButton
                ref={archiveAllButtonRef}
                onMouseLeave={() => setArchiveAllButtonVisible(false)}
                onClick={onArchiveAllCompletedToday}>
                <CloseIcon icon='Close' size={9} fill={styles.colors.veryLightGrey} />
                {translations.todayView.completed.archiveAll}
              </ArchiveAllButton>
            </AnimateHeight>
          </GroupContainer>
        </DragDropContext>
      </div>
    </>
  )
}

export default React.memo(TodaySection)

export const ArchiveAllButton = styled.div`
  cursor: pointer;
  display: flex;
  background: ${styles.colors.veryLightGrey};
  align-items: center;
  justify-content: center;
  padding: ${styles.spacings.small} 0;
  margin: 0 29px 13px;
  font-size: ${styles.fonts.fontSizeXSmall};
  border-radius: ${styles.spacings.normal};
`

ArchiveAllButton.displayName = 'ArchiveAllButton'

export const CloseIcon = styled(Icon)`
  margin-right: 15px;
`

CloseIcon.displayName = 'CloseIcon'
