import React from 'react'

import { translations } from 'gipsy-misc'
import { Project } from 'gipsy-misc/types'
import { ProjectDropdownSelector } from 'gipsy-ui'

import { IndeterminateStateMap, ItemsPerEntry } from 'logic/batchActions'

import DropdownButton from './DropdownButton'
interface Props {
  activeProjects: Project[]
  onSelect: (projects: Project[], indeterminateIds: IndeterminateStateMap) => void
  selectedItemsPerProject: ItemsPerEntry
  shrinked: boolean
  totalSelectedItems: number
}

export default function ProjectsButton({
  activeProjects,
  onSelect,
  selectedItemsPerProject,
  shrinked,
  totalSelectedItems,
}: Props) {
  return (
    <DropdownButton<Project>
      activeItems={activeProjects}
      buttonText={translations.general.projects}
      dropdownComponent={ProjectDropdownSelector}
      emptyLabel={translations.projects.noProjects}
      icon='FolderOutlined'
      onSelect={onSelect}
      selectedItemsPerActiveEntry={selectedItemsPerProject}
      shrinked={shrinked}
      totalSelectedItems={totalSelectedItems}
    />
  )
}
