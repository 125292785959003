import cloneDeep from 'lodash/cloneDeep'
import produce from 'immer'

import { models } from 'gipsy-misc'
import { Sprint, Task } from 'gipsy-misc/types'

export type GroupItem = Sprint | Task
export type GroupsOrder = { [key: string]: number }
export type PageItemsByGroup = { [key: string]: GroupItem[] }
export type SelectionData = {
  group: string
  item: GroupItem
  sprint?: Sprint
}
export type PivotData = SelectionData | null
export type SelectedItems = { [key: string]: string }

export const initGroupList = <T>(pageGroups: { [key: string]: string }, initialValue: T): { [key: string]: T } =>
  Object.values(pageGroups).reduce((list, group) => {
    list[group] = cloneDeep(initialValue)
    return list
  }, {})

export const getGroupsInBetween = (groupsOrder: GroupsOrder, startGroup: string, endGroup: string): string[] => {
  const startBound = groupsOrder[startGroup]
  const endBound = groupsOrder[endGroup]

  if (startBound === endBound) return []

  return Object.keys(groupsOrder).slice(Math.min(startBound, endBound) + 1, Math.max(startBound, endBound))
}

export const isSelectedGroupAfter = (groupsOrder: GroupsOrder, targetGroup: string, pivotGroup: string): boolean => {
  return groupsOrder[targetGroup] > groupsOrder[pivotGroup]
}

export const isSelectedGroupBefore = (groupsOrder: GroupsOrder, targetGroup: string, pivotGroup: string): boolean => {
  return groupsOrder[targetGroup] < groupsOrder[pivotGroup]
}

export const selectItemInGroup = (item: GroupItem, selectedItems: SelectedItems, group: string): SelectedItems => {
  return produce(selectedItems, (draft: SelectedItems) => {
    draft[item.id] = group

    if (item.type === models.item.type.SPRINT) {
      const sprint = item as Sprint
      sprint.tasks?.forEach?.((t) => {
        draft[t.id] = group
      })
    }
  })
}

export const unselectItemInGroup = (item: GroupItem, selectedItems: SelectedItems): SelectedItems => {
  return produce(selectedItems, (draft: SelectedItems) => {
    delete draft[item.id]

    if (item.type === models.item.type.SPRINT) {
      const sprint = item as Sprint
      sprint.tasks?.forEach?.((t) => {
        delete draft[t.id]
      })
    }
  })
}

const selectAllItemsForGroup = (
  selectedItems: SelectedItems,
  pageItemsByGroup: PageItemsByGroup,
  group: string
): SelectedItems => {
  const groupItems = pageItemsByGroup[group]
  return groupItems.reduce((currentItems: SelectedItems, groupItem: GroupItem) => {
    return selectItemInGroup(groupItem, currentItems, group)
  }, selectedItems)
}

const unselectAllItemsForGroup = (
  selectedItems: SelectedItems,
  pageItemsByGroup: PageItemsByGroup,
  group: string
): SelectedItems => {
  const groupItems = pageItemsByGroup[group]
  return groupItems.reduce((currentItems: SelectedItems, groupItem: GroupItem) => {
    return unselectItemInGroup(groupItem, currentItems)
  }, selectedItems)
}

export const setSelectionForGroups = (
  selectedItems: SelectedItems,
  pageItemsByGroup: PageItemsByGroup,
  groups: string[],
  selected: boolean
): SelectedItems => {
  return groups.reduce((currentItems: SelectedItems, group: string) => {
    if (selected) {
      return selectAllItemsForGroup(currentItems, pageItemsByGroup, group)
    } else {
      return unselectAllItemsForGroup(currentItems, pageItemsByGroup, group)
    }
  }, selectedItems)
}

const selectPivotItem = (selectedItemData: SelectionData): { pivot: PivotData; selectedItems: SelectedItems } => {
  const { group, item } = selectedItemData
  const newSelectedItems = selectItemInGroup(item, {}, group)
  const newPivot = selectedItemData
  return { pivot: newPivot, selectedItems: newSelectedItems }
}

const selectContiguousItemsWithinSprint = (
  sprintGroup: string,
  sprintTasks: Task[],
  pivotItemIdx: number,
  targetItemIdx: number
): SelectedItems => {
  if (pivotItemIdx < 0 || targetItemIdx < 0) return {}

  const toSelectItems = sprintTasks.slice(
    Math.min(pivotItemIdx, targetItemIdx),
    Math.max(pivotItemIdx, targetItemIdx) + 1
  )

  return toSelectItems.reduce((currentItems: SelectedItems, item: Task) => {
    currentItems[item.id] = sprintGroup
    return currentItems
  }, {})
}

const selectGroupsInBetweenSelectionAndPivot = (
  selectedItemData: SelectionData,
  pivotData: PivotData,
  pageItemsByGroup: PageItemsByGroup,
  groupsOrder: GroupsOrder
): SelectedItems => {
  const inBetweenGroups = getGroupsInBetween(groupsOrder, (pivotData as SelectionData).group, selectedItemData.group)
  return inBetweenGroups.reduce((currentItems: SelectedItems, group: string) => {
    pageItemsByGroup[group].forEach((item) => {
      currentItems = selectItemInGroup(item, currentItems, group)
    })

    return currentItems
  }, {})
}

const selectContiguousItemsInPivotAndTargetGroups = (
  selectedItemData: SelectionData,
  pivotData: PivotData,
  pageItemsByGroup: PageItemsByGroup,
  groupsOrder: GroupsOrder
): SelectedItems => {
  const { group: pGroup, item: pItem, sprint: pSprint } = pivotData as SelectionData
  const { group: sGroup, item: sItem, sprint: sSprint } = selectedItemData
  const isTargetGroupBeforePivotGroup = isSelectedGroupBefore(groupsOrder, sGroup, pGroup)
  const isTargetGroupAfterPivotGroup = isSelectedGroupAfter(groupsOrder, sGroup, pGroup)
  const isTargetGroupSameAsPivot = !isTargetGroupBeforePivotGroup && !isTargetGroupAfterPivotGroup
  const pGroupItems = pageItemsByGroup[pGroup]
  const sGroupItems = pageItemsByGroup[sGroup]
  const sItemIdx = sGroupItems.findIndex((item) => item.id === (sSprint ? sSprint.id : sItem.id))
  const pItemIdx = pGroupItems.findIndex((item) => item.id === (pSprint ? pSprint.id : pItem.id))

  if (sItemIdx === -1 || pItemIdx === -1) return {}

  let nestedPIdx = -1
  if (pSprint?.tasks) {
    nestedPIdx = pSprint.tasks.findIndex((t) => t.id === pItem.id)
  }

  let nestedSIdx = -1
  if (sSprint?.tasks) {
    nestedSIdx = sSprint.tasks.findIndex((t) => t.id === sItem.id)
  }

  if (isTargetGroupSameAsPivot) {
    const itemsInRange = sGroupItems.slice(Math.min(sItemIdx, pItemIdx), Math.max(sItemIdx, pItemIdx) + 1)
    return itemsInRange.reduce((currentItems, item) => {
      if (nestedPIdx !== -1 && item === pSprint) {
        const selectTopToBottom = sItemIdx > pItemIdx
        const range: [number, number] = selectTopToBottom ? [nestedPIdx, pSprint!.tasks!.length - 1] : [0, nestedPIdx]
        return produce(currentItems, (draft: SelectedItems) => {
          return {
            ...draft,
            [pSprint!.id]: pGroup,
            ...selectContiguousItemsWithinSprint(pGroup, pSprint!.tasks!, ...range),
          }
        })
      }

      if (nestedSIdx !== -1 && item === sSprint) {
        const selectTopToBottom = sItemIdx < pItemIdx
        const range: [number, number] = selectTopToBottom ? [nestedSIdx, sSprint!.tasks!.length - 1] : [0, nestedSIdx]
        return produce(currentItems, (draft: SelectedItems) => {
          return {
            ...draft,
            [sSprint!.id]: sGroup,
            ...selectContiguousItemsWithinSprint(sGroup, sSprint!.tasks!, ...range),
          }
        })
      }

      return selectItemInGroup(item, currentItems, sGroup)
    }, {})
  } else if (isTargetGroupBeforePivotGroup) {
    const itemsInPivotGroupInRange = pGroupItems.slice(0, pItemIdx + 1)
    const pivotGroupItemsToSelect = itemsInPivotGroupInRange.reduce((currentItems, item) => {
      if (nestedPIdx !== -1 && item === pSprint) {
        return produce(currentItems, (draft: SelectedItems) => {
          return {
            ...draft,
            [pSprint!.id]: pGroup,
            ...selectContiguousItemsWithinSprint(pGroup, pSprint!.tasks!, 0, nestedPIdx),
          }
        })
      }

      return selectItemInGroup(item, currentItems, pGroup)
    }, {})

    const itemsInSelectedGroupRange = sGroupItems.slice(sItemIdx, sGroupItems.length)
    const selectedGroupItemsToSelect = itemsInSelectedGroupRange.reduce((currentItems, item) => {
      if (nestedSIdx !== -1 && item === sSprint) {
        return produce(currentItems, (draft: SelectedItems) => {
          return {
            ...draft,
            [sSprint!.id]: sGroup,
            ...selectContiguousItemsWithinSprint(sGroup, sSprint!.tasks!, nestedSIdx, sSprint!.tasks!.length - 1),
          }
        })
      }

      return selectItemInGroup(item, currentItems, sGroup)
    }, {})

    return {
      ...pivotGroupItemsToSelect,
      ...selectedGroupItemsToSelect,
    }
  } else if (isTargetGroupAfterPivotGroup) {
    const itemsInPivotGroupInRange = pGroupItems.slice(pItemIdx, pGroupItems.length)
    const pivotGroupItemsToSelect = itemsInPivotGroupInRange.reduce((currentItems, item) => {
      if (nestedPIdx !== -1 && item === pSprint) {
        return produce(currentItems, (draft: SelectedItems) => {
          return {
            ...draft,
            [pSprint!.id]: pGroup,
            ...selectContiguousItemsWithinSprint(pGroup, pSprint!.tasks!, nestedPIdx, pSprint!.tasks!.length - 1),
          }
        })
      }

      return selectItemInGroup(item, currentItems, pGroup)
    }, {})

    const itemsInSelectedGroupRange = sGroupItems.slice(0, sItemIdx + 1)
    const selectedGroupItemsToSelect = itemsInSelectedGroupRange.reduce((currentItems, item) => {
      if (nestedSIdx !== -1 && item === sSprint) {
        return produce(currentItems, (draft: SelectedItems) => {
          return {
            ...draft,
            [sSprint!.id]: sGroup,
            ...selectContiguousItemsWithinSprint(sGroup, sSprint!.tasks!, 0, nestedSIdx),
          }
        })
      }

      return selectItemInGroup(item, currentItems, sGroup)
    }, {})

    return {
      ...pivotGroupItemsToSelect,
      ...selectedGroupItemsToSelect,
    }
  }

  return {}
}

export const getContiguousSelection = (
  selectedItemData: SelectionData,
  pivotData: PivotData,
  selectedItems: SelectedItems,
  pageItemsByGroup: PageItemsByGroup,
  groupsOrder: GroupsOrder
): { pivot: PivotData; selectedItems: SelectedItems } => {
  if (!pivotData) {
    return selectPivotItem(selectedItemData)
  }

  if (selectedItemData.item === pivotData.item) {
    return { pivot: pivotData, selectedItems }
  }

  if (selectedItemData.sprint && selectedItemData.sprint === pivotData.sprint) {
    const sprintTasks = selectedItemData.sprint.tasks || []
    const pivotIdx = sprintTasks.findIndex((t) => t.id === pivotData.item.id)
    const selectedIdx = sprintTasks.findIndex((t) => t.id === selectedItemData.item.id)
    const newSelectedItems = selectContiguousItemsWithinSprint(pivotData.group, sprintTasks, pivotIdx, selectedIdx)
    return { pivot: pivotData, selectedItems: newSelectedItems }
  }

  const newSelectedItems = {
    ...selectGroupsInBetweenSelectionAndPivot(selectedItemData, pivotData, pageItemsByGroup, groupsOrder),
    ...selectContiguousItemsInPivotAndTargetGroups(selectedItemData, pivotData, pageItemsByGroup, groupsOrder),
  }

  return {
    pivot: pivotData,
    selectedItems: newSelectedItems,
  }
}
