import React, { useState, useEffect, useMemo, useRef, Fragment, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { Transition } from 'react-transition-group'
import throttle from 'lodash/throttle'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CalendarList from 'features/calendar/components/CalendarList'
import useCalendarActions from 'features/calendar/components/CalendarPanel/hooks/useCalendarActions'
import layoutBreakpoints from 'features/layout/breakpoints'
import { getPageSourceFromRoute } from 'features/source'
import { expandHandleWidth } from 'features/taskPanel/components/ExpandHandle'
import { shouldShowCalendar } from 'logic/layout'
import { updateCalendarDate, updateScrollToTime, setCalendarExpanded } from 'store/calendar/actions'
import { setPanelExpanded as setTaskPanelExpanded, setPanelOpen as setTaskPanelOpen } from 'store/taskPanel/actions'
import { getEventsArray } from 'store/calendar/selectors'
import variables from 'assets/styles/variables.js'
import { AddCalendarItemPopup, EditCalendarItemPopup, TaskLineModal } from './components'
import CalendarNav, { NavigationContainer } from './components/CalendarNav'
import PlanMyDayLightbox from './components/PlanMyDayLightbox'
import SprintComposer, { sprintComposerPortalId } from 'features/sprintComposer'
import { getItemsAsCalendarEvents } from 'store/items/selectors'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { Calendar, Icon } from 'gipsy-ui'
import { mixpanel, styles, utils, translations, variables as miscVariables } from 'gipsy-misc'

export const calendarBlurLayerPortalId = 'calendar-blur-layer-portal'

const { calendarPanelWidth, calendarLateralPadding, taskPanelContainerWidth } = variables

const propTypes = {
  creatingCalendarTask: PropTypes.object,
  editingCalendarTask: PropTypes.object,
  onDismissTogglePin: PropTypes.func,
  startSprintEdition: PropTypes.func,
  isDragging: PropTypes.bool,
  onCreateTask: PropTypes.func,
  onSaveTask: PropTypes.func,
  cancelCalendarTaskAction: PropTypes.func,
  actions: PropTypes.shape({
    updateCalendarDate: PropTypes.func,
    openTaskPanel: PropTypes.func,
    setTaskPanelOpen: PropTypes.func,
  }),
}

function useCalendarViewModeChanged(isCalendarExpanded) {
  const isCalendarExpandedRef = useRef(false)
  const hasChanged = isCalendarExpandedRef.current !== isCalendarExpanded
  if (hasChanged) isCalendarExpandedRef.current = isCalendarExpanded
  return hasChanged
}

const CalendarPanel = (props) => {
  const {
    session,
    calendarContainerRef,
    calendarDate: storeCalendarDate,
    cancelCalendarTaskAction,
    creatingCalendarTask,
    creatingSprint,
    customCancelButton,
    editingCalendarTask,
    editingSprint,
    hideSprintComposer,
    highlightedEventId,
    inOnboarding,
    isCalendarExpanded,
    isDragging,
    isSprintComposerShown,
    isTaskPanelExpanded,
    itemEvents,
    navigationStats,
    onClickCancelSprint,
    onCreateSprint,
    onCreateCalendarTask,
    onEditSprint,
    onSaveCalendarTask,
    onTogglePinFromCalendarPanel,
    scrollToTime,
    showCalendar,
    startSprintCreation,
    startSprintEdition,
    windowWidth,
  } = props

  const { setTaskPanelExpanded, setTaskPanelOpen, updateCalendarDate, updateScrollToTime } = props.actions

  const location = useLocation()

  const [animating, setAnimating] = useState(false)
  const [planMyDayDate, setPlanMyDayDate] = useState(null)
  const hasViewModeChanged = useCalendarViewModeChanged(isCalendarExpanded)
  const hasTrackedFirstLand = useRef(false)

  const fromOnboarding = location.state?.fromOnboarding
  const pageSource = getPageSourceFromRoute(location.pathname)

  useEffect(() => {
    if (fromOnboarding && !hasTrackedFirstLand.current) {
      mixpanelApi.track({ event: mixpanel.onboardingFirstTimeCalendarLandedEvent })
      hasTrackedFirstLand.current = true
    }
  }, [fromOnboarding])

  const calendarItems = useMemo(() => {
    const items = (props.events || []).concat(itemEvents)
    const onboardingSprint = location?.state?.onboardingSprint

    if (onboardingSprint) {
      items.push(utils.calendar.parseItemToEvent(onboardingSprint))
    }

    return items
  }, [itemEvents, location, props.events])

  const _hideSprintComposer = useCallback(() => {
    if (!isCalendarExpanded) {
      setTaskPanelOpen(false)
    }
    hideSprintComposer()
  }, [isCalendarExpanded, hideSprintComposer, setTaskPanelOpen])

  const fourDayMode = windowWidth < layoutBreakpoints.tablet

  const {
    addItemPopupRef,
    addItemPopupPositionProps,
    calendarDate,
    calendarRef,
    clickedItem,
    completeCalendarTask,
    computeSlotFromSprint,
    editItemPopupRef,
    filteredCalendarItems,
    getCalendarRef,
    getSelectedSlotBounds,
    handleEditingItemUpdate,
    handleUpdateClickedItem,
    hideModalsAndPopups,
    isAddItemPopupShown,
    isEditItemPopupShown,
    isTaskLineModalShown,
    localEditingCalendarTask,
    onCancelTaskAction,
    onChangeAddCalendarItemTitle,
    onChangeDuration,
    onChangeSlotRange,
    onChangeStartDate,
    onChangeStartTime,
    onClickDeleteClickedItem,
    onClickDeleteTask,
    onClickDeleteSprint,
    onClickEvent,
    onClickLeftArrow,
    onClickOutsideCalendarModalOrPopup,
    onClickRemoveTask,
    onClickRightArrow,
    onClickToday,
    onCreateSprint: onCreateSprintFromCalendar,
    onCreateTask: onCreateTaskFromCalendar,
    onCurrentTaskChange,
    onDragOut,
    onEventDropMiddleware,
    onEventResize,
    onSaveTask,
    onSelectSlot,
    onTogglePin,
    prevEditingPopupPositionProps,
    selectedSlot,
    setCalendarDate,
    setSelectedSlot,
    startFocusSession,
    startLocalCalendarItemEdition,
    startSprintCreation: startSprintCreationFromCalendar,
    startTaskCreation,
    registerShortcuts,
    unregisterShortcuts,
  } = useCalendarActions({
    calendarContainerRef,
    calendarIsAnimating: animating,
    calendarItems,
    callbacks: {
      onClickDeleteSprintCallback: _hideSprintComposer,
    },
    cancelCalendarTaskAction,
    creatingCalendarTask,
    creatingSprint,
    date: storeCalendarDate,
    editingCalendarTask,
    editingSprint,
    fourDayMode,
    isDragging,
    isSprintComposerShown,
    onCreateCalendarTask,
    onCreateSprint,
    onSaveCalendarTask,
    onTogglePinFromCalendarPanel,
    startSprintCreation,
    startSprintEdition,
    updateScrollToTime,
    weekView: isCalendarExpanded,
  })

  const isCalendarSectionExpanded = isCalendarExpanded && !isSprintComposerShown

  useEffect(() => {
    if (isCalendarExpanded || isSprintComposerShown) {
      setTaskPanelOpen(true)
    }
  }, [isSprintComposerShown, isCalendarExpanded, setTaskPanelOpen])

  useEffect(() => {
    if (!showCalendar) {
      hideModalsAndPopups()

      if (!isSprintComposerShown) {
        setTaskPanelOpen(false)
      }
    }
  }, [hideModalsAndPopups, isSprintComposerShown, setTaskPanelOpen, showCalendar])

  useEffect(() => {
    setCalendarDate(storeCalendarDate)
  }, [setCalendarDate, storeCalendarDate])

  const computeCalendarTitle = useCallback(() => {
    return isCalendarSectionExpanded
      ? moment(utils.date.getStartOfWeek(calendarDate, session.user.settingsPreferences.calendar.firstDay)).format(
          'MMMM YYYY'
        )
      : moment(calendarDate).format('ddd, MMM DD')
  }, [isCalendarSectionExpanded, calendarDate, session])

  const throttleClick = React.useMemo(
    () =>
      throttle(() => {
        const start = new Date(calendarDate)
        const end = new Date(moment(calendarDate).add(30, 'm'))
        setSelectedSlot({
          start,
          end,
          item: selectedSlot?.item || { title: '' },
        })
        // wait for event highlight to be rendered in order to find its bounds
        setTimeout(() => {
          updateScrollToTime(start)
          onSelectSlot({ start, end })
        })
      }, 1000),
    [calendarDate, onSelectSlot, selectedSlot?.item, setSelectedSlot, updateScrollToTime]
  )

  const onClickCreate = useCallback(
    (e) => {
      e.stopPropagation() // to avoid onClickOutside from being called
      throttleClick(e)
    },
    [throttleClick]
  )

  useEffect(() => {
    if (hasViewModeChanged) {
      const componentName = 'CalendarPanel'
      if (isCalendarExpanded) {
        registerShortcuts(
          [
            {
              label: translations.sprint.create,
              key: 'b',
              callback: startSprintCreationFromCalendar,
            },
          ],
          componentName
        )
      } else {
        unregisterShortcuts(componentName)
      }
    }
  }, [hasViewModeChanged, isCalendarExpanded, registerShortcuts, startSprintCreationFromCalendar, unregisterShortcuts])

  const _onClickCancelSprint = useCallback(() => {
    _hideSprintComposer()
    setSelectedSlot(null)
    onClickCancelSprint()
  }, [_hideSprintComposer, onClickCancelSprint, setSelectedSlot])

  const onCurrentSprintChangeDelay = useRef()

  const onCurrentSprintChange = useCallback(
    (sprint) => {
      const updateSelectedSlot = () =>
        setSelectedSlot((selectedSlot) => {
          if (selectedSlot) {
            return {
              ...selectedSlot,
              ...computeSlotFromSprint(sprint),
            }
          } else {
            return selectedSlot
          }
        })

      // delay updates to the calendar's pinned focus block, 80ms works best to prevent lag on input changes and updates event's duration seamlessly
      window.clearTimeout(onCurrentSprintChangeDelay.current)
      onCurrentSprintChangeDelay.current = setTimeout(updateSelectedSlot, 80)
    },
    [computeSlotFromSprint, setSelectedSlot]
  )

  const _onCreateSprint = useCallback(
    (sprint) => {
      onCreateSprintFromCalendar(sprint)
      hideModalsAndPopups()
      _hideSprintComposer()
      setSelectedSlot(null)
    },
    [_hideSprintComposer, hideModalsAndPopups, onCreateSprintFromCalendar, setSelectedSlot]
  )

  const _onEditSprint = useCallback(
    async (...data) => {
      _hideSprintComposer()
      setSelectedSlot(null)
      onEditSprint(...data)
    },
    [_hideSprintComposer, onEditSprint, setSelectedSlot]
  )

  const changePlanMyDayDate = useCallback((date) => {
    setPlanMyDayDate(moment(date).format('YYYY-MM-DD'))
    mixpanelApi.track({ event: mixpanel.daysTasksClickedEvent })
  }, [])

  const handleLightboxClosed = useCallback(() => {
    setPlanMyDayDate(null)
  }, [])

  const onCalendarListExpandToggled = useCallback(
    (expanded) => {
      if (expanded && isTaskPanelExpanded) {
        setTaskPanelExpanded(false)
      }
    },
    [isTaskPanelExpanded, setTaskPanelExpanded]
  )

  const onAnimationStart = useCallback(() => {
    setAnimating(true)
  }, [])

  const onAnimationEnd = useCallback(() => {
    // prevent task modal from rendering in the wrong position
    setTimeout(() => {
      setAnimating(false)
    })
  }, [])

  const { hasNavigated, initialRoute } = navigationStats
  const initialAnimationState = hasNavigated
    ? 'hide'
    : shouldShowCalendar(initialRoute)
    ? isCalendarSectionExpanded
      ? isTaskPanelExpanded
        ? 'showExpanded'
        : 'showExpandedFullScreen'
      : 'showCollapsed'
    : 'hide'
  const sprintComposerNode = document.querySelector(`#${sprintComposerPortalId}`)

  const firstDayOfWeek = session.user.settingsPreferences.calendar.firstDay
  const showLightbox = !!(isCalendarSectionExpanded && planMyDayDate)
  const smallDesktopResolution = windowWidth > layoutBreakpoints.desktopSmall

  let zIndex =
    isTaskLineModalShown && !isCalendarSectionExpanded
      ? miscVariables.zIndex.pageBlurLayer
      : isCalendarSectionExpanded
      ? miscVariables.zIndex.taskPanel
      : miscVariables.zIndex.calendarPanel

  return (
    <Fragment>
      {!inOnboarding &&
        sprintComposerNode &&
        createPortal(
          <SprintComposer
            isShown={isSprintComposerShown}
            editingSprint={editingSprint}
            creatingSprint={creatingSprint}
            onCreate={_onCreateSprint}
            enterDelay={isCalendarExpanded && styles.transitions.calendarSlide * 1000}
            onCurrentSprintChange={onCurrentSprintChange}
            onEdit={_onEditSprint}
            onDelete={onClickDeleteSprint}
            onCancel={_onClickCancelSprint}
            selectedSlot={selectedSlot}
            showCalendar={showCalendar}
            customCancelButton={customCancelButton}
            registerShortcuts={registerShortcuts}
            unregisterShortcuts={unregisterShortcuts}
            updateCalendarDate={updateCalendarDate}
          />,
          sprintComposerNode
        )}

      <Transition in={isCalendarSectionExpanded} timeout={styles.transitions.calendarSlide * 1000}>
        {(slideTransitionState) => (
          <Fragment>
            <AnimatePresence>
              {showCalendar && (
                <Container
                  animate={
                    isCalendarSectionExpanded
                      ? isTaskPanelExpanded
                        ? 'showExpanded'
                        : 'showExpandedFullScreen'
                      : 'showCollapsed'
                  }
                  exit={'hide'}
                  initial={initialAnimationState}
                  key='calendar-container'
                  onAnimationStart={onAnimationStart}
                  onAnimationComplete={onAnimationEnd}
                  slideTransitionState={slideTransitionState}
                  zIndex={zIndex}
                  variants={calendarPanelVariants}>
                  {isCalendarSectionExpanded && (
                    <CalendarList
                      hasNavigated={hasNavigated}
                      onExpandToggled={onCalendarListExpandToggled}
                      shouldCollapse={isTaskPanelExpanded}
                    />
                  )}
                  <CalendarContainer expanded={isCalendarSectionExpanded} ref={calendarContainerRef}>
                    <div id={calendarBlurLayerPortalId} />
                    {!animating && isAddItemPopupShown && (
                      <AddCalendarItemPopup
                        firstDayOfWeek={firstDayOfWeek}
                        onClose={hideModalsAndPopups}
                        top={addItemPopupPositionProps.top}
                        shouldFlipTail={addItemPopupPositionProps.shouldFlipTail}
                        left={isCalendarSectionExpanded ? addItemPopupPositionProps.left : undefined}
                        onClickOutside={onClickOutsideCalendarModalOrPopup}
                        ref={addItemPopupRef}
                        onChangeStartDate={onChangeStartDate}
                        onChangeDuration={onChangeDuration}
                        onTogglePin={onTogglePin}
                        selectedSlot={selectedSlot}
                        startSprintCreation={startSprintCreationFromCalendar}
                        onCreateTask={onCreateTaskFromCalendar}
                        onCreateSprint={_onCreateSprint}
                        onChangeSlotRange={onChangeSlotRange}
                        onChangeTitle={onChangeAddCalendarItemTitle}
                        startTaskCreation={startTaskCreation}
                        showTooltip={!isCalendarSectionExpanded}
                      />
                    )}
                    {!animating && isEditItemPopupShown && (
                      <EditCalendarItemPopup
                        ref={editItemPopupRef}
                        onClickEdit={startLocalCalendarItemEdition}
                        onClickDelete={onClickDeleteClickedItem}
                        onClickDeleteTask={onClickDeleteTask}
                        onClickRemoveTask={onClickRemoveTask}
                        onUpdateClickedItem={handleUpdateClickedItem}
                        onClickStart={startFocusSession}
                        onClickComplete={completeCalendarTask}
                        positionVerticallyOnly={!isCalendarSectionExpanded}
                        onClickOutside={onClickOutsideCalendarModalOrPopup}
                        item={clickedItem}
                        getCalendarRef={getCalendarRef}
                        getSelectedSlotBounds={getSelectedSlotBounds}
                        onClose={hideModalsAndPopups}
                        onUpdateItem={handleEditingItemUpdate}
                        pageSource={pageSource}
                        registerShortcuts={registerShortcuts}
                        unregisterShortcuts={unregisterShortcuts}
                        firstDayOfWeek={firstDayOfWeek}
                      />
                    )}

                    {!animating && !isEditItemPopupShown && (
                      <TaskLineModal
                        showModal={isTaskLineModalShown}
                        onClickOutside={onClickOutsideCalendarModalOrPopup}
                        getCalendarRef={getCalendarRef}
                        getSelectedSlotBounds={getSelectedSlotBounds}
                        onSave={onSaveTask}
                        fixedPosition={isCalendarSectionExpanded}
                        onTogglePin={onTogglePin}
                        onCancel={onCancelTaskAction}
                        onCreate={onCreateTaskFromCalendar}
                        onDelete={onClickDeleteTask}
                        creatingCalendarTask={props.creatingCalendarTask}
                        editingCalendarTask={props.editingCalendarTask}
                        localEditingCalendarTask={localEditingCalendarTask}
                        selectedSlot={selectedSlot}
                        startSprintCreation={startSprintCreationFromCalendar}
                        onCurrentTaskChange={onCurrentTaskChange}
                        onChangeDuration={onChangeDuration}
                        onChangeStartTime={onChangeStartTime}
                        prevEditingPopupPositionProps={prevEditingPopupPositionProps}
                      />
                    )}

                    {(isCalendarSectionExpanded || !isTaskLineModalShown) && (
                      <CalendarNav
                        calendarDate={calendarDate}
                        calendarTitle={computeCalendarTitle()}
                        dayView={!isCalendarSectionExpanded}
                        fullWeekContainerProps={{ slideTransitionState }}
                        onClickCreate={smallDesktopResolution ? onClickCreate : undefined}
                        onClickLeftArrow={onClickLeftArrow}
                        onClickRightArrow={onClickRightArrow}
                        onClickToday={onClickToday}
                      />
                    )}

                    {showLightbox && (
                      <PlanMyDayLightbox
                        calendarItems={calendarItems}
                        date={planMyDayDate}
                        onChangePlanMyDayDate={changePlanMyDayDate}
                        onClose={handleLightboxClosed}
                      />
                    )}

                    {!showLightbox && (
                      <Transition
                        in={['entered', 'exited'].includes(slideTransitionState)}
                        mountOnEnter
                        unmountOnExit
                        timeout={0}
                        exit={false}>
                        {(calendarTransitionState) => (
                          <StyledCalendar
                            firstDay={session.user.settingsPreferences.calendar.firstDay}
                            transitionState={calendarTransitionState}
                            isDragging={isDragging}
                            disableSelection={isEditItemPopupShown}
                            hasDndNavigation={false}
                            ref={calendarRef}
                            viewMode={
                              isCalendarSectionExpanded
                                ? fourDayMode
                                  ? Calendar.viewModes.FOUR_DAYS
                                  : Calendar.viewModes.WEEK
                                : Calendar.viewModes.DAY
                            }
                            calendarItems={filteredCalendarItems}
                            onClickPrevButton={onClickLeftArrow}
                            onClickNextButton={onClickRightArrow}
                            date={calendarDate}
                            scrollToTime={scrollToTime}
                            highlightedEventId={highlightedEventId}
                            onSelectSlot={onSelectSlot}
                            isDraggable
                            onEventResize={onEventResize}
                            onClickEvent={onClickEvent}
                            onEventDrop={onEventDropMiddleware}
                            onDragOut={onDragOut}
                            onPlanMyDayTriggerClicked={smallDesktopResolution ? changePlanMyDayDate : undefined}
                          />
                        )}
                      </Transition>
                    )}
                  </CalendarContainer>
                </Container>
              )}
            </AnimatePresence>
          </Fragment>
        )}
      </Transition>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  session: state.session,
  events: getEventsArray(state),
  calendarDate: state.calendar.settings.calendarDate,
  scrollToTime: state.calendar.settings.scrollToTime,
  highlightedEventId: state.calendar.items.highlightedEventId,
  isTaskPanelExpanded: state.taskPanel.settings.isExpanded,
  isTaskPanelItemDragging: state.taskPanel.dragAndDrop.isDragging,
  isCalendarExpanded: state.calendar.settings.isExpanded,
  itemEvents: getItemsAsCalendarEvents(state.items),
  navigationStats: state.layout.navigationStats,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setCalendarExpanded,
      setTaskPanelExpanded,
      setTaskPanelOpen,
      updateCalendarDate,
      updateScrollToTime,
    },
    dispatch
  ),
})

CalendarPanel.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(CalendarPanel)

const calendarPanelVariants = {
  hide: {
    right: '-100%',
    transition: {
      duration: 0.6,
    },
  },
  showCollapsed: {
    right: '0%',
    transition: {
      duration: styles.transitions.calendarSlide,
    },
    width: `${calendarPanelWidth}px`,
  },
  showExpanded: {
    right: '0%',
    transition: {
      duration: styles.transitions.calendarSlide,
    },
    width: `calc(100% - ${taskPanelContainerWidth + calendarLateralPadding + expandHandleWidth + 8}px)`,
  },
  showExpandedFullScreen: {
    right: '0%',
    transition: {
      duration: styles.transitions.calendarSlide,
    },
    width: `calc(100% - ${expandHandleWidth + 20}px)`,
  },
}

const getHidingDuringSlideStyle = (state) => {
  switch (state) {
    case 'entering': {
      return css`
        opacity: 0;
        transition-duration: 0s;
      `
    }
    case 'entered': {
      return css`
        opacity: 1;
      `
    }
    case 'exiting': {
      return css`
        opacity: 0;
        transition-duration: 0s;
      `
    }
    case 'exited': {
      return css`
        opacity: 1;
      `
    }
    default:
      return css``
  }
}

const StyledCalendar = styled(Calendar)`
  overflow: hidden;
  width: 100%;
  background: ${styles.colors.veryLightGrey};
  border-radius: 8px;
  opacity: ${(props) => (props.transitionState === 'entered' ? 1 : 0)};
  transition: opacity ${styles.transitions.calendarSlide / 2}s ease-out;
`

const buttonWidth = calendarPanelWidth - calendarLateralPadding * 2

const ModeButtonLeftChevron = styled(Icon)`
  margin-top: 1px;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transform: translate(0, -50%);
  position: absolute;
  left: 9px;
  top: 50%;
`

const ModeButtonRightChevron = styled(Icon)`
  margin-top: 1px;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transform: translate(0, -50%);
  position: absolute;
  right: 9px;
  top: 50%;
`

const ModeButton = styled.div`
  display: flex;
  cursor: pointer;
  width: ${buttonWidth}px;
  text-transform: capitalize;
  height: 40px;
  border-radius: 10px;
  border: 1px solid ${(props) => (props.isSelected ? 'transparent' : styles.colors.greyBorderColor)};
  align-items: center;
  justify-content: center;
  font-weight: 500;
  position: relative;
  font-size: ${styles.fonts.fontSizeSmall};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out,
    opacity ${styles.transitions.calendarSlide / 2}s ease-out;
  :hover {
    background-color: ${(props) => props.backgroundColor}CC;
    color: ${(props) => props.color}CC;
    ${ModeButtonLeftChevron} {
      transform: translate(100%, -50%);
      opacity: 0.8;
    }
    ${ModeButtonRightChevron} {
      transform: translate(-100%, -50%);
      opacity: 0.8;
    }
  }
`

const Container = styled(motion.div)`
  background-color: white;
  border-radius: 8px;
  box-shadow: inset 1px 0px 0px #e9e8ee;
  display: flex;
  height: calc(100% - 16px);
  margin: 8px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${({ zIndex }) => zIndex};

  ${NavigationContainer} {
    ${(props) => getHidingDuringSlideStyle(props.slideTransitionState)};
  }

  ${ModeButton} {
    ${(props) => getHidingDuringSlideStyle(props.slideTransitionState)};
  }
`

Container.displayName = 'Container'

const CalendarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ expanded }) =>
    expanded
      ? `12px ${calendarLateralPadding * 2}px 8px ${calendarLateralPadding * 2}px`
      : `12px ${calendarLateralPadding}px 8px ${calendarLateralPadding}px`};
  position: relative;
`

CalendarContainer.displayName = 'CalendarContainer'
