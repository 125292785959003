import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { constants, styles, translations } from 'gipsy-misc'
import { Sprint } from 'gipsy-misc/types'
import { FixedTooltip, Icon, SprintSelect } from 'gipsy-ui'

import { getActiveSprintsMapByScheduledTimeAndGroupedByRecurrence } from 'store/items/selectors'

import { ButtonContainer, ButtonContent, ButtonPopup } from './commonUIComponents'

interface Props {
  onSelect: (sprint: Sprint) => void
  shrinked: boolean
}

export default function SprintsButton({ onSelect, shrinked }: Props) {
  const activeSprintsMap = useSelector((state) => getActiveSprintsMapByScheduledTimeAndGroupedByRecurrence(state.items))

  const [active, setActive] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  const toggleActive = (e: React.MouseEvent) => {
    if (popupRef.current?.contains?.(e.target as Node)) return

    setActive((prev) => !prev)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (!containerRef?.current?.contains(e.target as Node)) {
      setActive(false)
    }
  }

  const handleChange = (sprint: Sprint) => {
    setActive(false)
    onSelect(sprint)
  }

  return (
    <ButtonContainer
      active={active}
      activeColor={styles.colors.orangeColor}
      fillIcon
      onClick={toggleActive}
      ref={containerRef}>
      <ButtonContent>
        {shrinked ? (
          <FixedTooltip
            content={translations.line.addToSprint}
            horizontalOffset={-10}
            horizontalPosition='left'
            portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
            style={{
              padding: '8px',
            }}
            verticalOffset={-12}
            verticalPosition='bottom'>
            <Icon className='ButtonIcon ButtonIcon--shrinked' icon='Sprint' size={14} />
          </FixedTooltip>
        ) : (
          <>
            <Icon className='ButtonIcon' icon='Sprint' size={14} />
            {translations.line.addToSprint}
          </>
        )}
      </ButtonContent>
      {active && (
        <StyledButtonPopup onClickOutside={handleClickOutside} ref={popupRef}>
          <SprintSelect
            activeSprintsMap={activeSprintsMap}
            hideCreateButton
            onChange={handleChange}
            maxWidth={344}
            width='100%'
            value={null}
          />
        </StyledButtonPopup>
      )}
    </ButtonContainer>
  )
}

const StyledButtonPopup = styled(ButtonPopup)`
  .RecurringItemsDropdown__container {
    margin-bottom: 0;
  }
`

StyledButtonPopup.displayName = 'StyledButtonPopup'
