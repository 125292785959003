import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import parser from 'react-html-parser'

import { styles, translations } from 'gipsy-misc'
import { GoogleButton, MicrosoftButton, SimpleButton } from 'gipsy-ui'

import { LoginPopup } from 'features/addAccount'
import breakpoints from 'features/layout/breakpoints'
import { timeBlockingExpVariants as variants } from 'pages/login/container'
import { getSrc } from 'utils/image'

interface Props {
  goToGoogleAuth: () => void
  goToMicrosoftAuth: () => void
  variant: string
}

export default function Header({ goToGoogleAuth, goToMicrosoftAuth, variant = variants.TIME_BLOCKING }: Props) {
  const [loginPopupShown, setLoginPopupShown] = useState(false)

  const ctaContainerRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (e) => {
    if (ctaContainerRef.current && !ctaContainerRef.current.contains(e.target)) {
      setLoginPopupShown(false)
    }
  }

  return (
    <Container variant={variant}>
      <Content>
        <Logo alt='KosmoTime' src={getSrc('/images/gipsybot-with-title.svg')} variant={variant} />
        <Title className='Header__title' variant={variant}>
          {parser(translations.timeBlockingExp[variant].header.title)}
        </Title>
        <CTAContainer ref={ctaContainerRef} variant={variant}>
          <CTA
            text={translations.timeBlockingExp[variant].header.cta}
            onClick={() => setLoginPopupShown(!loginPopupShown)}
            variant={variant}
          />
          {loginPopupShown && (
            <LoginPopup onClickOutside={handleClickOutside}>
              <GoogleButton label={'Google'} onClick={goToGoogleAuth} />
              <MicrosoftButton label={'Microsoft'} onClick={goToMicrosoftAuth} />
            </LoginPopup>
          )}
        </CTAContainer>
      </Content>
    </Container>
  )
}

export type TimeBlockingVariantComponent = {
  variant: string
}

const Container = styled.header<TimeBlockingVariantComponent>`
  position: relative;
  height: 26%;
  min-height: 208px;
  width: 100%;

  :before {
    background-image: url('${getSrc('/images/time-blocking-exp/header.jpg')}');
    background-position-y: 15%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 100%;
    opacity: 0.2;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    min-height: 257px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 27%;
      min-height: 229px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        min-height: 229px;
      }
    `}
`

Container.displayName = 'Container'

const Content = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
`

Content.displayName = 'Content'

const Logo = styled.img<TimeBlockingVariantComponent>`
  height: 53px;
  margin: 21px auto 0;
  width: 132px;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    height: 61px;
    margin: 31px auto 0;
    width: 153px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 68px;
      margin: 16px auto 0;
      width: 171px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        height: 68px;
        margin: 16px auto 0;
        width: 171px;
      }
    `}
`

Logo.displayName = 'Logo'

const Title = styled.h1<TimeBlockingVariantComponent>`
  &.Header__title {
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
    margin: 0 auto;
    text-align: center;
    text-transform: unset;
    width: auto;

    strong {
      font-weight: 500;
    }
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    &.Header__title {
      font-size: 28px;
    }
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      &.Header__title {
        font-size: 22px;
      }

      @media (min-width: ${breakpoints.desktopLarge}px) {
        &.Header__title {
          font-size: 22px;
        }
      }
    `}
`

Title.displayName = 'Title'

const CTAContainer = styled.div<TimeBlockingVariantComponent>`
  margin: 25px auto 34px;
  position: relative;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    margin: 33px auto 47px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      margin: 31px auto 41px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        margin: 31px auto 41px;
      }
    `}
`

CTAContainer.displayName = 'CTAContainer'

const CTA = styled(SimpleButton)<TimeBlockingVariantComponent>`
  box-shadow: ${styles.shadows.taskMenuHover};
  font-size: 18px;
  height: 41px;
  line-height: 32px;
  width: 245px;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    font-size: 22px;
    height: 45px;
    width: 304px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      width: 170px;

      :hover {
        background-color: ${styles.colors.superlightVioletColor};
        border-color: ${styles.colors.primaryColor};
        color: ${styles.colors.primaryColor};
      }

      @media (min-width: ${breakpoints.desktopLarge}px) {
        width: 170px;
      }
    `}
`

CTA.displayName = 'CTA'
