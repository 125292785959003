import { produce } from 'immer'
import isEqual from 'lodash/isEqual'
import moment from 'moment'

import { models, utils } from 'gipsy-misc'

import { getFindItemByIdFn } from './selectors'

import types from './types'

const defaultState = {
  areItemsLoaded: false,
  sprints: {},
  tasks: {},
}

function addItem(state, item = {}) {
  const { sprints, tasks } = state
  const { id, type } = item

  if (!id || !type) return state

  if (type === models.item.type.TASK) {
    if (item.sprintInfo) {
      const sprint = sprints[item.sprintInfo.id]

      if (!sprint) return state

      sprint.tasks = sprints[sprint.id].tasks || []

      if (item.sprintInfo.rank) {
        sprint.tasks = sprint.tasks.filter((sprintTask) => sprintTask.id !== item.id)
        sprint.tasks.splice(item.sprintInfo.rank - 1, 0, item)
      } else {
        sprint.tasks.push(item)
      }

      delete tasks[id]
    } else {
      tasks[id] = item
    }
  }

  if (type === models.item.type.SPRINT) {
    sprints[id] = item
    item.tasks?.forEach?.((task) => {
      delete tasks[task.id]
    })
  }

  return state
}

function addItems(state, items = []) {
  const newState = items.reduce((currentState, item) => {
    const updatedState = addItem(currentState, item)
    return updatedState
  }, state)

  return newState
}

function removeItem(state, item = {}) {
  const { sprints, tasks } = state
  const { id, type } = item

  if (!id || !type) return state

  if (type === models.item.type.TASK) {
    if (item.sprintInfo) {
      const sprint = sprints[item.sprintInfo.id]

      if (!sprint) return state

      sprint.tasks = (sprints[sprint.id].tasks || []).filter((sprintTask) => sprintTask.id !== item.id)
    } else {
      delete tasks[id]
    }
  }

  if (type === models.item.type.SPRINT) {
    delete sprints[id]

    item.tasks?.forEach?.((task) => {
      const updatedTask = produce(task, (draft) => {
        draft.when.date = moment().format('YYYY-MM-DD')
        delete draft.sprintInfo
      })

      tasks[task.id] = updatedTask
    })
  }

  return state
}

function removeItems(state, items = []) {
  const newState = items.reduce((currentState, item) => {
    const updatedState = removeItem(currentState, item)
    return updatedState
  }, state)

  return newState
}

// for temporal items
function replaceItem(state, { item, replacement }) {
  const { sprints, tasks } = state
  const { id, type } = item
  const { id: replacementId, type: replacementType } = replacement

  if (!id || !type || !replacementId || !replacementType) return state

  if (type !== replacementType) {
    console.warn(`-- attempted to replace ${type} with ${replacementType}`)
    return state
  }

  if (type === models.item.type.TASK) {
    delete tasks[id]
    tasks[replacementId] = replacement
  }

  if (type === models.item.type.SPRINT) {
    delete sprints[id]
    sprints[replacementId] = replacement
  }

  return state
}

function replaceItems(state, { items, replacements }) {
  let newState = items.reduce((currentState, item) => {
    const updatedState = removeItem(currentState, item)
    return updatedState
  }, state)

  newState = replacements.reduce((currentState, replacement) => {
    const updatedState = addItem(currentState, replacement)
    return updatedState
  }, state)

  return newState
}

function setAllItems({ sprints, tasks }) {
  return {
    areItemsLoaded: true,
    sprints: (sprints || []).reduce((sprintList, sprint) => {
      sprintList[sprint.id] = sprint
      return sprintList
    }, {}),
    tasks: (tasks || []).reduce((taskList, task) => {
      taskList[task.id] = task
      return taskList
    }, {}),
  }
}

function updateItem(state, item = {}) {
  const { sprints, tasks } = state
  const findItemById = getFindItemByIdFn(state)
  const { id, type } = item

  if (!id || !type) return state

  if (type === models.item.type.TASK) {
    const oldTask = findItemById(id)

    if (!oldTask) return state

    let hasSprintInfoChanged = !isEqual(oldTask.sprintInfo, item.sprintInfo)

    // handle nulls and undefineds
    if (
      hasSprintInfoChanged &&
      (oldTask.sprintInfo === null || oldTask.sprintInfo === undefined) &&
      (item.sprintInfo === null || item.sprintInfo === undefined)
    ) {
      hasSprintInfoChanged = false
    }

    if (hasSprintInfoChanged) {
      const wasSprintInfoAdded = !!(item.sprintInfo?.id && !oldTask.sprintInfo?.id)
      const wasSprintInfoRemoved = !!(!item.sprintInfo?.id && oldTask.sprintInfo?.id)
      const sprint = wasSprintInfoAdded ? sprints[item.sprintInfo?.id] : sprints[oldTask.sprintInfo?.id]

      if (!sprint) return state

      sprint.tasks = sprint.tasks || []

      if (wasSprintInfoAdded) {
        if (item.sprintInfo.rank) {
          sprint.tasks = sprint.tasks.filter((sprintTask) => sprintTask.id !== item.id)
          sprint.tasks.splice(item.sprintInfo.rank - 1, 0, item)
        } else {
          sprint.tasks.push(item)
        }

        delete tasks[id]
      } else if (wasSprintInfoRemoved) {
        sprint.tasks = (sprint.tasks || []).filter((sprintTask) => sprintTask.id !== item.id)
        tasks[id] = item
      } else {
        const targetSprint = sprints[item.sprintInfo?.id]

        if (!targetSprint) return

        sprint.tasks = (sprint.tasks || []).filter((sprintTask) => sprintTask.id !== item.id)

        const now = moment()

        if (utils.sprint.shouldCompleteSprint(sprint, now)) {
          const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
          sprint.completionTime = completionTime
          sprint.estimatedTime = estimatedTime
        }

        if (item.sprintInfo.rank) {
          targetSprint.tasks = targetSprint.tasks.filter((sprintTask) => sprintTask.id !== item.id)
          targetSprint.tasks.splice(item.sprintInfo.rank - 1, 0, item)
        } else {
          targetSprint.tasks.push(item)
        }
      }
    } else {
      if (item.sprintInfo) {
        const sprint = sprints[item.sprintInfo.id]

        if (!sprint) return state

        sprint.tasks = sprint.tasks || []

        const taskIndex = sprint.tasks.findIndex((sprintTask) => sprintTask.id === item.id)

        if (taskIndex !== -1) {
          sprint.tasks.splice(taskIndex, 1, item)
        }
      } else {
        tasks[id] = item
      }
    }
  }

  if (type === models.item.type.SPRINT) {
    sprints[id] = item
  }

  return state
}

function updateItems(state, items = []) {
  const newState = items.reduce((currentState, item) => {
    const updatedState = updateItem(currentState, item)
    return updatedState
  }, state)

  return newState
}

function reducer(state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.ADD_ITEM: {
        return addItem(draft, action.payload)
      }

      case types.ADD_ITEMS: {
        return addItems(draft, action.payload)
      }

      case types.REMOVE_ITEM: {
        return removeItem(draft, action.payload)
      }

      case types.REMOVE_ITEMS: {
        return removeItems(draft, action.payload)
      }

      case types.REPLACE_ITEM: {
        return replaceItem(draft, action.payload)
      }

      case types.REPLACE_ITEMS: {
        return replaceItems(draft, action.payload)
      }

      case types.SET_ALL_ITEMS: {
        return setAllItems(action.payload)
      }

      case types.UPDATE_ITEM: {
        return updateItem(draft, action.payload)
      }

      case types.UPDATE_ITEMS: {
        return updateItems(draft, action.payload)
      }

      default: {
        return draft
      }
    }
  })
}

export default reducer
