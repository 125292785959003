import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { produce } from 'immer'
import clone from 'lodash/clone'
import differenceBy from 'lodash/differenceBy'
import get from 'lodash/get'
import moment from 'moment'

import { focussession as focussessionApi, sprint as sprintApi, task as taskApi } from 'gipsy-api'
import { constants, models, styles, translations, utils } from 'gipsy-misc'

import RealTime from 'features/realTime'
import { handleAPIError } from 'store/app/actions'
import {
  addCalendarEvent,
  removeCalendarEvent,
  setHighlightedEventId,
  updateCalendarDate,
  updateCalendarEvent,
} from 'store/calendar/actions'
import ConfirmPanel from 'features/popup/components/confirmPanel'
import RecurringItemPanel from 'features/popup/components/recurrentItemPanel'
import Loading from 'features/popup/components/loading'
import { closePopup, openPopup } from 'store/popup/actions'
import {
  createTaskAndFs,
  endSession,
  handleCompletedSession,
  updateFocusSessionTask,
  updateFocusSessionTitle,
} from 'store/session/actions'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { patchTaskRequest, toggleCompleteTaskRequest } from 'store/task/actions'
import {
  addItem,
  addItems,
  removeItem,
  removeItems,
  replaceItem,
  replaceItems,
  updateItem,
  updateItems,
} from 'store/items/actions'
import {
  getAllItems,
  getFindItemByIdFn,
  getSprints,
  getSprintsById,
  getSprintTasks,
  getTasksById,
} from 'store/items/selectors'

import { getAllInstancesOfRecSprint } from './utils/sprints'
import { getAllInstancesOfRecTask } from './utils/tasks'

const { InstanceOptions } = models.recurrency

export default function usePageActions() {
  const allItems = useSelector((state) => getAllItems(state.items))
  const calendarHighlightedEventId = useSelector((state) => state.calendar.highlightedEventId)
  const dispatch = useDispatch()
  const findItemById = useSelector((state) => getFindItemByIdFn(state.items))
  const session = useSelector((state) => state.session)
  const sprints = useSelector((state) => getSprints(state.items))
  const sprintsById = useSelector((state) => getSprintsById(state.items))
  const sprintTasks = useSelector((state) => getSprintTasks(state.items))
  const tasksById = useSelector((state) => getTasksById(state.items))

  const [isTaskCreationAlertShown, setIsTaskCreationAlertShown] = useState(false)

  const patchers = useRef({})
  const taskCreationAlertTimeout = useRef(null)

  const addItemToCalendar = useCallback(
    (item, color, isSprint) => {
      dispatch(addCalendarEvent({ color, isSprint, item }))
    },
    [dispatch]
  )

  const removeItemFromCalendar = useCallback(
    (id) => {
      dispatch(removeCalendarEvent({ itemId: id }))
    },
    [dispatch]
  )

  const updateCalendarItem = useCallback(
    (idToUpdate, item) => {
      dispatch(
        updateCalendarEvent({
          idToUpdate,
          item,
        })
      )
    },
    [dispatch]
  )

  const sendPatchRequest = useCallback(
    ({ extraParams, method, paramName, task, value }) => {
      if (!patchers.current[task.id]) {
        patchers.current[task.id] = new utils.TaskPatcher(constants.delayBeforePatch.default)
      }

      try {
        const { params, body } = utils.task.computePatchRequest(task, { paramName, value, method }, extraParams)
        return new Promise((resolve, reject) => {
          patchers.current[task.id].put(paramName, method, () => {
            return dispatch(patchTaskRequest(task.id, params, body))
              .then((args) => {
                resolve(args)
              })
              .catch((args) => {
                reject(args)
              })
          })
        })
      } catch (err) {
        console.error(err)
      }
    },
    [dispatch]
  )

  const archiveTask = useCallback(
    async ({ id }) => {
      const task = findItemById(id)

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const paramName = 'archived'
      const value = true

      dispatch(removeItem(task))

      await sendPatchRequest({ method: 'change', paramName, task, value })
    },
    [dispatch, findItemById, sendPatchRequest]
  )

  const completeTask = useCallback(
    async ({ id }, extraParams, toAddFocusSession) => {
      let oldTask = clone(findItemById(id))
      let completionFocusSession = toAddFocusSession
      const now = moment()

      if (!oldTask) {
        console.warn('-- task not found')
        return
      }

      if (oldTask.type !== models.item.type.TASK) return

      if (!completionFocusSession) {
        const completionTime = utils.task.getCompletionTime(oldTask)
        completionFocusSession = utils.focussession.createDefaultFocusSession({
          task: oldTask,
          endTime: completionTime,
        })
      }

      let completionTime

      if (extraParams?.inFocusSession) {
        completionTime = now
      } else {
        completionTime = utils.task.getCompletionTime(oldTask)
      }

      const completedTask = utils.task.updateTaskWhenComplete(oldTask, completionTime)

      if (completionFocusSession) {
        completedTask.focusSessions = (completedTask.focusSessions || []).concat([completionFocusSession])
        addItemToCalendar(
          { ...completionFocusSession, type: models.item.type.FOCUSSESSION },
          styles.colors.focusSessionFill
        )
      }

      if (oldTask && oldTask.sprintInfo?.id) {
        let sprint = clone(findItemById(oldTask.sprintInfo?.id))
        if (sprint) {
          sprint.tasks = sprint.tasks?.filter((completedTask) => completedTask.id !== id)
          if (utils.sprint.shouldCompleteSprint(sprint, now)) {
            const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
            sprint.completionTime = completionTime
            sprint.estimatedTime = estimatedTime
          }
          dispatch(updateItem(sprint))
        }

        dispatch(addItem(completedTask))
      } else {
        dispatch(updateItem(completedTask))
      }

      const out = await dispatch(toggleCompleteTaskRequest(id, 1, undefined, extraParams))
      const updatedTask = clone(completedTask)

      if (out?.createdFocusSession) {
        if (completionFocusSession) {
          removeItemFromCalendar(completionFocusSession.id)
          updatedTask.focusSessions = (updatedTask.focusSessions || []).filter(
            (fs) => fs.id !== completionFocusSession.id
          )
        }

        addItemToCalendar(
          { ...out.createdFocusSession, type: models.item.type.FOCUSSESSION },
          styles.colors.focusSessionFill
        )

        updatedTask.focusSessions = (updatedTask.focusSessions || []).concat([out.createdFocusSession])
        dispatch(updateItem(updatedTask))
      }
    },
    [addItemToCalendar, dispatch, findItemById, removeItemFromCalendar]
  )

  const completeTasks = useCallback(
    (taskIds) => {
      const toUpdateSprintsById = {}
      const toCompleteTasks = []

      taskIds.forEach((id) => {
        const oldTask = findItemById(id)
        if (!oldTask || oldTask.type !== models.item.type.TASK || oldTask.completed) return

        const completedTask = utils.task.updateTaskWhenComplete(oldTask, utils.task.getCompletionTime(oldTask))
        toCompleteTasks.push(completedTask)
        const sprintId = oldTask.sprintInfo?.id

        if (sprintId) {
          const sprint = toUpdateSprintsById[sprintId] || clone(findItemById(sprintId))

          if (sprint) {
            const now = moment()
            sprint.tasks = sprint.tasks?.filter((completedTask) => completedTask.id !== id)

            if (utils.sprint.shouldCompleteSprint(sprint, now)) {
              const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
              sprint.completionTime = completionTime
              sprint.estimatedTime = estimatedTime
            }

            toUpdateSprintsById[sprintId] = sprint
          }

          dispatch(addItem(completedTask))
        } else {
          dispatch(updateItem(completedTask))
        }
      })

      dispatch(updateItems(Object.values(toUpdateSprintsById)))

      toCompleteTasks.forEach((task, index) => {
        const updatedTask = clone(task)
        dispatch(toggleCompleteTaskRequest(task.id, 1, undefined, { hideMessage: index !== 0 })).then((out) => {
          if (out?.createdFocusSession) {
            addItemToCalendar(
              { ...out.createdFocusSession, type: models.item.type.FOCUSSESSION },
              styles.colors.focusSessionFill
            )

            updatedTask.focusSessions = (updatedTask.focusSessions || []).concat([out.createdFocusSession])
            dispatch(updateItem(updatedTask))
          }
        })
      })
    },
    [addItemToCalendar, dispatch, findItemById]
  )

  const completeTaskFromFS = useCallback(
    async ({ id }, completionFocusSession) => {
      completeTask({ id }, { inFocusSession: true }, completionFocusSession)
      dispatch(endSession(completionFocusSession))
    },
    [completeTask, dispatch]
  )

  const showTaskCreationAlert = useCallback(() => {
    if (taskCreationAlertTimeout.current) {
      clearTimeout(taskCreationAlertTimeout.current)
      setIsTaskCreationAlertShown(false)
    }
    setIsTaskCreationAlertShown(() => {
      taskCreationAlertTimeout.current = setTimeout(() => setIsTaskCreationAlertShown(false), 1800)
      return true
    })
  }, [])

  const uncompleteTask = useCallback(
    async (id) => {
      let task = clone(findItemById(id))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const updatedTask = produce(task, (draft) => {
        draft.completionTime = ''
        draft.completed = 0
      })

      dispatch(updateItem(updatedTask))

      await dispatch(toggleCompleteTaskRequest(id, 0))
    },
    [dispatch, findItemById]
  )

  const addRecurrenceToSingleTask = useCallback(
    (task) => {
      const recurringTask = utils.recurrency.tasks.computeRecurringTask(
        session.id,
        task,
        task.recurrencyInformation.recurrencyDetails
      )
      const tasks = utils.recurrency.tasks.scheduleNextTasksForDay(task.when.date, recurringTask)

      if (!tasks) return { recurringTask }

      dispatch(addItems(tasks))
      return { recurringTask, tasks }
    },
    [dispatch, session.id]
  )

  const createRecurrentTask = useCallback(
    async ({ context, dontShowCreationAlert, task }) => {
      const result = addRecurrenceToSingleTask(task)

      if (!result) return

      const { recurringTask, tasks } = result
      const [firstInstance] = tasks

      if (context?.componentSource === 'inlineAddTask' && !dontShowCreationAlert) {
        showTaskCreationAlert()
      }

      try {
        task.creationTime = recurringTask.creationTime
        const { id, ...dbTask } = task
        const response = await taskApi.create(dbTask, context)

        if (!response.instances) return response

        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        const [firstResponseInstance] = response.instances

        if (response.instances.length !== tasks.length || firstResponseInstance.id !== firstInstance.id) {
          dispatch(replaceItems(tasks, response.instances))
        }

        return response
      } catch (err) {
        dispatch(handleAPIError(err, { task }))
        return { error: get(err, 'data.code') }
      }
    },
    [addRecurrenceToSingleTask, dispatch, showTaskCreationAlert]
  )

  const createSingleInstanceTask = useCallback(
    async ({ context, dontShowCreationAlert, task }) => {
      let sprint

      if (task.sprintInfo && !context?.fromOnboarding) {
        sprint = clone(findItemById(task.sprintInfo?.id))

        if (!sprint) {
          console.warn('-- sprint not found')
        }
      }

      if (!task.id) {
        task = utils.ids.addIdToItem(task, models.item.type.TASK, session.id)
      }

      if (sprint) {
        sprint.tasks = (sprint.tasks || []).concat(task)
        dispatch(updateItem(sprint))
      } else {
        dispatch(addItem(task))
      }

      if (context?.componentSource === 'inlineAddTask' && !dontShowCreationAlert) {
        showTaskCreationAlert()
      }

      try {
        const { id, ...dbTask } = task
        const response = await taskApi.create(dbTask, context)

        if (!response.task) return

        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        const createdTask = response.task

        if (createdTask.id !== task.id) {
          if (sprint) {
            const idx = (sprint.tasks || []).findIndex((sprintTask) => sprintTask.id === task.id)
            if (idx > -1) {
              sprint = produce(sprint, (draft) => {
                draft.tasks[idx] = createdTask
              })
              dispatch(updateItem(sprint))
            }
          } else {
            dispatch(replaceItem(task, createdTask))
          }
        }

        // we need to repair the ranks
        utils.resolve.awaitOnce(`taskApi.repairActive`, taskApi.repairActive, 5000)

        return response
      } catch (err) {
        if (!err.cancel) {
          dispatch(handleAPIError(err, { task }))
        }
        return { error: get(err, 'data.code') }
      }
    },
    [dispatch, findItemById, session.id, showTaskCreationAlert]
  )

  const createInlineTask = useCallback(
    async (taskData) => {
      const { task } = taskData
      let result

      if (task.recurrencyInformation?.recurrencyDetails) {
        result = await createRecurrentTask(taskData)
      } else {
        result = await createSingleInstanceTask(taskData)
      }

      return result
    },
    [createRecurrentTask, createSingleInstanceTask]
  )

  const addRecurrenceToSingleSprint = useCallback(
    (sprint) => {
      const recurringSprint = utils.recurrency.sprints.computeRecurringSprint(
        session.id,
        sprint,
        sprint.recurrencyInformation.recurrencyDetails
      )
      const sprints = utils.recurrency.sprints.scheduleNextSprintsForDay(sprint.when.date, recurringSprint)

      if (!sprints) return { recurringSprint }

      const [firstInstance] = sprints

      if (firstInstance && sprint.tasks) {
        let firstInstanceTasks = utils.sprint.remapSprintTasksWithId(sprint.tasks, firstInstance.id)
        firstInstanceTasks = firstInstanceTasks.map((task) => {
          task.pin = undefined
          return task
        })

        firstInstance.tasks = firstInstanceTasks
      }

      dispatch(addItems(sprints))
      return { recurringSprint, sprints }
    },
    [dispatch, session.id]
  )

  const createRecurrentSprint = useCallback(
    async (toCreateSprint) => {
      const result = addRecurrenceToSingleSprint(toCreateSprint)

      if (!result) return

      const { recurringSprint, sprints } = result
      const [firstInstance] = sprints

      try {
        toCreateSprint.creationTime = recurringSprint.creationTime
        const out = await sprintApi.create(toCreateSprint)

        if (!out.instances) return out

        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        const [firstResponseInstance] = out.instances

        if (out.instances.length !== sprints.length || firstResponseInstance.id !== firstInstance.id) {
          dispatch(replaceItems(sprints, out.instances))
        }

        return out
      } catch (err) {
        dispatch(handleAPIError(err, { sprint: toCreateSprint }))
        return { error: get(err, 'data.code') }
      }
    },
    [addRecurrenceToSingleSprint, dispatch]
  )

  const createSingleInstanceSprint = useCallback(
    async (toCreateSprint) => {
      const sprint = utils.ids.addIdToItem(toCreateSprint, models.item.type.SPRINT, session.id)
      sprint.tasks = utils.sprint.remapSprintTasksWithId(sprint.tasks || [], sprint.id)

      if (sprint.tasks) {
        sprint.tasks = sprint.tasks.map((task) => {
          task.pin = undefined
          return task
        })
      }

      dispatch(addItem(sprint))

      try {
        const out = await sprintApi.create(sprint)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
        let result = sprint

        if (out && out.sprint && out.sprint.id !== sprint.id) {
          const newSprint = out.sprint
          newSprint.tasks = utils.sprint.remapSprintTasksWithId(sprint.tasks, newSprint.id)
          dispatch(replaceItem(sprint, newSprint))
          result = newSprint
        }

        return result
      } catch (err) {
        dispatch(handleAPIError(err, { sprint }))
        return { error: get(err, 'data.code') }
      }
    },
    [dispatch, session.id]
  )

  const createSprint = useCallback(
    async (toCreateSprint) => {
      let result

      if (toCreateSprint.recurrencyInformation?.recurrencyDetails) {
        result = await createRecurrentSprint(toCreateSprint)
      } else {
        result = await createSingleInstanceSprint(toCreateSprint)
      }

      return result
    },
    [createRecurrentSprint, createSingleInstanceSprint]
  )

  const deleteTask = useCallback(
    async (taskId, recurrenceOption) => {
      const task = findItemById(taskId)

      if (!task) {
        console.warn('-- task not found')
        return
      }

      if (!recurrenceOption || recurrenceOption === InstanceOptions.Single) {
        dispatch(removeItem(task))
      } else {
        const [recurrenceId, taskDateStr] = taskId.split('_')
        const tasksToRemove = []
        const tasksToUpdate = []

        if (recurrenceOption === InstanceOptions.Next) {
          const taskDate = moment(taskDateStr)

          tasksById.forEach((currentTaskId) => {
            if (!currentTaskId.includes(recurrenceId)) return

            const [, currentSprintDateStr] = currentTaskId.split('_')
            const currentSprintDate = moment(currentSprintDateStr)

            let currentTask = findItemById(currentTaskId)
            if (currentTask && currentTask.recurrencyInformation) {
              if (currentSprintDate.isSameOrAfter(taskDate)) {
                if (currentTask) {
                  tasksToRemove.push(currentTask)
                }
              } else {
                currentTask = produce(currentTask, (draft) => {
                  draft.recurrencyInformation = undefined
                })
                tasksToUpdate.push(currentTask)
              }
            }
          })
        } else {
          tasksById.forEach((currentTaskId) => {
            if (!currentTaskId.includes(recurrenceId)) return

            const currentTask = findItemById(currentTaskId)

            if (currentTask && currentTask.recurrencyInformation) {
              // we need to make sure the sprint is recurring. The id isn't enough
              tasksToRemove.push(currentTask)
            }
          })
        }

        dispatch(replaceItems(tasksToRemove, tasksToUpdate))
      }

      try {
        await taskApi.del(task.id, recurrenceOption)
        RealTime.publishMessage(task.id, [models.realtime.topics.itemDelete])
      } catch (err) {
        dispatch(handleAPIError(err, { task }))
      }
    },
    [dispatch, findItemById, tasksById]
  )

  const deleteItems = useCallback(
    (itemIds) => {
      const sprintsIdsToRemove = []
      const sprintTasksToRemoveMap = {}
      const tasksToRemoveFromState = []
      const tasksToRemove = []

      itemIds.forEach((id) => {
        const item = findItemById(id)

        if (!item) return

        if (item.type === models.item.type.TASK) {
          tasksToRemoveFromState.push(item)

          if (!item.sprintInfo) {
            tasksToRemove.push(item)
            return
          }

          const sprintTasks = sprintTasksToRemoveMap[item.sprintInfo.id] || []
          sprintTasks.push(item.id)
          sprintTasksToRemoveMap[item.sprintInfo.id] = sprintTasks
        } else if (item.type === models.item.type.SPRINT) {
          sprintsIdsToRemove.push(id)
        }
      })

      const updatedItems = dispatch(removeItems(tasksToRemoveFromState))
      const sprintsToRemove = []
      sprintsIdsToRemove.forEach((sprintId) => {
        if (updatedItems.sprints[sprintId]) {
          sprintsToRemove.push(updatedItems.sprints[sprintId])
        }
      })

      dispatch(removeItems(sprintsToRemove))

      tasksToRemove.forEach((task) => {
        taskApi.del(task.id, InstanceOptions.Single).catch((err) => {
          dispatch(handleAPIError(err, { task }))
        })
      })

      sprintsToRemove.forEach((sprint) => {
        const tasksToDeleteIds = sprintTasksToRemoveMap[sprint.id] || []
        delete sprintTasksToRemoveMap[sprint.id]

        sprintApi.del(sprint.id, InstanceOptions.Single, tasksToDeleteIds).catch((err) => {
          dispatch(handleAPIError(err, { sprint }))
        })
      })

      const remainingSprintTasksToRemove = Object.keys(sprintTasksToRemoveMap)
      if (remainingSprintTasksToRemove.length !== 0) {
        remainingSprintTasksToRemove.forEach((sprintId) => {
          const sprintTasks = sprintTasksToRemoveMap[sprintId]

          sprintTasks.forEach((taskId) => {
            taskApi.del(taskId, InstanceOptions.Single).catch((err) => {
              dispatch(handleAPIError(err, { taskId, sprintId }))
            })
          })
        })
      }
    },
    [dispatch, findItemById]
  )

  const deleteFocusSession = useCallback(
    async (focusSession) => {
      const task = findItemById(focusSession.taskId)
      removeItemFromCalendar(focusSession.id)

      if (!task) {
        console.warn('-- task not found')
      } else {
        const updatedTask = clone(task)
        updatedTask.focusSessions = updatedTask.focusSessions.filter((fs) => fs.id !== focusSession.id)
        dispatch(updateItem(updatedTask))

        if (task && session.focusSession?.taskId === focusSession.taskId) {
          dispatch(updateFocusSessionTask(updatedTask))
        }
      }

      try {
        await focussessionApi.del(focusSession.id)
      } catch (err) {
        dispatch(handleAPIError(err, { focusSession }))
      }
    },
    [dispatch, findItemById, removeItemFromCalendar, session.focusSession?.taskId]
  )

  const deleteSprint = useCallback(
    async (sprintId, recurrenceOption) => {
      let sprint = findItemById(sprintId)

      if (!sprint) {
        console.warn('-- sprint not found')
        return
      }

      if (!recurrenceOption || recurrenceOption === InstanceOptions.Single) {
        dispatch(removeItem(sprint))
      } else {
        const [recurrenceId, sprintDateStr] = sprintId.split('_')
        const sprintsToRemove = []
        const sprintsToUpdate = []

        if (recurrenceOption === InstanceOptions.Next) {
          const sprintDate = moment(sprintDateStr)

          sprintsById.forEach((currentSprintId) => {
            if (!currentSprintId.includes(recurrenceId)) return

            const [, currentSprintDateStr] = currentSprintId.split('_')
            const currentSprintDate = moment(currentSprintDateStr)

            let currentSprint = findItemById(currentSprintId)
            if (currentSprint && currentSprint.recurrencyInformation) {
              if (currentSprintDate.isSameOrAfter(sprintDate)) {
                if (currentSprint) {
                  sprintsToRemove.push(currentSprint)
                }
              } else {
                currentSprint = produce(currentSprint, (draft) => {
                  draft.recurrencyInformation = undefined
                })
                sprintsToUpdate.push(currentSprint)
              }
            }
          })
        } else {
          sprintsById.forEach((currentSprintId) => {
            if (!currentSprintId.includes(recurrenceId)) return

            const currentSprint = findItemById(currentSprintId)

            if (currentSprint && currentSprint.recurrencyInformation) {
              // we need to make sure the sprint is recurring. The id isn't enough
              sprintsToRemove.push(currentSprint)
            }
          })
        }

        dispatch(replaceItems(sprintsToRemove, sprintsToUpdate))
      }

      try {
        await sprintApi.del(sprintId, recurrenceOption)
        RealTime.publishMessage(sprintId, [models.realtime.topics.itemDelete])
      } catch (err) {
        dispatch(handleAPIError(err, { sprintId }))
      }
    },
    [dispatch, findItemById, sprintsById]
  )

  const _closePopup = useCallback(() => {
    dispatch(closePopup())
  }, [dispatch])

  const openLoadingPopup = useCallback(() => {
    dispatch(
      openPopup({
        centeredTitle: true,
        component: <Loading text={'Stay still! We are generating your instances'} />,
        hideLogo: true,
      })
    )
  }, [dispatch])

  const recurringItemPopup = useCallback(
    (
      { forSprint, hideAllOption, hideSingleOption, hideTitle, noOptionsMessageText, noOptionsValue, title } = {},
      { onCancelled, onConfirmed } = {}
    ) => {
      const onConfirm = (optionChecked) => {
        _closePopup()
        onConfirmed?.(optionChecked)
      }

      const onCancel = () => {
        onCancelled?.()
        _closePopup()
      }

      dispatch(
        openPopup({
          centeredTitle: true,
          component: (
            <RecurringItemPanel
              forSprint={forSprint}
              hideAllOption={hideAllOption}
              hideSingleOption={hideSingleOption}
              noOptionsMessageText={noOptionsMessageText}
              noOptionsValue={noOptionsValue}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          ),
          hideTitle,
          logo: 'happy',
          title,
          onClose: onCancel,
        })
      )
    },
    [dispatch, _closePopup]
  )

  const updateLocalSprintFromEdit = useCallback(
    (newSprint, oldSprint) => {
      const newTasksInSprint = differenceBy(newSprint.tasks || [], oldSprint.tasks || [], 'id')

      if (newTasksInSprint.length) {
        // need to do this because tasks in newTasksInSprint don't have a when date
        const tasksInStateToBeRemoved = newTasksInSprint.reduce((taskList, taskToRemove) => {
          const task = findItemById(taskToRemove.id)

          if (task) {
            taskList.push(task)
          }

          return taskList
        }, [])

        dispatch(removeItems(tasksInStateToBeRemoved))
      }

      const removedTasksFromSprint = differenceBy(oldSprint.tasks || [], newSprint.tasks || [], 'id')

      if (removedTasksFromSprint.length) {
        const tasksToBeAdded = removedTasksFromSprint.map((taskToRemove) =>
          produce(taskToRemove, (draft) => {
            draft.when.date = moment(newSprint.pin.time).format('YYYY-MM-DD')
            delete draft.sprintInfo
          })
        )

        dispatch(addItems(tasksToBeAdded))
      }

      dispatch(updateItem(newSprint))
    },
    [dispatch, findItemById]
  )

  const updateSprintWithTasksAndRecurrencyInfoOfSeries = useCallback(
    (instances, newSprint) => {
      const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)

      if (!splittedInstances) return null

      const { before, instance, after } = splittedInstances

      const updatedBeforeInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })
      })

      dispatch(replaceItems(before, updatedBeforeInstances))

      const recurringSprint = utils.recurrency.sprints.computeRecurringSprint(
        session.id,
        newSprint,
        newSprint.recurrencyInformation.recurrencyDetails
      )

      const sprints = utils.recurrency.sprints.scheduleNextSprintsForDay(newSprint.when.date, recurringSprint)

      if (!sprints) return { createdRecurringSprint: recurringSprint }

      const { filledInstances: updatedInstances, toUpdateTasks } = utils.recurrency.sprints.fillNewInstancesWithTasks(
        sprints,
        after,
        newSprint.tasks
      )
      const oldRecItems = [instance, ...after]
      dispatch(replaceItems(oldRecItems, updatedInstances))
      dispatch(updateItems(toUpdateTasks))

      return { createdRecurringSprint: recurringSprint, createdInstances: updatedInstances }
    },
    [dispatch, session.id]
  )

  const updateSprintWithTasksWhenRecurrencyDetailsAreSame = useCallback(
    (instances, newSprint, oldSprint, recurrenceOption) => {
      const hasWhenDateChanged = newSprint.when.date !== oldSprint.when.date

      switch (true) {
        case recurrenceOption === InstanceOptions.Single: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
          return
        }
        case hasWhenDateChanged: {
          return updateSprintWithTasksAndRecurrencyInfoOfSeries(instances, newSprint)
        }

        case recurrenceOption === InstanceOptions.All || recurrenceOption === InstanceOptions.Next: {
          if (utils.sprint.areSprintAttributesNotEqual(newSprint, oldSprint)) {
            let instancesToUpdate = instances

            if (recurrenceOption === InstanceOptions.Next) {
              const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)
              instancesToUpdate = splittedInstances.after
            }

            const newPinMoment = moment(newSprint.pin.time)

            const updatedInstances = produce(instancesToUpdate, (draft) => {
              draft.forEach((instance) => {
                if (instance.id === newSprint.id) return

                instance.title = newSprint.title
                instance.estimatedTime = newSprint.estimatedTime
                instance.pin.time = moment(instance.pin.time).set({
                  hour: newPinMoment.hour(),
                  minute: newPinMoment.minute(),
                  second: newPinMoment.second(),
                })
              })
            })

            dispatch(updateItems(updatedInstances))
            updateLocalSprintFromEdit(newSprint, oldSprint)
          } else {
            updateLocalSprintFromEdit(newSprint, oldSprint)
          }
          break
        }

        // single option
        default: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
        }
      }
    },
    [dispatch, updateLocalSprintFromEdit, updateSprintWithTasksAndRecurrencyInfoOfSeries]
  )

  const updateSprintAndRemoveRecurrencyOfSeries = useCallback(
    (instances, newSprint, oldSprint) => {
      const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)

      if (!splittedInstances) return null

      const { before, after } = splittedInstances

      const updatedInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })

        draft.push(newSprint)
      })

      dispatch(updateItems(updatedInstances))
      dispatch(removeItems(after))
      updateLocalSprintFromEdit(newSprint, oldSprint)
    },
    [dispatch, updateLocalSprintFromEdit]
  )

  const updateRecurringSprintInstanceWithTasks = useCallback(
    (newSprint, oldSprint, recurrenceOption) => {
      const instances = getAllInstancesOfRecSprint(
        sprintsById,
        findItemById,
        utils.sprint.getRecSprintId(oldSprint),
        true
      )

      switch (true) {
        case !newSprint.recurrencyInformation: {
          return updateSprintAndRemoveRecurrencyOfSeries(instances, newSprint, oldSprint)
        }

        case utils.recurrency.options.isRecurrenceEqual(
          oldSprint.recurrencyInformation?.recurrencyDetails,
          newSprint.recurrencyInformation?.recurrencyDetails
        ): {
          return updateSprintWithTasksWhenRecurrencyDetailsAreSame(instances, newSprint, oldSprint, recurrenceOption)
        }

        default: {
          return updateSprintWithTasksAndRecurrencyInfoOfSeries(instances, newSprint)
        }
      }
    },
    [
      findItemById,
      sprintsById,
      updateSprintAndRemoveRecurrencyOfSeries,
      updateSprintWithTasksAndRecurrencyInfoOfSeries,
      updateSprintWithTasksWhenRecurrencyDetailsAreSame,
    ]
  )

  const handleSprintSave = useCallback(
    async (newSprint, oldSprint, recurrenceOption, { onRecurrenceComputed } = {}) => {
      newSprint.tasks?.forEach?.(function (task, index) {
        if (get(task, 'pin.time')) {
          task.pin = undefined
          this[index] = task
        }
      }, newSprint.tasks)

      let createdInstances
      switch (true) {
        case !!oldSprint.recurrencyInformation: {
          const result = updateRecurringSprintInstanceWithTasks(newSprint, oldSprint, recurrenceOption)
          if (!!result) {
            // to make sure the new id generated is the same as the one the front end generated
            newSprint = produce(newSprint, (draft) => {
              draft.recurrencyInformation.recurrencyDetails = result?.createdRecurringSprint.recurrencyDetails
              draft.creationTime = result?.createdRecurringSprint.creationTime
            })
            createdInstances = result?.createdInstances
          }

          onRecurrenceComputed?.(result)
          break
        }

        case !oldSprint.recurrencyInformation && !!newSprint.recurrencyInformation: {
          dispatch(removeItem(oldSprint))
          const result = addRecurrenceToSingleSprint(newSprint)
          const { recurringSprint: createdRecurringSprint, sprints } = result
          // to make sure the new id generated is the same as the one the front end generated
          newSprint.creationTime = createdRecurringSprint.creationTime
          createdInstances = sprints
          break
        }

        default: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
          break
        }
      }

      try {
        const sprintWithTaskIds = produce(newSprint, (draft) => {
          if (draft.tasks) {
            draft.tasksId = draft.tasks.map((t) => t.id)
          }
        })
        const out = await sprintApi.editWithTasks(sprintWithTaskIds, { recurrenceOption })
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        if (!out?.instances) return

        const [firstResponseInstance] = out.instances

        if (out.instances.length !== createdInstances?.length || firstResponseInstance.id !== createdInstances[0].id) {
          dispatch(replaceItems(createdInstances, out.instances))
        }
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    },
    [addRecurrenceToSingleSprint, dispatch, updateLocalSprintFromEdit, updateRecurringSprintInstanceWithTasks]
  )

  const saveSprint = useCallback(
    (updatedSprint, { onCancel, onRecurrenceComputed } = {}) => {
      const newSprint = clone(updatedSprint)
      const oldSprint = findItemById(updatedSprint.id)

      if (!oldSprint) {
        console.warn('-- sprint not found')
        return
      }

      const isOldSprintRecurrent = utils.sprint.isRecurrent(oldSprint)
      const isNewSprintRecurrent = utils.sprint.isRecurrent(newSprint)
      const sprintAttributesChanged = utils.sprint.areSprintAttributesNotEqual(newSprint, oldSprint)
      const hasRecurrenceChanged = !utils.recurrency.options.isRecurrenceEqual(
        oldSprint.recurrencyInformation?.recurrencyDetails,
        newSprint.recurrencyInformation?.recurrencyDetails
      )

      if (!isOldSprintRecurrent && !isNewSprintRecurrent && !sprintAttributesChanged) return

      if (isOldSprintRecurrent && isNewSprintRecurrent) {
        if (!hasRecurrenceChanged && !sprintAttributesChanged) return

        const hasWhenDateChanged = newSprint.when.date !== oldSprint.when.date
        const hideAllOption = hasWhenDateChanged || hasRecurrenceChanged
        const hideTitle = hideAllOption && hasRecurrenceChanged
        recurringItemPopup(
          {
            forSprint: true,
            hideAllOption,
            hideSingleOption: hasRecurrenceChanged,
            hideTitle,
            title: translations.sprint.recurrencyPanel.edit.prompt,
          },
          {
            onConfirmed: (recurrenceOption) =>
              handleSprintSave(newSprint, oldSprint, recurrenceOption, { onRecurrenceComputed }),
            onCancelled: onCancel,
          }
        )
      } else {
        handleSprintSave(newSprint, oldSprint)
      }
    },
    [findItemById, handleSprintSave, recurringItemPopup]
  )

  const editTasks = useCallback(
    (taskIds, editFn = (t) => t) => {
      const tasksToUpdate = []
      const previousTasks = []

      taskIds.forEach((id) => {
        const item = findItemById(id)

        if (!item || item.type !== models.item.type.TASK) return

        previousTasks.push(item)
        tasksToUpdate.push(
          produce(item, (draft) => {
            return editFn(draft)
          })
        )
      })

      dispatch(updateItems(tasksToUpdate))

      return {
        saveToBackend: () => {
          tasksToUpdate.forEach((task) => {
            taskApi.putFullTask(task, InstanceOptions.Single)
          })
        },
        undoAction: () => {
          dispatch(updateItems(previousTasks))
        },
      }
    },
    [dispatch, findItemById]
  )

  const updateFocusSession = useCallback(
    (updatedFocusSession) => {
      updateCalendarItem(updatedFocusSession.id, updatedFocusSession)

      const indexInFocusedLine =
        updatedFocusSession.taskId === session?.focusSession?.taskId
          ? session?.focusSession?.task?.focusSessions?.findIndex?.((fs) => fs.id === updatedFocusSession.id)
          : undefined

      if (indexInFocusedLine !== undefined && indexInFocusedLine !== -1) {
        const fsTask = { ...session.focusSession.task }
        const fsTaskSessions = [...fsTask.focusSessions]
        fsTaskSessions[indexInFocusedLine] = updatedFocusSession
        fsTask.focusSessions = fsTaskSessions
        dispatch(updateFocusSessionTask(fsTask))
      }

      const task = clone(findItemById(updatedFocusSession.taskId))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const idx = task.focusSessions.findIndex((fs) => fs.id === updatedFocusSession.id)

      if (idx < 0) {
        console.warn('-- focus session not found')
        return
      }

      task.focusSessions = [...task.focusSessions]
      task.focusSessions[idx] = updatedFocusSession
      dispatch(updateItem(task))

      // TODO: backend update here, decouple from FS's popup
    },
    [dispatch, findItemById, session.focusSession?.task, session.focusSession?.taskId, updateCalendarItem]
  )

  const rescheduleTasksToNextActiveInstance = useCallback(
    (recSprintId, pastSprintId, tasks) => {
      let activeInstances = getAllInstancesOfRecSprint(sprintsById, findItemById, recSprintId, true)
      activeInstances = utils.list.sortListByScheduledTime(activeInstances)
      if (activeInstances.length > 0) {
        for (let instance of activeInstances) {
          if (instance.id !== pastSprintId) {
            const nextScheduledInstance = produce(instance, (draft) => {
              draft.tasks = tasks
            })
            dispatch(updateItem(nextScheduledInstance))
            break
          }
        }
      }
    },
    [dispatch, findItemById, sprintsById]
  )

  const endSprint = useCallback(
    async (sprintId) => {
      const sprint = findItemById(sprintId)

      if (!sprint) {
        console.warn('-- sprint not found')
        return
      }
      const tasks = sprint.tasks || []

      const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
      const updatedEndedSprint = produce(sprint, (draft) => {
        draft.completionTime = completionTime
        draft.estimatedTime = estimatedTime
        draft.tasks = undefined
      })

      dispatch(updateItem(updatedEndedSprint))

      if (tasks?.length > 0) {
        if (utils.sprint.isRecurrent(sprint)) {
          rescheduleTasksToNextActiveInstance(utils.sprint.getRecSprintId(sprint), sprint.id, tasks)
        } else {
          const tasksToBeAdded = tasks.map((taskToUpdate) =>
            produce(taskToUpdate, (draft) => {
              draft.when.date = moment(sprint.pin.time).format('YYYY-MM-DD')
              delete draft.sprintInfo
            })
          )
          dispatch(addItems(tasksToBeAdded))
        }
      }

      try {
        await sprintApi.end(sprint.id)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err, { sprintId: sprint?.id }))
      }
    },
    [dispatch, findItemById, rescheduleTasksToNextActiveInstance]
  )

  const getFocusedTaskId = useCallback(() => {
    return !session.hideFocusedTask && utils.session.getFSTaskIdFromSession(session)
  }, [session])

  const updateLocalTaskFromEdit = useCallback(
    async (newTask, oldTask) => {
      const updatedItems = dispatch(updateItem(newTask))
      const oldSprint = updatedItems.sprints[oldTask?.sprintInfo?.id]

      if (oldSprint) {
        const now = moment()

        if (utils.sprint.shouldCompleteSprint(oldSprint, now)) {
          const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(oldSprint)

          const updatedOldSprint = produce(oldSprint, (draft) => {
            draft.completionTime = completionTime
            draft.estimatedTime = estimatedTime
          })

          dispatch(updateItem(updatedOldSprint))
        }
      }
    },
    [dispatch]
  )

  const updateTaskAndRemoveRecurrencyOfSeries = useCallback(
    (instances, newTask, oldTask) => {
      const splittedInstances = utils.recurrency.tasks.splitInstances(instances, newTask.id)

      if (!splittedInstances) return null

      const { before, after } = splittedInstances

      const updatedInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })

        draft.push(newTask)
      })

      dispatch(updateItems(updatedInstances))
      dispatch(removeItems(after))
      updateLocalTaskFromEdit(newTask, oldTask)
    },
    [dispatch, updateLocalTaskFromEdit]
  )

  const updateTaskAndRecurrencyInfoOfSeries = useCallback(
    (instances, newTask) => {
      const splittedInstances = utils.recurrency.tasks.splitInstances(instances, newTask.id)

      if (!splittedInstances) return null

      const { before, instance, after } = splittedInstances

      const updatedBeforeInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })
      })

      dispatch(replaceItems(before, updatedBeforeInstances))

      const recurringTask = utils.recurrency.tasks.computeRecurringTask(
        session.id,
        newTask,
        newTask.recurrencyInformation.recurrencyDetails
      )

      const tasks = utils.recurrency.tasks.scheduleNextTasksForDay(newTask.when.date, recurringTask)

      if (!tasks) return { createdRecurringTask: recurringTask }

      const oldRecItems = [instance, ...after]
      dispatch(replaceItems(oldRecItems, tasks))

      return { createdRecurringTask: recurringTask, createdInstances: tasks }
    },
    [dispatch, session.id]
  )

  const updateTaskWhenRecurrencyDetailsAreSame = useCallback(
    (instances, newTask, oldTask, recurrenceOption) => {
      let hasWhenDateChanged = newTask.when.date !== oldTask.when.date

      if (newTask.pin?.time) {
        hasWhenDateChanged =
          moment(newTask.pin.time).format('YYYY-MM-DD') !== moment(oldTask.when.date).format('YYYY-MM-DD')
      }

      switch (true) {
        case recurrenceOption === InstanceOptions.Single: {
          updateLocalTaskFromEdit(newTask, oldTask)
          return
        }

        case hasWhenDateChanged: {
          return updateTaskAndRecurrencyInfoOfSeries(instances, newTask)
        }

        case recurrenceOption === InstanceOptions.All || recurrenceOption === InstanceOptions.Next: {
          if (utils.task.areTaskAttributesNotEqual(newTask, oldTask)) {
            let instancesToUpdate = instances

            if (recurrenceOption === InstanceOptions.Next) {
              const splittedInstances = utils.recurrency.tasks.splitInstances(instances, newTask.id)
              instancesToUpdate = splittedInstances.after
            }

            let newPinMoment
            if (newTask.pin?.time) {
              newPinMoment = moment(newTask.pin.time)
            }

            const updatedInstances = produce(instancesToUpdate, (draft) => {
              draft.forEach((instance) => {
                if (instance.id === newTask.id) return

                instance.title = newTask.title
                instance.estimatedTime = newTask.estimatedTime
                instance.projects = newTask.projects
                instance.projectsId = newTask.projectsId
                instance.projectsRank = newTask.projectsRank
                instance.tags = newTask.tags
                instance.tagsId = newTask.tagsId
                instance.tagsRank = newTask.tagsRank
                instance.urlsInfo = newTask.urlsInfo

                if (newPinMoment && !instance.sprintInfo && instance.pin?.time) {
                  instance.pin = {
                    time: moment(instance.pin.time).set({
                      hour: newPinMoment.hour(),
                      minute: newPinMoment.minute(),
                      second: newPinMoment.second(),
                    }),
                  }
                } else {
                  delete instance.pin
                }
              })
            })

            dispatch(updateItems(updatedInstances))
            updateLocalTaskFromEdit(newTask, oldTask)
          } else {
            updateLocalTaskFromEdit(newTask, oldTask)
          }
          break
        }

        // single option
        default: {
          updateLocalTaskFromEdit(newTask, oldTask)
        }
      }
    },
    [dispatch, updateLocalTaskFromEdit, updateTaskAndRecurrencyInfoOfSeries]
  )

  const updateRecurringTask = useCallback(
    (newTask, oldTask, recurrenceOption) => {
      const instances = getAllInstancesOfRecTask(tasksById, findItemById, utils.task.getRecTaskId(oldTask), true)

      switch (true) {
        case !newTask.recurrencyInformation: {
          return updateTaskAndRemoveRecurrencyOfSeries(instances, newTask, oldTask)
        }

        case utils.recurrency.options.isRecurrenceEqual(
          oldTask.recurrencyInformation?.recurrencyDetails,
          newTask.recurrencyInformation?.recurrencyDetails
        ): {
          return updateTaskWhenRecurrencyDetailsAreSame(instances, newTask, oldTask, recurrenceOption)
        }

        default: {
          return updateTaskAndRecurrencyInfoOfSeries(instances, newTask)
        }
      }
    },
    [
      findItemById,
      tasksById,
      updateTaskAndRecurrencyInfoOfSeries,
      updateTaskAndRemoveRecurrencyOfSeries,
      updateTaskWhenRecurrencyDetailsAreSame,
    ]
  )

  const handleTaskSave = useCallback(
    async (newTask, oldTask, recurrenceOption, { onRecurrenceComputed } = {}) => {
      let createdInstances
      switch (true) {
        case !!oldTask.recurrencyInformation: {
          const result = updateRecurringTask(newTask, oldTask, recurrenceOption)
          if (!!result) {
            // to make sure the new id generated is the same as the one the front end generated
            newTask = produce(newTask, (draft) => {
              draft.creationTime = result?.createdRecurringTask.creationTime
              draft.recurrencyInformation.recurrencyDetails = result?.createdRecurringTask.recurrencyDetails
            })
            createdInstances = result?.createdInstances
          }

          onRecurrenceComputed?.(result)
          break
        }

        case !oldTask.recurrencyInformation && !!newTask.recurrencyInformation: {
          dispatch(removeItem(oldTask))
          const result = addRecurrenceToSingleTask(newTask)
          const { recurringTask: createdRecurringTask, tasks } = result
          // to make sure the new id generated is the same as the one the front end generated
          newTask.creationTime = createdRecurringTask.creationTime
          createdInstances = tasks
          break
        }

        default: {
          updateLocalTaskFromEdit(newTask, oldTask)
          break
        }
      }

      try {
        const out = await taskApi.putFullTask(newTask, recurrenceOption)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        if (!out?.instances) return

        const [firstResponseInstance] = out.instances

        if (out.instances.length !== createdInstances?.length || firstResponseInstance.id !== createdInstances[0].id) {
          dispatch(replaceItems(createdInstances, out.instances))
        }
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    },
    [addRecurrenceToSingleTask, dispatch, updateLocalTaskFromEdit, updateRecurringTask]
  )

  const saveTask = useCallback(
    (updatedTask, { onCancel, onRecurrenceComputed } = {}) => {
      const newTask = clone(updatedTask)
      const oldTask = findItemById(updatedTask.id)

      if (!oldTask) {
        console.warn('-- task not found')
        return
      }

      const isOldTaskRecurrent = utils.task.isRecurrent(oldTask)
      const isNewTaskRecurrent = utils.task.isRecurrent(newTask)
      const taskAttributesChanged = utils.task.areTaskAttributesNotEqual(newTask, oldTask)
      const hasRecurrenceChanged = !utils.recurrency.options.isRecurrenceEqual(
        oldTask.recurrencyInformation?.recurrencyDetails,
        newTask.recurrencyInformation?.recurrencyDetails
      )

      if (!isOldTaskRecurrent && !isNewTaskRecurrent && !taskAttributesChanged) return

      if (isOldTaskRecurrent && isNewTaskRecurrent) {
        if (newTask.sprintInfo !== oldTask.sprintInfo) {
          handleTaskSave(newTask, oldTask, InstanceOptions.Single)
          return
        }

        if (newTask.sprintInfo) {
          const haveTaskSimpleAttributesChanged = utils.task.areTaskSimpleAttributesNotEqual(newTask, oldTask)
          if (haveTaskSimpleAttributesChanged) {
            recurringItemPopup(
              {
                hideAllOption: true,
                hideSingleOption: true,
                hideTitle: true,
                noOptionsMessageText: translations.task.recurrencyPanel.noOptionsSingleTask,
                noOptionsValue: InstanceOptions.Single,
              },
              {
                onConfirmed: (recurrenceOption) => handleTaskSave(newTask, oldTask, recurrenceOption),
                onCancelled: onCancel,
              }
            )

            return
          }
        }

        if (!hasRecurrenceChanged && !taskAttributesChanged) return

        const hasWhenDateChanged = newTask.when.date !== oldTask.when.date
        const hideAllOption = hasWhenDateChanged || hasRecurrenceChanged
        const hideTitle = hideAllOption && hasRecurrenceChanged
        recurringItemPopup(
          {
            hideAllOption,
            hideSingleOption: hasRecurrenceChanged,
            hideTitle,
            title: translations.task.recurrencyPanel.edit.prompt,
          },
          {
            onConfirmed: (recurrenceOption) =>
              handleTaskSave(newTask, oldTask, recurrenceOption, { onRecurrenceComputed }),
            onCancelled: onCancel,
          }
        )
      } else {
        handleTaskSave(newTask, oldTask)
      }
    },
    [findItemById, handleTaskSave, recurringItemPopup]
  )

  const startFsAndCreateTask = useCallback(
    async ({
      context,
      taskData = models.task({
        title: '',
        when: { date: moment().format('YYYY-MM-DD') },
        to: utils.user.computeUserFromSessionUser(session.user),
      }),
    }) => {
      const creatingTask = utils.ids.addIdToItem(taskData, models.item.type.TASK, session.id)

      dispatch(addItem(creatingTask))

      await dispatch(
        createTaskAndFs(creatingTask, context, (createdTask) => {
          if (createdTask.id !== creatingTask.id) {
            dispatch(replaceItem(creatingTask, createdTask))
          }
        })
      )
    },
    [dispatch, session.user, session.id]
  )

  const deletionPopup = useCallback(
    ({ cancelLabel, confirmLabel, logo = 'sad', text, title }, { onCancelled, onConfirmed } = {}) => {
      const onConfirm = () => {
        onConfirmed?.()
        dispatch(closePopup())
      }

      const onCancel = () => {
        onCancelled?.()
        dispatch(closePopup())
      }

      dispatch(
        openPopup({
          title,
          centeredTitle: true,
          logo,
          component: (
            <ConfirmPanel
              cancelLabel={cancelLabel}
              confirmLabel={confirmLabel}
              onCancel={onCancel}
              onConfirm={onConfirm}
              text={text}
            />
          ),
        })
      )
    },
    [dispatch]
  )

  const focusSessionDeletePopup = useCallback(
    (callbacks) => {
      deletionPopup(
        {
          cancelLabel: translations.general.noKeep,
          confirmLabel: translations.general.yesPlease,
          text: translations.focussession.delete.prompt,
          title: translations.focussession.delete.prompt,
        },
        callbacks
      )
    },
    [deletionPopup]
  )

  const taskDeletePopUp = useCallback(
    (task, callbacks) => {
      if (task.recurrencyInformation) {
        recurringItemPopup({ title: translations.task.recurrencyPanel.delete.prompt }, callbacks)
      } else {
        deletionPopup(
          {
            cancelLabel: translations.taskPanel.delete.deny,
            confirmLabel: translations.taskPanel.delete.consent,
            text: translations.taskPanel.delete.prompt,
            title: translations.taskPanel.delete.prompt,
          },
          callbacks
        )
      }
    },
    [deletionPopup, recurringItemPopup]
  )

  const sprintDeletePopup = useCallback(
    (sprint, callbacks) => {
      if (sprint.recurrencyInformation) {
        recurringItemPopup({ forSprint: true, title: translations.sprint.recurrencyPanel.delete.prompt }, callbacks)
      } else {
        deletionPopup(
          {
            cancelLabel: translations.sprint.delete.deny,
            confirmLabel: translations.sprint.delete.consent,
            text: translations.sprint.delete.prompt,
            title: translations.sprint.delete.prompt,
          },
          callbacks
        )
      }
    },
    [deletionPopup, recurringItemPopup]
  )

  const addedLinksFSDeletePopup = useCallback(
    (callbacks) => {
      deletionPopup(
        {
          cancelLabel: translations.focussession.deleteOnEditConfirmation.cancel,
          confirmLabel: translations.focussession.deleteOnEditConfirmation.consent,
          text: translations.focussession.deleteOnEditConfirmation.prompt,
          title: translations.focussession.deleteOnEditConfirmation.prompt,
        },
        callbacks
      )
    },
    [deletionPopup]
  )

  const onClickFocusSession = useCallback(
    (fs) => {
      dispatch(setHighlightedEventId(fs.id))
      dispatch(updateCalendarDate(fs.startTime))
    },
    [dispatch]
  )

  const onClickDeleteFocusSession = useCallback(
    ({ focusSession, callback }) => {
      focusSessionDeletePopup({
        onConfirmed: () => {
          deleteFocusSession(focusSession)
          callback?.()
        },
        onCancelled: () => {
          callback?.()
        },
      })
    },
    [deleteFocusSession, focusSessionDeletePopup]
  )

  const onClickSprint = useCallback(
    (sprint) => {
      dispatch(setHighlightedEventId(sprint.id))
      dispatch(updateCalendarDate(sprint.pin.time))
    },
    [dispatch]
  )

  const onClickOutsideSprint = useCallback(
    (sprint) => {
      if (calendarHighlightedEventId === sprint.id) {
        dispatch(setHighlightedEventId(''))
      }
    },
    [calendarHighlightedEventId, dispatch]
  )

  const onTaskDroppedInSprint = useCallback(
    async ({ destinationIndex = 0, sprintId, taskId }) => {
      const targetSprint = clone(findItemById(sprintId))
      const task = clone(findItemById(taskId))

      if (!targetSprint || !task) {
        console.warn(`-- ${targetSprint ? 'task' : 'sprint'} not found`)
        return
      }

      const rank = destinationIndex + 1
      const newSprintInfo = {
        id: targetSprint.id,
        title: targetSprint.title,
        estimatedTime: targetSprint.estimatedTime,
        pin: targetSprint.pin,
        rank,
      }

      const updatedTask = utils.task.computeTaskOnChange(task, {
        paramName: 'sprintInfo',
        value: newSprintInfo,
      })

      const updatedItems = dispatch(updateItem(updatedTask))
      const sprint = updatedItems.sprints[updatedTask?.sprintInfo?.id]

      try {
        await sprintApi.dragAndDropTasks({
          taskId: task.id,
          sprintId: sprint.id,
          toRank: rank,
        })
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err, { sprint }))
      }
    },
    [dispatch, findItemById]
  )

  const addTasksToSprint = useCallback(
    (sprintId, taskIds) => {
      const sprint = findItemById(sprintId)

      if (!sprint) {
        console.warn('-- sprint not found')
        return
      }

      let currentRank = sprint.tasks?.length || 0
      const previousSprintsMap = {}
      const previousTasks = []
      const updatedTasks = taskIds.reduce((currentTasks, taskId) => {
        const task = findItemById(taskId)

        if (!task || task.type !== models.item.type.TASK) return currentTasks

        previousTasks.push(task)

        if (task.sprintInfo) {
          const prevSprint = findItemById(task.sprintInfo.id)

          if (prevSprint) {
            previousSprintsMap[prevSprint.id] = prevSprint
          }
        }

        currentTasks.push(
          utils.task.computeTaskOnChange(task, {
            paramName: 'sprintInfo',
            value: {
              id: sprint.id,
              title: sprint.title,
              estimatedTime: sprint.estimatedTime,
              pin: sprint.pin,
              rank: ++currentRank,
            },
          })
        )
        return currentTasks
      }, [])

      dispatch(updateItems(updatedTasks))

      return {
        saveToBackend: () => {
          updatedTasks.forEach((task) => {
            sprintApi
              .dragAndDropTasks({
                taskId: task.id,
                sprintId: sprint.id,
                toRank: task.sprintInfo.rank,
              })
              .catch((err) => {
                dispatch(handleAPIError(err, { sprint, task }))
              })
          })
        },
        undoAction: () => {
          dispatch(updateItems([...previousTasks, ...Object.values(previousSprintsMap)]))
        },
      }
    },
    [dispatch, findItemById]
  )

  const onChangeTaskParam = useCallback(
    ({ taskId, task }, { paramName, value, method = 'change' }, extraParams, forceRequest) => {
      if (!task) {
        let taskFromList = findItemById(taskId)

        if (!taskFromList) {
          console.warn('-- task not found')
          return
        }

        task = taskFromList
      } else {
        taskId = task.id
      }

      const newTask = utils.task.computeTaskOnChange(
        task,
        {
          paramName,
          value,
          method,
        },
        extraParams
      )

      dispatch(updateItem(newTask))

      if (task || forceRequest) {
        sendPatchRequest({ extraParams, method, paramName, task, value })
      }
    },
    [dispatch, findItemById, sendPatchRequest]
  )

  const onTitleChange = useCallback(
    (title, task) => {
      const focusedTaskId = getFocusedTaskId()

      if (focusedTaskId && task.id === focusedTaskId) {
        dispatch(updateFocusSessionTitle(title))
      }

      onChangeTaskParam({ task }, { paramName: 'title', value: title }, null, true)
    },
    [dispatch, getFocusedTaskId, onChangeTaskParam]
  )

  const updateTaskWithCallback = useCallback(
    (taskId, callback) => {
      const task = clone(findItemById(taskId))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const updatedTask = callback(task)
      dispatch(updateItem(updatedTask))
    },
    [dispatch, findItemById]
  )

  const _handleCompletedSession = useCallback(
    async (sessionDiffs) => {
      const completeSessionAndDiscard = (discardSession, focusSessionsUpdateFunc) => {
        dispatch(
          handleCompletedSession({ ...sessionDiffs, discardSession }, (updated) => {
            const { completedSession, completedSessionTask } = updated
            updateTaskWithCallback(completedSessionTask.id, (task) => ({
              ...task,
              focusSessions: focusSessionsUpdateFunc(task.focusSessions || [], completedSession),
              title: completedSessionTask.title,
              urlsInfo: completedSessionTask.urlsInfo,
            }))
          })
        )
      }

      const spentTimeNS = utils.focussession.getSpentTimeInNanoSeconds(sessionDiffs.updatedSession)
      const wasSessionDiscarded = !!sessionDiffs.updatedSession?.discarded

      const removeFS = (focusSessions, completedSession) => {
        return focusSessions.filter((fs) => fs.id !== completedSession.id)
      }

      const updateFS = (focusSessions, completedSession) => {
        if (focusSessions.length === 0) {
          return focusSessions.concat([completedSession])
        }

        const updatedSessions = [...focusSessions]
        const toUpdateIndex = focusSessions.findIndex((fs) => fs.id === completedSession.id)

        if (toUpdateIndex !== -1) {
          updatedSessions[toUpdateIndex] = completedSession
        }

        return updatedSessions
      }

      const noop = (fs) => fs

      if (!wasSessionDiscarded && spentTimeNS < utils.focussession.minDurationFSNS) {
        addedLinksFSDeletePopup({
          onConfirmed: () => {
            completeSessionAndDiscard(true, removeFS)
          },
        })
      } else {
        completeSessionAndDiscard(false, wasSessionDiscarded ? noop : updateFS)
      }
    },
    [addedLinksFSDeletePopup, dispatch, updateTaskWithCallback]
  )

  const _popShortcutsGroup = useCallback(
    (...args) => {
      dispatch(popShortcutsGroup(...args))
    },
    [dispatch]
  )

  const _pushShortcutsGroup = useCallback(
    (...args) => {
      dispatch(pushShortcutsGroup(...args))
    },
    [dispatch]
  )

  const onClickDelete = useCallback(
    (taskId, callback) => {
      const task = findItemById(taskId)

      if (!task) {
        console.warn('-- task not found')
        return
      }

      taskDeletePopUp(task, {
        onConfirmed: (recurrenceOption) => {
          deleteTask(taskId, recurrenceOption)
          callback?.()
        },
      })
    },
    [deleteTask, findItemById, taskDeletePopUp]
  )

  const rescheduleItems = useCallback(
    (itemIds, newDate) => {
      const itemsToUpdate = []
      const previousItems = []
      const newDateMoment = moment(newDate)

      itemIds.forEach((id) => {
        const item = findItemById(id)

        if (!item) return

        if (item && item.type === models.item.type.SPRINT) {
          const updatedSprint = produce(item, (draft) => {
            const updatedPin = moment(draft.pin.time)
            updatedPin.date(newDateMoment.date())
            updatedPin.month(newDateMoment.month())
            updatedPin.year(newDateMoment.year())
            draft.pin.time = updatedPin.format()
            draft.when.date = newDateMoment.format('YYYY-MM-DD')
          })

          previousItems.push(item)
          itemsToUpdate.push(updatedSprint)
        } else if (item.type === models.item.type.TASK) {
          if (item.sprintInfo) return

          const updatedTask = produce(item, (draft) => {
            if (draft.pin?.time) {
              const updatedPin = moment(draft.pin.time)
              updatedPin.date(newDateMoment.date())
              updatedPin.month(newDateMoment.month())
              updatedPin.year(newDateMoment.year())
              draft.pin.time = updatedPin.format()
            }

            draft.when.date = newDateMoment.format('YYYY-MM-DD')
          })

          previousItems.push(item)
          itemsToUpdate.push(updatedTask)
        }
      })

      dispatch(updateItems(itemsToUpdate))

      return {
        saveToBackend: () => {
          itemsToUpdate.forEach((item) => {
            if (item.type === models.item.type.SPRINT) {
              sprintApi.editWithTasks(item, { recurrenceOption: InstanceOptions.Single })
            } else {
              taskApi.putFullTask(item, InstanceOptions.Single)
            }
          })
        },
        undoAction: () => {
          dispatch(updateItems(previousItems))
        },
      }
    },
    [dispatch, findItemById]
  )

  return {
    addedLinksFSDeletePopup,
    addItemToCalendar,
    addTasksToSprint,
    allItems,
    archiveTask,
    calendarHighlightedEventId,
    completeTask,
    completeTaskFromFS,
    completeTasks,
    createInlineTask,
    createSprint,
    deleteFocusSession,
    deleteItems,
    deleteSprint,
    deleteTask,
    deletionPopup,
    editTasks,
    endSprint,
    findItemById,
    focusSessionDeletePopup,
    getFocusedTaskId,
    handleCompletedSession: _handleCompletedSession,
    handleSprintSave,
    handleTaskSave,
    isTaskCreationAlertShown,
    onClickDelete,
    onClickFocusSession,
    onClickOutsideSprint,
    onClickSprint,
    onClickDeleteFocusSession,
    onTaskDroppedInSprint,
    onTitleChange,
    popShortcutsGroup: _popShortcutsGroup,
    pushShortcutsGroup: _pushShortcutsGroup,
    removeItemFromCalendar,
    rescheduleItems,
    saveSprint,
    saveTask,
    sendPatchRequest,
    showTaskCreationAlert,
    sprintDeletePopup,
    sprints,
    sprintTasks,
    startFsAndCreateTask,
    taskDeletePopUp,
    uncompleteTask,
    updateCalendarItem,
    updateFocusSession,
    recurringItemPopup,
    rescheduleTasksToNextActiveInstance,
    openLoadingPopup,
    closePopup: _closePopup,
    updateRecurringSprintInstanceWithTasks,
  }
}

export const withPageActions = (Component) => {
  return (props) => {
    const actions = usePageActions()

    return <Component {...props} {...actions} />
  }
}
