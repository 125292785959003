import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { produce } from 'immer'

import { Sprint, Task } from 'gipsy-misc/types'

import { setBatchItemsIds } from 'store/batchActions/actions'

import {
  getContiguousSelection,
  GroupItem,
  GroupsOrder,
  PageItemsByGroup,
  PivotData,
  SelectedItems,
  selectItemInGroup,
  SelectionData,
  setSelectionForGroups,
  unselectItemInGroup,
} from './utils/index'

interface Props {
  findItemById: (id: string) => GroupItem | undefined
  getItemGroup: (item: GroupItem) => string
  hasNestedItems: boolean
  pageGroupsOrder: GroupsOrder
  pageItemsByGroup: PageItemsByGroup
  startBatchActions?: () => void
}

export default function useBatchSelectionHandler({
  findItemById,
  getItemGroup,
  hasNestedItems = true,
  pageGroupsOrder,
  pageItemsByGroup,
  startBatchActions,
}: Props) {
  const dispatch = useDispatch()
  const selectMode = useSelector((state) => state.batchActions.shown)

  const [selectedItems, setSelectedItems] = useState<SelectedItems>({})

  const selectionPivot = useRef<PivotData | null>(null)

  const onSelectContiguousItem = useCallback(
    (selectedItemData, pivotData) => {
      setSelectedItems((prev) => {
        const { pivot, selectedItems: newSelectedItems } = getContiguousSelection(
          selectedItemData,
          pivotData,
          prev,
          pageItemsByGroup,
          pageGroupsOrder
        )

        selectionPivot.current = pivot
        return newSelectedItems
      })
    },
    [pageGroupsOrder, pageItemsByGroup]
  )

  const onSelectNonContiguousItem = useCallback(({ item, group, sprint }: SelectionData) => {
    setSelectedItems((prev) => {
      if (prev[item.id]) {
        return unselectItemInGroup(item, prev)
      } else {
        selectionPivot.current = { group, item, sprint }
        return selectItemInGroup(item, prev, group)
      }
    })
  }, [])

  const onSelectItem = useCallback(
    (id: string, contiguousSelection: boolean = false) => {
      const item = findItemById(id)
      let parentSprint: Sprint | undefined

      if (!item) {
        console.warn('-- item not found')
        return
      }

      const taskItem = item as Task
      if (hasNestedItems && taskItem.sprintInfo) {
        parentSprint = findItemById(taskItem.sprintInfo.id) as Sprint | undefined

        if (!parentSprint) {
          console.warn('-- sprint not found')
          return
        }
      }

      if (!selectMode) {
        startBatchActions?.()
      }

      const group = getItemGroup(parentSprint || item)
      const selectedItemData = { item, group, sprint: parentSprint }

      if (contiguousSelection) {
        onSelectContiguousItem(selectedItemData, selectionPivot.current)
      } else {
        onSelectNonContiguousItem(selectedItemData)
      }
    },
    [
      findItemById,
      getItemGroup,
      hasNestedItems,
      onSelectContiguousItem,
      onSelectNonContiguousItem,
      selectMode,
      startBatchActions,
    ]
  )

  const onToggleItemsSelectionByGroups = useCallback(
    (checked: boolean, groups: string[] = []) => {
      if (!selectMode) {
        startBatchActions?.()
      }

      selectionPivot.current = null
      setSelectedItems((prev) => setSelectionForGroups(prev, pageItemsByGroup, groups, checked))
    },
    [pageItemsByGroup, selectMode, startBatchActions]
  )

  const onToggleAllItemsSelection = useCallback(
    (checked: boolean) => {
      onToggleItemsSelectionByGroups(checked, Object.keys(pageGroupsOrder))
    },
    [onToggleItemsSelectionByGroups, pageGroupsOrder]
  )

  useEffect(() => {
    if (!selectMode) {
      setSelectedItems({})
      selectionPivot.current = null
      dispatch(setBatchItemsIds([]))
    }
  }, [dispatch, selectMode])

  useEffect(() => {
    if (!selectMode) return

    setSelectedItems((prev) => {
      let shouldUpdateSelectedItems = false
      const updatedSelection = produce(prev, (draft: SelectedItems) => {
        Object.keys(draft).forEach((itemId) => {
          const item = findItemById(itemId)

          if (!item) {
            delete draft[itemId]
            shouldUpdateSelectedItems = true
            return
          }

          let itemGroup = getItemGroup(item)
          let pageItem = pageItemsByGroup[itemGroup].find((i) => i.id === item.id)

          if (pageItem) return

          const task = item as Task

          if (task.sprintInfo) {
            let sprint = findItemById(task.sprintInfo!.id)

            if (!sprint) {
              delete draft[itemId]
              shouldUpdateSelectedItems = true
              return
            }

            sprint = sprint as Sprint
            itemGroup = getItemGroup(sprint)
            const pageSprint = pageItemsByGroup[itemGroup].find((i) => i.id === sprint!.id)

            if (!pageSprint) {
              delete draft[itemId]
              shouldUpdateSelectedItems = true
              return
            }

            pageItem = (pageSprint as Sprint).tasks?.find?.((t) => t.id === task.id)
          }

          if (!pageItem || itemGroup === null || itemGroup === undefined) {
            delete draft[itemId]
            shouldUpdateSelectedItems = true
            return
          } else if (itemGroup !== draft[itemId]) {
            draft[itemId] = itemGroup
            shouldUpdateSelectedItems = true
            return
          }
        })
      })

      if (shouldUpdateSelectedItems) {
        selectionPivot.current = null
        return updatedSelection
      }

      return prev
    })
  }, [dispatch, findItemById, getItemGroup, pageItemsByGroup, selectMode])

  useEffect(() => {
    if (!selectMode) return

    dispatch(setBatchItemsIds(Object.keys(selectedItems)))
  }, [dispatch, selectedItems, selectMode])

  return {
    onSelectItem,
    onToggleAllItemsSelection,
    onToggleItemsSelectionByGroups,
    selectedItems,
    selectMode,
  }
}
