export const TaskObjectGroups = {
  COMPLETED: 'completed',
  OTHER: 'other',
  PINNED: 'pinned',
}

export const TaskSubGroups = {
  SPRINTS: 'sprints',
  PINNED_TASKS: 'pinnedTasks',
  TASKS: 'tasks',
  SPRINT_TASKS: 'sprintTasks',
}

export const InProgressSprints = 'inProgressSprints'
