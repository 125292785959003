import React from 'react'
import styled, { css } from 'styled-components'
import parser from 'react-html-parser'

import { translations } from 'gipsy-misc'

import breakpoints, { verticalBreakpoints } from 'features/layout/breakpoints'
import { timeBlockingExpVariants as variants } from 'pages/login/container'
import { getSrc } from 'utils/image'

import { TimeBlockingVariantComponent } from './Header'

interface Props {
  variant: string
}

export default function UsedBy({ variant = variants.TIME_BLOCKING }: Props) {
  return (
    <Container className='UsedBy' variant={variant}>
      <Title className='UsedBy__title' variant={variant}>
        {parser(translations.timeBlockingExp[variant].usedBy.title)}
      </Title>
      <Logos>
        <ChiliPiperLogo
          alt='Chili Piper'
          className='UsedBy__logo--chilipiper'
          src={getSrc('/images/time-blocking-exp/chilipiper.png')}
          variant={variant}
        />
        <ZapierLogo
          alt='Zapier'
          className='UsedBy__logo--zapier'
          src={getSrc('/images/time-blocking-exp/zapier.png')}
          variant={variant}
        />
        <AtlassianLogo
          alt='Atlassian'
          className='UsedBy__logo--atlassian'
          src={getSrc('/images/time-blocking-exp/atlassian.png')}
          variant={variant}
        />
        <BlaBlaCarLogo
          alt='BlaBlaCar'
          className='UsedBy__logo--blablacar'
          src={getSrc('/images/time-blocking-exp/blablacar.png')}
          variant={variant}
        />
        <AdobeLogo
          alt='Adobe'
          className='UsedBy__logo--adobe'
          src={getSrc('/images/time-blocking-exp/adobe.png')}
          variant={variant}
        />
      </Logos>
    </Container>
  )
}

const Container = styled.section<TimeBlockingVariantComponent>`
  background-color: white;
  display: flex;
  flex-flow: column;
  height: 19%;
  justify-content: center;
  padding: 0px 20px;

  @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
    height: 21%;
    padding: 20px 20px 10px;
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    min-height: 210px;
    padding: 0 20px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 20%;
      min-height: 175px;

      @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
        height: 20%;
        min-height: 175px;
      }

      @media (min-width: ${breakpoints.desktopLarge}px) {
        height: 20%;
        min-height: 175px;
      }
    `}
`

Container.displayName = 'Container'

const Title = styled.h2<TimeBlockingVariantComponent>`
  &.UsedBy__title {
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    margin: 0 auto;
    text-align: center;
    text-transform: unset;
    width: auto;

    strong {
      font-weight: 500;
    }
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    &.UsedBy__title {
      font-size: 22px;
    }
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      &.UsedBy__title {
        font-size: 18px;
        margin-bottom: 18px;
      }

      @media (min-width: ${breakpoints.desktopLarge}px) {
        &.UsedBy__title {
          font-size: 18px;
        }
      }
    `}
`

Title.displayName = 'Title'

const Logos = styled.div`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

Logos.displayName = 'Logos'

const Logo = styled.img<TimeBlockingVariantComponent>`
  object-fit: contain;
  position: relative;
`

Logo.displayName = 'Logo'

const ChiliPiperLogo = styled(Logo)`
  height: 53px;
  margin-right: 45px;
  width: 59px;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    height: 70px;
    margin-right: 30px;
    width: 88px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 63px;
      margin-right: 27px;
      width: 79px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        height: 63px;
        margin-right: 27px;
        width: 79px;
      }
    `}
`

ChiliPiperLogo.displayName = 'ChiliPiperLogo'

const ZapierLogo = styled(Logo)`
  height: 27px;
  margin-right: 38px;
  width: 54px;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    height: 30px;
    margin-right: 15px;
    width: 77px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 27px;
      margin-right: 13px;
      width: 60px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        height: 27px;
        margin-right: 13px;
        width: 60px;
      }
    `}
`

ZapierLogo.displayName = 'ZapierLogo'

const AtlassianLogo = styled(Logo)`
  height: 58px;
  margin-right: 38px;
  top: 20px;
  width: 102px;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    height: 81px;
    margin-right: 15px;
    top: 29px;
    width: 155px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 72px;
      margin-right: 13px;
      top: 26px;
      width: 138px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        height: 72px;
        margin-right: 13px;
        top: 26px;
        width: 138px;
      }
    `}
`

AtlassianLogo.displayName = 'AtlassianLogo'

const BlaBlaCarLogo = styled(Logo)`
  height: 17px;
  margin-right: 38px;
  top: -6px;
  width: 82px;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    height: 29px;
    margin-right: 31px;
    top: -6px;
    width: 132px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 26px;
      margin-right: 28px;
      top: -6px;
      width: 118px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        height: 26px;
        margin-right: 28px;
        top: -6px;
        width: 118px;
      }
    `}
`

BlaBlaCarLogo.displayName = 'BlaBlaCarLogo'

const AdobeLogo = styled(Logo)`
  height: 41px;
  top: 8px;
  width: 70px;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    height: 56px;
    top: 11px;
    width: 102px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 50px;
      top: 8px;
      width: 92px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        height: 50px;
        top: 8px;
        width: 92px;
      }
    `}
`

AdobeLogo.displayName = 'AdobeLogo'
