import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from 'Icon'
import SquareCheckbox from 'Checkbox/SquareCheckbox'

import { styles } from 'gipsy-misc'

const propTypes = {
  value: PropTypes.object.isRequired,
  selected: PropTypes.bool,
}

class DropdownSelector extends Component {
  static propTypes = propTypes

  state = {
    isHovered: false,
  }

  render() {
    const { value, selected, onClick, hideIcon, indeterminate } = this.props
    const { isHovered } = this.state
    const isHighlighted = isHovered || selected
    return (
      <TagContainer
        onClick={onClick}
        isHighlighted={isHovered || selected}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}
        className='tag-container'>
        {hideIcon ? null : (
          <IconContainer
            size={15}
            fill={isHighlighted ? styles.colors.primaryColor : styles.colors.textMediumDarkColor}
            icon='Tag'
          />
        )}
        <LabelContainer isHighlighted={isHighlighted}>{value.name}</LabelContainer>
        <StyledCheckbox
          size={16}
          isHovered={isHovered}
          checked={selected}
          onChange={onClick}
          indeterminate={indeterminate}
        />
      </TagContainer>
    )
  }
}

export { DropdownSelector }

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: ${(props) => (props.isHighlighted ? styles.colors.veryLightGrey : 'inherit')};
`

const IconContainer = styled(Icon)`
  margin-right: 15px;
  flex-shrink: 0;
`

const LabelContainer = styled.span`
  font-size: ${styles.fonts.fontSizeSmall};
  color: ${styles.colors.textMediumDarkColor};
  padding-right: 8px;
`

const StyledCheckbox = styled(SquareCheckbox)`
  &&& {
    margin-left: auto;
    flex-shrink: 0;
  }
`
