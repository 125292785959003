import React, { Fragment, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'

import { styles, translations, utils } from 'gipsy-misc'

import {
  Container as Wrapper,
  CreateButton,
  EmptyLabel,
  FlexContainer,
  ItemsContainer,
  SearchBar,
  Separator,
} from 'ListDropdowns/commonUIComponents'

export default React.forwardRef(function RecurringItemsDropdown(
  {
    className,
    createLabel,
    dropdownSelector: DropdownSelector,
    emptyLabel,
    filterAttribute = 'title',
    groupDropdownSelector: GroupDropdownSelector,
    inlineCreateButton,
    itemsMap,
    maxWidth,
    onCreate,
    onSelect,
    searchPlaceholder,
    selectedOption,
    style,
    width,
    withCreate,
  },
  ref
) {
  const [filter, setFilter] = useState('')

  const onChangeFilter = (e) => {
    setFilter(e.target.value)
  }

  const handleSelection = useCallback(
    (option) => {
      onSelect(option)
    },
    [onSelect]
  )

  const options = useMemo(() => {
    const filterRegex = new RegExp(filter, 'i')

    const filteredOptions = Object.keys(itemsMap).reduce((currentItems, itemId) => {
      const item = itemsMap[itemId]

      if (Array.isArray(item)) {
        const [firstInstance] = item

        if (filterRegex.test(firstInstance[filterAttribute])) {
          currentItems.push({ instances: item, recurrenceId: itemId })
        }
      } else if (filterRegex.test(item[filterAttribute])) {
        currentItems.push(item)
      }

      return currentItems
    }, [])

    return filteredOptions
  }, [filter, filterAttribute, itemsMap])

  const renderOptions = () =>
    options.map((option, index) => {
      const showSeparator = index !== options.length - 1

      if (option.instances) {
        return (
          <Fragment key={option.recurrenceId}>
            <GroupDropdownSelector
              onClick={handleSelection}
              recurrenceId={option.recurrenceId}
              recurrenceInstances={option.instances}
              selectedOption={selectedOption}
            />
            {showSeparator && <Separator />}
          </Fragment>
        )
      } else {
        return (
          <Fragment key={option.id}>
            <DropdownSelector
              onClick={() => handleSelection(option)}
              selected={selectedOption?.id === option.id}
              value={option}
            />
            {showSeparator && <Separator />}
          </Fragment>
        )
      }
    })

  return (
    <Container className={clsx('RecurringItemsDropdown', className)} ref={ref} style={style}>
      <Wrapper className='RecurringItemsDropdown__container' maxWidth={maxWidth} width={width}>
        <FlexContainer inlineCreateButton={inlineCreateButton}>
          <SearchBar
            onChange={onChangeFilter}
            onClick={utils.DOM.stopPropagation}
            placeholder={searchPlaceholder || translations.general.search}
            value={filter}
          />
          {withCreate && (
            <CreateButton
              backgroundColor='white'
              borderColor={styles.colors.darkGrey}
              borderRadius={8}
              onClick={onCreate}
              text={createLabel}
              textColor={styles.colors.darkGrey}
              width={60}
            />
          )}
        </FlexContainer>
        <Separator />
        {options?.length ? <ItemsContainer>{renderOptions()}</ItemsContainer> : <EmptyLabel> {emptyLabel}</EmptyLabel>}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  border-radius: 8px;
`

Container.displayName = 'Container'
