import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import moment from 'moment'
import parser from 'react-html-parser'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, models, styles, translations, utils } from 'gipsy-misc'
import { Calendar, recurrenceComponentsUtils } from 'gipsy-ui'

import variables from 'assets/styles/variables'
import CalendarNav, { NavigationContainer } from 'features/calendar/components/CalendarPanel/components/CalendarNav'
import { computePopupTopAndLeftPosition } from 'features/calendar/utils'
import { changeSlotStartDate, computeSlotFromCalendarTask } from 'logic/calendar'
import AddItemPopup from 'pages/onboarding/components/addItemPopup'
import RecurrencePopup from 'pages/onboarding/components/recurrencePopup'
import SprintEditor from 'pages/onboarding/components/sprintEditor'
import TaskCreatedPopup from 'pages/onboarding/components/taskCreatedPopup'
import { fetchNextEvents, fetchPreviousEvents, updateCalendarDate, updateScrollToTime } from 'store/calendar/actions'
import { getEventsArray } from 'store/calendar/selectors'
import { updateItem } from 'store/items/actions'
import { closePopup } from 'store/popup/actions'
import { getSrc } from 'utils/image'

import { OPTIONS as MODES } from './MasterTimeBlocking'

const { calendarLateralPadding } = variables

export default function ShowCalendarStep({
  mode = MODES.TASK,
  onCreateTask,
  onDeleteTask,
  onSaveTask,
  onStepDone,
  stepNumber,
  user,
}) {
  const dispatch = useDispatch()
  const calendarDate = useSelector((state) => state.calendar.settings.calendarDate)
  const events = useSelector((state) => getEventsArray(state))
  const scrollToTime = useSelector((state) => state.calendar.settings.scrollToTime)
  const session = useSelector((state) => state.session)

  const [addItemPopupPositionProps, setAddItemPopupPositionProps] = useState({ left: null, top: 0 })
  const [addTasksToSprintMode, setAddTasksToSprintMode] = useState(false)
  const [createdSprint, setCreatedSprint] = useState(null)
  const [createdTask, setCreatedTask] = useState(null)
  const [hoverIndicatorStyles, setHoverIndicatorStyles] = useState(null)
  const [isAddItemPopupShown, setIsAddItemPopupShown] = useState(false)
  const [isContainerHovered, setIsContainerHovered] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isRecurrencePopupShown, setIsRecurrencePopupShown] = useState(false)
  const [isTaskCreatedPopupShown, setIsTaskCreatedPopupShown] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState(null)

  const addItemPopupRef = useRef(null)
  const stepRef = useRef(null)
  const trackingData = useRef({
    hasTrackedSelectedSlot: false,
    hasTrackedCreateBlock: false,
  })

  const sprintMode = mode === MODES.SPRINT

  const onClickLeftArrow = useCallback(() => {
    let newDate = moment(calendarDate)
    newDate.subtract(1, 'week')
    newDate = newDate.toDate()
    dispatch(fetchPreviousEvents({ date: newDate, isWeekView: true }))
    dispatch(updateCalendarDate(newDate, true))
  }, [calendarDate, dispatch])

  const onClickRightArrow = useCallback(() => {
    let newDate = moment(calendarDate)
    newDate.add(1, 'week')
    newDate = newDate.toDate()
    dispatch(fetchNextEvents({ date: newDate, isWeekView: true }))
    dispatch(updateCalendarDate(newDate, true))
  }, [calendarDate, dispatch])

  const onClickToday = useCallback(() => {
    const newDate = new Date()
    dispatch(updateCalendarDate(newDate, true))
  }, [dispatch])

  useLayoutEffect(() => {
    const startOfDay = moment().startOf('day')
    let targetScroll = moment().subtract(6, 'hours')
    targetScroll = targetScroll > startOfDay ? targetScroll : startOfDay
    dispatch(updateScrollToTime(targetScroll.toDate()))
  }, [dispatch])

  const getSelectedSlotBounds = useCallback(() => {
    const calendarNode = stepRef.current

    if (calendarNode) {
      const currentHighlighted = calendarNode.querySelector('.fc-event.highlighted')
      const currentPlaceholder = calendarNode.querySelector('.fc-event.placeholder')

      if (currentPlaceholder) {
        return currentPlaceholder.getBoundingClientRect()
      } else if (currentHighlighted) {
        return currentHighlighted.getBoundingClientRect()
      }
    }
  }, [])

  const showAddItemPopup = useCallback(
    (bounds) => {
      const calendarRef = stepRef.current
      bounds = bounds ?? getSelectedSlotBounds()

      if (bounds && calendarRef) {
        const { left, shouldFlipTail, top } = computePopupTopAndLeftPosition(
          calendarRef.getBoundingClientRect(),
          bounds,
          variables.addCalendarTaskPopupHeight,
          variables.addCalendarTaskPopupWidth,
          false
        )

        setAddItemPopupPositionProps((prev) => ({
          left: !isNaN(left) ? left : prev.left,
          shouldFlipTail,
          top: !isNaN(top) ? top : prev.top,
        }))

        setIsAddItemPopupShown(true)

        if (!trackingData.current.hasTrackedSelectedSlot) {
          mixpanelApi.track({ event: mixpanel.onboardingCalendarSlotSelectedEvent })
          trackingData.current.hasTrackedSelectedSlot = true
        }
      }
    },
    [getSelectedSlotBounds]
  )

  const onSelectSlot = useCallback(
    ({ bounds, start, end, allDay }) => {
      setIsRecurrencePopupShown(false)

      setSelectedSlot({
        allDay,
        start,
        end,
        item: { title: '' },
      })

      showAddItemPopup(bounds)
    },
    [showAddItemPopup]
  )

  const moveSelectedSlot = (event) => {
    const { start, end, extendedProps } = event
    const { item } = extendedProps

    if (item.isPlaceholder) {
      onSelectSlot({ start, end })
    }
  }

  const resizeSelectedSlot = (event) => {
    const { start, end, extendedProps } = event
    const { item } = extendedProps

    if (item.isPlaceholder) {
      setSelectedSlot((selectedSlot) => ({
        start,
        end,
        item: selectedSlot.item,
      }))
    }
  }

  const hideAddItemPopup = (e) => {
    if (isRecurrencePopupShown) return

    if (e.target.classList.contains('fc-event-resizer') || e.target.getAttribute('data-is-placeholder') === 'true') {
      return
    }

    setIsAddItemPopupShown(false)
    setSelectedSlot(null)
  }

  const computePlaceholderSlot = useCallback(
    (selectedSlot) => {
      return {
        ...selectedSlot,
        color: sprintMode ? styles.colors.orangeColor : styles.colors.primaryColor,
        item: { ...selectedSlot.item, isPlaceholder: true },
        isHighlighted: false,
        isLocalItem: true,
        isPlaceholder: true,
        isSprint: false,
      }
    },
    [sprintMode]
  )

  const handleTaskCreation = async (task) => {
    mixpanelApi.track({ event: mixpanel.onboardingCalendarItemCreatedEvent }, 'Task')
    setIsAddItemPopupShown(false)
    setSelectedSlot(null)
    setIsTaskCreatedPopupShown(true)
    task = utils.ids.addIdToItem(task, models.item.type.TASK, session.id)
    setCreatedTask(task)
    const result = await onCreateTask({ task })
    const resultTask = result.task
    setCreatedTask(resultTask)

    if (resultTask.id !== task.id) {
      dispatch(updateItem(resultTask, task.id))
    }
  }

  const onTaskCreatedPopupAccept = () => {
    mixpanelApi.track({ event: mixpanel.onboardingFocusBlockPromptEvent }, 'Create a Focus Block')
    setIsTaskCreatedPopupShown(false)
    dispatch(closePopup())
    onStepDone(stepNumber, { createdTask, shouldCreateSprint: true })
  }

  const onTaskCreatedPopupDismiss = () => {
    mixpanelApi.track({ event: mixpanel.onboardingFocusBlockPromptEvent }, 'Maybe Later')
    setIsTaskCreatedPopupShown(false)
    dispatch(closePopup())
    onStepDone(stepNumber, { createdTask, shouldCreateSprint: false })
  }

  const showAddTasksToSprint = (sprint) => {
    mixpanelApi.track({ event: mixpanel.onboardingCalendarItemCreatedEvent }, 'Focus Block')
    setCreatedSprint(sprint)
    setIsAddItemPopupShown(false)
    setSelectedSlot(null)
    setAddTasksToSprintMode(true)
  }

  const showRecurrenceOptions = (sprint) => {
    setCreatedSprint(sprint)
    setAddTasksToSprintMode(false)
    setIsRecurrencePopupShown(true)

    if (selectedSlot && !trackingData.current.hasTrackedCreateBlock) {
      const start = moment(selectedSlot.start)
      const end = moment(selectedSlot.end)
      const duration = moment.duration(end.diff(start)).asMinutes()
      const when = start.startOf('day').diff(moment().startOf('day'), 'days')
      mixpanelApi.track({ event: mixpanel.onboardingCalendarCreateBlockEvent }, { duration, when })
      trackingData.current.hasTrackedCreateBlock = true
    }
  }

  const handleContinue = ({ days, typeSelected }) => {
    setIsRecurrencePopupShown(false)
    let sprint = { ...createdSprint }

    const recurrencyData = {
      customTypeSettings: { days },
      every: 1,
      recurrencyType: typeSelected,
      sprintStartTime: moment(createdSprint.pin.time),
    }

    const recurrencyDetails = recurrenceComponentsUtils.getRecurrencyDetails(recurrencyData)

    if (recurrencyDetails) {
      sprint.recurrencyInformation = { recurrencyDetails }
    }

    mixpanelApi.track({ event: mixpanel.onboardingCalendarRecurrenceSelectedEvent })
    setIsRecurrencePopupShown(false)
    dispatch(closePopup())
    onStepDone(stepNumber, { recurrencyData, sprint })
  }

  const syncItemWithSelectedSlot = useCallback((item) => {
    const { allDay, start, end } = computeSlotFromCalendarTask(item)
    setSelectedSlot({
      allDay,
      start,
      end,
      item,
    })
  }, [])

  const onTogglePin = ({ item }) => {
    if (selectedSlot) {
      if (!selectedSlot.allDay) {
        const newItem = {
          ...item,
          estimatedTime: 0,
          when: { date: moment(selectedSlot.start).format('YYYY-MM-DD') },
        }

        delete newItem.pin
        syncItemWithSelectedSlot(newItem)
        setTimeout(() => {
          showAddItemPopup()
        })
        return
      } else {
        const time = utils.task.computeTimeForToggledPin(selectedSlot.start)
        const newItem = {
          ...item,
          estimatedTime: utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 }),
          pin: {
            time: time.format(),
          },
          when: {
            date: time.format('YYYY-MM-DD'),
          },
        }

        dispatch(updateCalendarDate(newItem.when.date))
        dispatch(updateScrollToTime?.(time.toDate()))
        syncItemWithSelectedSlot(newItem)
        setTimeout(() => {
          showAddItemPopup()
        })
        return
      }
    }
  }

  const onChangeStartDate = useCallback(
    (newStartDate) => {
      setSelectedSlot((selectedSlot) => {
        const newSlot = changeSlotStartDate(selectedSlot, newStartDate)

        if (isAddItemPopupShown) {
          dispatch(updateCalendarDate(newSlot.start))
          dispatch(updateScrollToTime?.(newSlot.start))
          setTimeout(() => {
            showAddItemPopup()
          })
        }

        return newSlot
      })
    },
    [dispatch, isAddItemPopupShown, showAddItemPopup]
  )

  const onChangeSlotRange = useCallback(({ start, end }) => {
    let newEnd = end

    if (start.isSame(newEnd)) {
      newEnd.add(15, 'minutes')
    }

    setSelectedSlot((selectedSlot) => ({ ...selectedSlot, end: newEnd.toDate(), start: start.toDate() }))
  }, [])

  useEffect(() => {
    if (selectedSlot) {
      setIsMouseDown(false)
      return
    }

    const handleMouseDown = () => {
      setIsMouseDown(true)
      setHoverIndicatorStyles(null)
    }

    const handleMouseUp = () => {
      setIsMouseDown(false)
    }

    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [selectedSlot])

  useEffect(() => {
    const handleHoverIndicator = (e) => {
      if (!isContainerHovered || isMouseDown) {
        setHoverIndicatorStyles(null)
        return
      }

      const hoveredElements = document.elementsFromPoint(e.pageX, e.pageY)
      let colEl, rowEl

      for (let el of hoveredElements) {
        if (el === addItemPopupRef.current || el.classList.contains('fc-timegrid-event-harness')) {
          setHoverIndicatorStyles(null)
          return
        }

        if (el.classList.contains('fc-timegrid-slot')) {
          rowEl = el
        } else if (el.classList.contains('fc-timegrid-col')) {
          colEl = el
        }

        if (colEl && rowEl) {
          break
        }
      }

      if (!colEl && !rowEl) {
        return
      }

      const containerBounds = stepRef.current.getBoundingClientRect()
      const colBounds = colEl.getBoundingClientRect()
      const rowBounds = rowEl.getBoundingClientRect()

      setHoverIndicatorStyles({
        height: `${rowEl.clientHeight}px`,
        left: `${colBounds.left - containerBounds.left}px`,
        top: `${rowBounds.top - containerBounds.top}px`,
        width: `${colEl.clientWidth}px`,
      })
    }

    window.addEventListener('mousemove', handleHoverIndicator)

    return () => {
      window.removeEventListener('mousemove', handleHoverIndicator)
    }
  }, [isContainerHovered, isMouseDown])

  const handleHoverEnter = () => {
    setIsContainerHovered(true)
  }

  const handleHoverExit = () => {
    setIsContainerHovered(false)
  }

  const calendarItems = useMemo(() => {
    const items = [...events]

    if (selectedSlot) {
      items.push(computePlaceholderSlot(selectedSlot))
    }

    if (createdTask) {
      items.push(utils.calendar.parseItemToEvent(createdTask))
    }

    if (createdSprint) {
      items.push(utils.calendar.parseItemToEvent(createdSprint))
    }

    return items
  }, [computePlaceholderSlot, createdSprint, createdTask, events, selectedSlot])

  useEffect(() => {
    setIsAddItemPopupShown(false)
    setIsContainerHovered(false)
    setIsRecurrencePopupShown(false)
    setIsTaskCreatedPopupShown(false)
    setSelectedSlot(null)
    dispatch(closePopup())
  }, [dispatch, mode])

  const firstDayOfWeek = user?.settingsPreferences?.calendar?.firstDay

  return (
    <>
      {addTasksToSprintMode && (
        <SprintEditor
          onCreateTask={onCreateTask}
          onDeleteTask={onDeleteTask}
          onSave={showRecurrenceOptions}
          onSaveTask={onSaveTask}
          sprint={createdSprint}
        />
      )}
      <Title sprintMode={sprintMode}>
        {parser(
          sprintMode ? translations.onboarding.calendarStep.sprintTitle : translations.onboarding.calendarStep.taskTitle
        )}
      </Title>
      <Container onMouseEnter={handleHoverEnter} onMouseLeave={handleHoverExit} ref={stepRef} sprintMode={sprintMode}>
        {hoverIndicatorStyles && <HoverIndicator sprintMode={sprintMode} style={hoverIndicatorStyles} />}
        {isAddItemPopupShown && (
          <AddItemPopup
            firstDayOfWeek={firstDayOfWeek}
            left={addItemPopupPositionProps.left}
            onChangeSlotRange={onChangeSlotRange}
            onChangeStartDate={onChangeStartDate}
            onClickOutside={hideAddItemPopup}
            onClose={hideAddItemPopup}
            onCreateSprint={showAddTasksToSprint}
            onCreateTask={handleTaskCreation}
            onTogglePin={onTogglePin}
            ref={addItemPopupRef}
            selectedSlot={selectedSlot}
            showTooltip={false}
            sprintMode={sprintMode}
            top={addItemPopupPositionProps.top}
          />
        )}
        {isRecurrencePopupShown && (
          <RecurrencePopup
            firstDayOfWeek={firstDayOfWeek}
            onContinue={handleContinue}
            show={isRecurrencePopupShown}
            startTime={createdSprint?.pin?.time}
          />
        )}
        {isTaskCreatedPopupShown && (
          <TaskCreatedPopup
            onAccept={onTaskCreatedPopupAccept}
            onDismiss={onTaskCreatedPopupDismiss}
            show={isTaskCreatedPopupShown}
          />
        )}
        <CalendarNav
          calendarDate={calendarDate}
          calendarTitle={moment(utils.date.getStartOfWeek(calendarDate, firstDayOfWeek)).format('MMMM YYYY')}
          onClickLeftArrow={onClickLeftArrow}
          onClickRightArrow={onClickRightArrow}
          onClickToday={onClickToday}
        />
        <StyledCalendar
          calendarItems={calendarItems}
          date={calendarDate}
          disablePlanMyDay
          firstDay={firstDayOfWeek}
          hasDndNavigation={false}
          isCalendarSectionExpanded={true}
          onEventDrop={moveSelectedSlot}
          onEventResize={resizeSelectedSlot}
          onSelectSlot={onSelectSlot}
          scrollToTime={scrollToTime}
          viewMode={Calendar.viewModes.WEEK}
        />
      </Container>
    </>
  )
}

const Container = styled.div`
  background: white;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 auto;
  max-height: 1200px;
  min-height: 440px;
  padding: ${`12px ${calendarLateralPadding}px 8px ${calendarLateralPadding}px`};
  position: relative;
  width: 74%;

  ${NavigationContainer} {
    opacity: 1;
  }

  ${({ sprintMode }) =>
    sprintMode &&
    css`
      & .fc-highlight.fc-highlight {
        background: ${styles.colors.orangeColor}20;
      }
    `}
`

Container.displayName = 'Container'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 32px;
  font-weight: 400;
  margin: 85px 0 45px;
  text-align: center;

  & > strong {
    color: ${({ sprintMode }) => (sprintMode ? styles.colors.orangeColor : styles.colors.primaryColor)};
    font-weight: 500;
  }
`

Title.displayName = 'Title'

const HoverIndicator = styled.div`
  background-color: ${({ sprintMode }) => (sprintMode ? styles.colors.orangeColor : styles.colors.primaryColor)}80;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
`

HoverIndicator.displayName = 'HoverIndicator'

const StyledCalendar = styled(Calendar)`
  background: ${styles.colors.veryLightGrey};
  border-radius: 8px;
  overflow: hidden;
  width: 100%;

  td.fc-daygrid-day.fc-day-past,
  td.fc-timegrid-col.fc-day-past {
    background-image: url(${getSrc('images/past-date-mask.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  td.fc-daygrid-day.fc-day-past {
    .gipsy-more-link {
      background-color: transparent;
    }
  }
`

StyledCalendar.displayName = 'StyledCalendar'
