import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { produce } from 'immer'
import isEqual from 'lodash/isEqual'

import { constants } from 'gipsy-misc'
import { AttributeDropdown, FixedTooltip, Icon } from 'gipsy-ui'

import {
  getDropdownSelectedItems,
  getIndeterminateStateMapForDropdown,
  IndeterminateStateMap,
  ItemsPerEntry,
} from 'logic/batchActions'

import { ButtonContainer, ButtonContent, ButtonPopup } from './commonUIComponents'

interface Props<T> {
  activeItems: T[]
  buttonText: string
  dropdownComponent: React.FC
  emptyLabel: string
  icon: string
  onSelect: (items: T[], indeterminateIds: IndeterminateStateMap) => void
  selectedItemsPerActiveEntry: ItemsPerEntry
  shrinked: boolean
  totalSelectedItems: number
}

export default function DropdownButton<T extends { id: string; name: string }>({
  activeItems,
  buttonText,
  dropdownComponent: DropdownComponent,
  emptyLabel,
  icon,
  onSelect,
  selectedItemsPerActiveEntry,
  shrinked,
  totalSelectedItems,
}: Props<T>) {
  const [active, setActive] = useState(false)
  const [indeterminateStats, setIndeterminateStats] = useState<IndeterminateStateMap>(
    getIndeterminateStateMapForDropdown(selectedItemsPerActiveEntry, totalSelectedItems)
  )
  const [selectedItems, setSelectedItems] = useState<T[]>(
    getDropdownSelectedItems(activeItems, selectedItemsPerActiveEntry, totalSelectedItems)
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  const toggleActive = (e: React.MouseEvent) => {
    if (popupRef.current?.contains?.(e.target as Node)) return

    setActive((prev) => !prev)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (containerRef?.current?.contains(e.target as Node)) return

    setActive(false)

    const originalSelection = getDropdownSelectedItems(activeItems, selectedItemsPerActiveEntry, totalSelectedItems)
    const originalIndeterminateState = getIndeterminateStateMapForDropdown(
      selectedItemsPerActiveEntry,
      totalSelectedItems
    )
    const haveItemsChanged =
      !isEqual(selectedItems, originalSelection) || !isEqual(indeterminateStats, originalIndeterminateState)

    if (haveItemsChanged) {
      onSelect(selectedItems, indeterminateStats)
    }
  }

  const handleChange = (selectedItem: T, selected: boolean) => {
    setSelectedItems((prev) =>
      produce(prev, (draft: T[]) => {
        const itemIdx = prev.findIndex((i) => i.id === selectedItem.id)

        if (selected) {
          if (itemIdx === -1) {
            draft.push(selectedItem)
          }
        } else if (itemIdx !== -1) {
          draft.splice(itemIdx, 1)
        }
      })
    )

    setIndeterminateStats((prev) =>
      produce(prev, (draft) => {
        draft[selectedItem.id] = false
      })
    )
  }

  useEffect(() => {
    setIndeterminateStats(getIndeterminateStateMapForDropdown(selectedItemsPerActiveEntry, totalSelectedItems))
    setSelectedItems(getDropdownSelectedItems(activeItems, selectedItemsPerActiveEntry, totalSelectedItems))
  }, [active, activeItems, selectedItemsPerActiveEntry, totalSelectedItems])

  const options = activeItems.map((i) => ({
    value: i,
    indeterminate: indeterminateStats[i.id],
    label: i.name,
  }))

  return (
    <ButtonContainer active={active} fillIcon onClick={toggleActive} ref={containerRef}>
      <ButtonContent>
        {shrinked ? (
          <FixedTooltip
            content={<ButtonText>{buttonText}</ButtonText>}
            horizontalOffset={-10}
            horizontalPosition='left'
            portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
            style={{
              padding: '8px',
            }}
            verticalOffset={-12}
            verticalPosition='bottom'>
            <Icon className='ButtonIcon ButtonIcon--shrinked' icon={icon} size={14} />
          </FixedTooltip>
        ) : (
          <>
            <Icon className='ButtonIcon' icon={icon} size={14} />
            <ButtonText>{buttonText}</ButtonText>
          </>
        )}
      </ButtonContent>
      {active && (
        <StyledButtonPopup onClickOutside={handleClickOutside} ref={popupRef}>
          <AttributeDropdown
            className='popup-dropdown'
            dropdownSelector={DropdownComponent}
            emptyLabel={emptyLabel}
            isMulti
            selectedOption={selectedItems}
            onSelect={handleChange}
            options={options}
            width={277}
          />
        </StyledButtonPopup>
      )}
    </ButtonContainer>
  )
}

const ButtonText = styled.span`
  text-transform: capitalize;
`

ButtonText.displayName = 'ButtonText'

const StyledButtonPopup = styled(ButtonPopup)`
  .popup-dropdown {
    margin-bottom: 0;
  }
`

StyledButtonPopup.displayName = 'StyledButtonPopup'
