import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import SquareCheckbox from 'Checkbox/SquareCheckbox'
import { ProjectBadge } from './badge'
import asanaLogo from 'assets/asana.png'
import { AsanaIcon } from 'LineView/components/commonUIComponents'
import Icon from 'Icon'

import { styles, translations } from 'gipsy-misc'

const propTypes = {
  value: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  projectBadgeWidth: PropTypes.number,
  padding: PropTypes.number,
  isUncategorized: PropTypes.bool,
}

const CheckBoxIconSize = 16
const CheckRoundIconSizeMargin = 11
const asanaIconSize = 10
const projectBadgeWidth = 10

function DropdownSelector(props) {
  const [isHovered, setIsHovered] = useState(false)
  const { selected, onClick, padding = 16, isUncategorized, indeterminate } = props
  let { value: project } = props
  if (isUncategorized) {
    project = {
      name: translations.general.uncategorized,
      size: 15,
      id: 'uncategorized',
    }
  }
  const isHighlighted = isHovered || selected

  return (
    <ProjectContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHighlighted={isHighlighted}
      padding={padding}
      key={project.id}
      hasAsanaWorkspace={project.asanaInfo}
      onClick={onClick}>
      <BadgeContainer>
        {isUncategorized ? (
          <Icon icon={'Uncategorized'} size={15} />
        ) : (
          <ProjectBadge size={projectBadgeWidth} color={project.color} />
        )}
      </BadgeContainer>

      <ProjectName>{project.name}</ProjectName>
      {project.asanaInfo?.workspaceName && (
        <ProjectFilterAsanaWorkspace>{project.asanaInfo?.workspaceName}</ProjectFilterAsanaWorkspace>
      )}
      {project.asanaInfo ? <ProjectAsanaIcon alt={'asana logo'} src={asanaLogo} /> : <span></span>}
      <StyledCheckbox
        isHovered={isHovered}
        size={CheckBoxIconSize}
        checked={selected}
        onChange={onClick}
        paramName='selectedProject'
        indeterminate={indeterminate}
      />
    </ProjectContainer>
  )
}
DropdownSelector.propTypes = propTypes

const ProjectAsanaIcon = styled(AsanaIcon)`
  margin-left: auto;
  width: ${asanaIconSize}px;
  height: ${asanaIconSize}px;
`

const ProjectName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  grid-row: 1 / 1;
  grid-column: 2 / 2;
  font-size: ${styles.fonts.fontSizeSmall};
  color: ${styles.colors.textMediumDarkColor};
  padding-right: 8px;
`

const ProjectFilterAsanaWorkspace = styled(ProjectName)`
  color: ${styles.colors.darkGrey};
  font-size: 9px;
  grid-row: 2 / 2;
  text-transform: uppercase;
`

const ProjectContainer = styled.div`
  display: grid;
  grid-template-columns: 22px 1fr ${asanaIconSize}px ${CheckBoxIconSize + CheckRoundIconSizeMargin}px;
  align-items: center;
  width: 100%;
  padding: ${(props) => props.padding}px;
  background-color: ${(props) => (props.isHighlighted ? styles.colors.veryLightGrey : 'inherit')};
  ${(props) =>
    props.hasAsanaWorkspace &&
    css`
      grid-template-rows: auto 13px;
    `}
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
`

const StyledCheckbox = styled(SquareCheckbox)`
  &&& {
    margin-left: auto;
    flex-shrink: 0;
  }
`

export { DropdownSelector, ProjectBadge, ProjectContainer }
