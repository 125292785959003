import { produce } from 'immer'

import { Project, Tag, Task } from 'gipsy-misc/types'

export type IndeterminateStateMap = { [key: string]: boolean }
export type ItemsPerEntry = { [key: string]: number }

export const getIndeterminateStateMapForDropdown = (
  selectedItemsPerActiveEntry: ItemsPerEntry,
  totalSelectedItems: number
): IndeterminateStateMap =>
  Object.keys(selectedItemsPerActiveEntry).reduce((currentMap, optionId) => {
    const totalItemsWithEntryValue = selectedItemsPerActiveEntry[optionId]
    currentMap[optionId] = totalItemsWithEntryValue !== 0 && totalItemsWithEntryValue !== totalSelectedItems
    return currentMap
  }, {})

export const getDropdownSelectedItems = <T extends { id: string }>(
  activeItems: T[],
  selectedItemsPerActiveEntry: ItemsPerEntry,
  totalSelectedItems: number
): T[] =>
  activeItems.reduce((currentItems: T[], item: T) => {
    const totalItemsWithEntryValue = selectedItemsPerActiveEntry[item.id]

    if (totalItemsWithEntryValue !== 0 && totalItemsWithEntryValue === totalSelectedItems) {
      currentItems.push(item)
    }

    return currentItems
  }, [])

export const updateTaskProjects = (
  task: Task,
  selectedProjects: Project[],
  indeterminateIds: IndeterminateStateMap
): Task =>
  produce(task, (draft: Task) => {
    const prevProjects = task.projects || []
    const updatedProjects = prevProjects.reduce((currentProjects, p) => {
      if (indeterminateIds[p.id]) {
        currentProjects.push(p)
      }

      return currentProjects
    }, [] as Project[])

    updatedProjects.push(...selectedProjects)
    draft.projects = updatedProjects
    draft.projectsId = updatedProjects.map((p) => p.id)
  })

export const updateTaskTags = (task: Task, selectedTags: Tag[], indeterminateIds: IndeterminateStateMap): Task =>
  produce(task, (draft: Task) => {
    const prevTags = task.tags || []
    const updatedTags = prevTags.reduce((currentTags, t) => {
      if (indeterminateIds[t.id]) {
        currentTags.push(t)
      }

      return currentTags
    }, [] as Tag[])

    updatedTags.push(...selectedTags)
    draft.tags = updatedTags
    draft.tagsId = updatedTags.map((t) => t.id)
  })
