import { produce } from 'immer'

import types from './types'

const defaultState = {
  actionHandlers: {
    onComplete: null,
    onDelete: null,
    onFocusBlockSelected: null,
    onProjectsSelected: null,
    onScheduleSelected: null,
    onTagsSelected: null,
  },
  batchItemsIds: [],
  shown: false,
}

function hide() {
  return defaultState
}

function setItems(state, itemsIds) {
  state.batchItemsIds = itemsIds
  return state
}

function show(_, handlers = {}) {
  return {
    actionHandlers: {
      ...defaultState.actionHandlers,
      ...handlers,
    },
    batchItemsIds: [],
    shown: true,
  }
}

function updateHandlers(state, handlers = {}) {
  state.actionHandlers.onComplete = handlers.onComplete
  state.actionHandlers.onDelete = handlers.onDelete
  state.actionHandlers.onFocusBlockSelected = handlers.onFocusBlockSelected
  state.actionHandlers.onProjectsSelected = handlers.onProjectsSelected
  state.actionHandlers.onScheduleSelected = handlers.onScheduleSelected
  state.actionHandlers.onTagsSelected = handlers.onTagsSelected
  return state
}

function reducer(state = defaultState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.HIDE_BATCH_ACTIONS: {
        return hide()
      }

      case types.SET_BATCH_ITEMS_IDS: {
        return setItems(draft, action.payload)
      }

      case types.SHOW_BATCH_ACTIONS: {
        return show(draft, action.payload)
      }

      case types.UPDATE_BATCH_ACTIONS: {
        return updateHandlers(draft, action.payload)
      }

      default: {
        return draft
      }
    }
  })
}

export default reducer
