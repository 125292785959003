import React, { PureComponent, Fragment } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { styles, translations, utils } from 'gipsy-misc'

import get from 'lodash/get'

import {
  Container,
  CreateButton,
  EmptyLabel,
  FlexContainer,
  ItemsContainer,
  SearchBar,
  Separator,
} from 'ListDropdowns/commonUIComponents'

const propTypes = {
  createButtonIconName: PropTypes.string,
  dropdownSelector: PropTypes.elementType.isRequired,
  inlineCreateButton: PropTypes.bool,
  isMulti: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.any,
}

export default class AttributeDropdown extends PureComponent {
  state = {
    filter: '',
  }

  static propTypes = propTypes

  static defaultProps = {
    filterAttribute: 'label',
    idField: 'id',
  }

  isSelected = (option) => {
    const { isMulti, selectedOption, idField } = this.props
    if (isMulti) {
      if (selectedOption == null) return false
      if (Array.isArray(selectedOption)) {
        return !!selectedOption.find((selectedOptionItem) => selectedOptionItem[idField] === option.value[idField])
      } else {
        throw new Error('Dropdown with multiple select can only have an array or null as selectedOption')
      }
    } else {
      if (Array.isArray(selectedOption)) {
        throw new Error('Dropdown with simple select cannot have an array as selectedOption')
      }
      return !!selectedOption && selectedOption[idField] === option.value[idField]
    }
  }

  getFilterAttribute = () => {
    const { filterAttribute } = this.props
    if (filterAttribute === 'label') return filterAttribute
    return `value.${filterAttribute}`
  }

  onChangeFilter = (e) => {
    const { value } = e.target
    this.setState({ filter: value })
  }

  renderOptions = () => {
    const { dropdownSelector: DropdownSelector, options, emptyLabel } = this.props
    const { filter } = this.state
    const filterAttribute = this.getFilterAttribute()
    let filteredOptions = options

    if (filter) {
      const regex = new RegExp(filter, 'gi')
      filteredOptions = filteredOptions.filter((option) => get(option, filterAttribute).match(regex))
    }

    if (!filteredOptions.length) {
      return <EmptyLabel> {emptyLabel}</EmptyLabel>
    }

    return filteredOptions.map((option, index) => {
      const { indeterminate, value, label } = option
      const showSeparator = index !== options.length - 1
      const isSelected = this.isSelected(option)
      return (
        <Fragment key={value.id}>
          <DropdownSelector
            key={value.id}
            onClick={() => this.onSelect(option)}
            value={value}
            label={label}
            selected={isSelected}
            indeterminate={indeterminate}
          />
          {showSeparator && <Separator key={value.id + '-separator'} />}
        </Fragment>
      )
    })
  }

  onSelect = (option) => {
    const { onSelect } = this.props
    onSelect(option.value, !this.isSelected(option))
  }

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  render() {
    const {
      className,
      options,
      onClickCreateButton,
      createLabel,
      withCreate,
      emptyLabel,
      searchPlaceholder,
      maxWidth,
      width,
      setRef = noop,
      inlineCreateButton,
    } = this.props

    return (
      <Container className={className} maxWidth={maxWidth} width={width} ref={setRef}>
        <FlexContainer inlineCreateButton={inlineCreateButton}>
          <SearchBar
            value={this.state.filter}
            onChange={this.onChangeFilter}
            onClick={utils.DOM.stopPropagation}
            placeholder={searchPlaceholder || translations.general.search}
          />
          {withCreate && (
            <CreateButton
              borderRadius={8}
              backgroundColor={'white'}
              borderColor={styles.colors.darkGrey}
              textColor={styles.colors.darkGrey}
              onClick={onClickCreateButton}
              text={createLabel}
              width={60}
            />
          )}
        </FlexContainer>
        <Separator />
        {options && options.length ? (
          <ItemsContainer>{this.renderOptions()}</ItemsContainer>
        ) : (
          <EmptyLabel> {emptyLabel}</EmptyLabel>
        )}
      </Container>
    )
  }
}
