export const stopPropagation = (e) => {
  e.stopPropagation()
  return e
}

export const preventDefault = (e) => {
  e = e || window.event
  if (e.preventDefault) {
    e.preventDefault()
  }
  e.returnValue = false
}
