export const getShortcutKey = (shortcut) => {
  let shortcutKey = shortcut.key.toLowerCase()
  const joinKeys = (...keys) => keys.reduce((accKey, key) => `${accKey}__${key}`)
  if (shortcut.shiftKey) {
    shortcutKey = joinKeys(shortcutKey, 'shift')
  }
  if (shortcut.ctrlKey) {
    shortcutKey = joinKeys(shortcutKey, 'ctrl')
  }
  if (shortcut.cmdKey) {
    shortcutKey = joinKeys(shortcutKey, 'cmd')
  }
  return shortcutKey
}
