import { createAction } from 'redux-actions'

import types from './types'

const _hideBatchActions = createAction(types.HIDE_BATCH_ACTIONS)
const _setBatchItemsIds = createAction(types.SET_BATCH_ITEMS_IDS)
const _showBatchActions = createAction(types.SHOW_BATCH_ACTIONS)
const _updateBatchActions = createAction(types.UPDATE_BATCH_ACTIONS)

export const hideBatchActions = () => (dispatch) => {
  dispatch(_hideBatchActions())
}

export const setBatchItemsIds = (items) => (dispatch) => {
  dispatch(_setBatchItemsIds(items))
}

export const showBatchActions = (handlers) => (dispatch) => {
  dispatch(_showBatchActions(handlers))
}

export const updateBatchActions = (handlers) => (dispatch) => {
  dispatch(_updateBatchActions(handlers))
}
