import * as array from './array'
import * as badges from './badges'
import * as calendar from './calendar'
import * as colors from './colors'
import * as datetime from './datetime'
import * as device from './device'
import * as date from './date'
import * as delay from './delay'
import * as DOM from './DOM'
import * as email from './email'
import * as event from './event'
import * as focussession from './focussession'
import * as ids from './ids'
import * as integers from './integers'
import * as list from './list'
import * as number from './number'
import * as object from './object'
import * as observer from './observer'
import * as os from './os'
import * as provider from './provider'
import recurrency from './recurrency/index'
import * as request from './request'
import resolve from './resolve'
import * as session from './session'
import * as shortcut from './shortcut'
import * as sprint from './sprint'
import * as string from './string'
import * as tags from './tags'
import * as task from './task'
import TaskPatcher from './TaskPatcher'
import * as url from './url'
import * as user from './user'
import * as timer from './timer'

const utils = {
  array,
  badges,
  calendar,
  colors,
  date,
  datetime,
  device,
  delay,
  DOM,
  email,
  event,
  focussession,
  ids,
  integers,
  list,
  number,
  object,
  observer,
  os,
  provider,
  recurrency,
  request,
  resolve,
  session,
  shortcut,
  sprint,
  string,
  tags,
  task,
  TaskPatcher,
  url,
  user,
  timer,
}

export default utils
