const variables = {
  headerHeight: '55px',
  navbarWidth: 232,
  calendarPanelWidth: 296,
  teamMembersHeight: '80px',
  pageMaxWidth: '1200px',
  pageMaxWidthSmallScreen: '900px',
  bodyBackgroundColor: '#F7F7FA',
  teamMembersBackgroundColor: '#f5f5f5',
  headerBackgroundColor: '#fdfdfd',
  mobileNavbarHeight: 60,
  addCalendarTaskPopupWidth: 235,
  editCalendarItemPopupWidth: 347,
  addCalendarTaskPopupTailWidth: 10,
  addCalendarTaskPopupHeight: 284,
  activeCalendarPanelHeaderHeight: 152,
  shortcutsBarHeight: 40,
  calendarLateralPadding: 16,
  calendarNavigationButtonWidth: 32,
  taskPanelContainerWidth: 330,
  headerNavbarHeight: 56,
}

export default variables
