import React from 'react'
import styled, { css } from 'styled-components'
import Icon from 'Icon'
import { Timer } from 'Timer'
import compose from 'lodash/flow'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import throttle from 'lodash/throttle'

import {
  StartButtonContainer,
  StartButtonActionsSection,
  CheckboxSection,
  START_BUTTON_WIDTH,
  START_BUTTON_MIN_WIDTH,
} from './commonUIComponents'
import Checkbox from 'Checkbox'
import PlayIcon from 'PlayIcon'

import { styles, translations, utils } from 'gipsy-misc'

export function StartButton(props) {
  const {
    showLinkClicker,
    item,
    session,
    setRef,
    onStartFocus,
    onPauseFocus,
    creating,
    onComplete,
    onClickCompleteButton,
    justCompleted,
    focusedTaskId,
    isTaskOnFocusSession,
    linePadding,
    disableHoverAnimation,
    color,
    playIconBackground,
    grayCheckBox,
    isDragging,
    extraPaddingTop,
    removeStartButton,
  } = props

  const primaryColor = color || styles.colors.primaryColor

  const spentTime = utils.task.getSpentTime(item)
  const isStartButtonDisabled = (!!focusedTaskId && !isTaskOnFocusSession) || justCompleted

  const getFormattedSpentTime = () => {
    return spentTime ? utils.datetime.beautifySpentTime(spentTime) + ` (${item.focusSessions.length})` : null
  }

  const onClickStartButton = throttle((e) => {
    const urlsToOpen = (item.urlsInfo || []).map((urlInfoItem) => urlInfoItem.url)
    onStartFocus({ urlsToOpen })
  }, 1000)

  const onClickElapsedTime = props.onClickElapsedTime
    ? compose([utils.DOM.stopPropagation, props.onClickElapsedTime])
    : undefined

  const debouncedPauseFocus = debounce(onPauseFocus, 500)

  const onClickPauseButton = compose([utils.DOM.stopPropagation, debouncedPauseFocus])

  const _onClickCompleteButton = compose([
    utils.DOM.stopPropagation,
    isStartButtonDisabled ? noop : onClickCompleteButton,
  ])

  const showCheckboxContainer = !creating && !isTaskOnFocusSession

  const showElapsedTime = !!((isTaskOnFocusSession && session.focusSession.startTime) || spentTime)

  const renderButtonIcon = () => {
    if (isTaskOnFocusSession) {
      return (
        <LeftInlineIcon
          onClick={onClickPauseButton}
          margin={4}
          size={20}
          icon={'InversePause'}
          isDisabled={isStartButtonDisabled}
          color={color}
        />
      )
    }
    return !focusedTaskId ? <PlayIcon playIconBackground={playIconBackground} fillColor={primaryColor} /> : <div />
  }
  return (
    <StartButtonContainer higherZIndex={showLinkClicker}>
      <StyledStartButtonActionsSection
        color={primaryColor}
        linePadding={linePadding}
        disableHoverAnimation={disableHoverAnimation}
        ref={setRef}
        isDisabled={isStartButtonDisabled}
        isClickable={!isTaskOnFocusSession}
        isTaskOnFocusSession={isTaskOnFocusSession}
        extraPaddingTop={extraPaddingTop}
        onClick={!focusedTaskId && !removeStartButton ? onClickStartButton : noop}>
        {!removeStartButton && (
          <>
            {!disableHoverAnimation && (
              <ActiveButtonContainer isDragging={isDragging}>
                <PlayIcon fillColor={primaryColor} />
                <DefaultDescription>
                  <span>{translations.general.start}</span>
                </DefaultDescription>
              </ActiveButtonContainer>
            )}

            <ButtonContainer isDragging={isDragging}>
              {renderButtonIcon()}
              {isTaskOnFocusSession && !item.completed && (
                <ActiveDescription
                  isDisabled={isStartButtonDisabled}
                  color={primaryColor}
                  onClick={(e) => _onClickCompleteButton(e)}>
                  <span>{translations.general.done}</span>
                </ActiveDescription>
              )}
            </ButtonContainer>
          </>
        )}
        {showElapsedTime && (
          <ElapsedTimeContainer
            isDragging={isDragging}
            isTaskOnFocusSession={isTaskOnFocusSession}
            isClickable={!!onClickElapsedTime}
            onClick={onClickElapsedTime}>
            <LeftInlineIcon fill={styles.colors.grey} size={9} icon={'HourGlassOutlined'} />
            {isTaskOnFocusSession ? (
              <Timer startDate={session.focusSession.startTime || Date.now()} timeAlreadySpent={spentTime} showHours />
            ) : (
              <span> {getFormattedSpentTime()}</span>
            )}
          </ElapsedTimeContainer>
        )}
      </StyledStartButtonActionsSection>

      {showCheckboxContainer && (
        <CheckboxSection linePadding={linePadding} extraPaddingTop={extraPaddingTop}>
          <StyledCheckbox
            size={16}
            disableTransition={isDragging}
            checkmarkTop={1.2}
            checkmarkLeft={4.5}
            checkmarkWidth={3}
            checkmarkHeight={6}
            showBoxShadow={false}
            checkmarkBorderWidth={1.5}
            borderWidth={1.5}
            checkedColor={grayCheckBox ? styles.colors.middleGrey : styles.colors.greenColor}
            checkMarkColor={grayCheckBox ? styles.colors.darkGrey : 'white'}
            hoverCheckMarkColor={styles.colors.darkGrey}
            hoverBackgroundColor={'white'}
            hoverBorderColor={styles.colors.darkGrey}
            borderColor={styles.colors.darkGrey}
            id={item.id}
            paramName='completed'
            checked={!!item.completed || justCompleted}
            onChange={onComplete}
            hide={isTaskOnFocusSession ? 1 : 0}
          />
        </CheckboxSection>
      )}
    </StartButtonContainer>
  )
}

const InlineIcon = styled(Icon)`
  display: inline-block;
`

const LeftInlineIcon = styled(InlineIcon)`
  margin-right: ${(props) => (props.margin ? props.margin : 5)}px;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  ${(props) =>
    props.color &&
    css`
      .pause-bar {
        fill: ${props.color};
      }
    `}
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`

const ButtonDescription = styled.span`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  align-items: center;
  justify-content: space-between;
  padding-left: 9px;
  span {
    display: inline-flex;
  }
`

const DefaultDescription = styled(ButtonDescription)`
  ${InlineIcon} {
    height: 7px;
  }
`

const StyledCheckbox = styled(Checkbox)`
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`

const ElapsedTimeContainer = styled.div`
  color: ${styles.colors.darkGrey};
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'inherit')};
  margin-top: auto;

  ${(props) =>
    !props.isDragging &&
    css`
      transition: 0.4s all ease-in-out;
      path {
        transition: 0.4s fill ease-in-out;
      }
    `}

  ${(props) =>
    props.isTaskOnFocusSession &&
    css`
      color: white;
      path {
        fill: white;
      }
    `}
`

const ActiveDescription = styled(ButtonDescription)`
  background: white;
  border-radius: 6px;
  color: ${(props) => props.color || styles.colors.primaryColor};
  text-align: center;
  justify-content: space-between;
  padding-right: 9px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`

const ButtonContainer = styled.span`
  display: flex;
  font-size: ${styles.fonts.fontSizeXSmall};
  align-items: center;
  justify-content: center;
  ${(props) =>
    !props.isDragging &&
    css`
      transition: 0.4s all ease-in-out;
    `}
`

const ActiveButtonContainer = styled(ButtonContainer)`
  opacity: 0;
  transform: translateX(${START_BUTTON_WIDTH - START_BUTTON_MIN_WIDTH}px);
  width: calc(100% - ${(START_BUTTON_WIDTH - START_BUTTON_MIN_WIDTH) * 2}px);
  position: absolute;
  z-index: 2;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
`

const StyledStartButtonActionsSection = styled(StartButtonActionsSection)`
  ${(props) =>
    !props.disableHoverAnimation &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        transition: 0.4s ease-in-out width;
        z-index: 1;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: ${(props) => props.color || styles.colors.primaryColor};
        overflow: hidden;
      }
      transition: width 0.4s ease-in-out;
      :hover {
        width: ${START_BUTTON_WIDTH}px;
        ::after {
          width: ${START_BUTTON_WIDTH}px;
        }

        ${ButtonContainer}:not(${ActiveButtonContainer}) {
          transform: translateX(-${START_BUTTON_WIDTH - START_BUTTON_MIN_WIDTH}px);
          position: absolute;
          opacity: 0;
        }

        ${ActiveButtonContainer} {
          transform: translateX(0);
          width: 100%;
          position: relative;
          opacity: 1;
        }

        ${ElapsedTimeContainer} {
          color: white;
          z-index: 2;
          path {
            fill: white;
          }
        }
      }
    `}
  cursor: ${(props) => (props.isClickable ? (props.isDisabled ? 'not-allowed' : 'pointer') : 'inherit')};
`
