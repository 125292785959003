import React, { useState, useRef, useCallback } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { IntegrationInfoRow, IntegrationInfoRowWrapper } from 'pages/integrations/common'
import ConfirmPanel from 'features/popup/components/confirmPanel'
import useClickOutside from 'features/app/hooks/useClickOutside'

import { Icon, Separator, CustomPopup, PopupItem, PrimaryButton, SecondaryButton, UserPicture } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

const IntegrationInfoContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const IntegrationInfoValue = styled.span`
  color: ${(props) => props.color || styles.colors.textDarkColor};
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
`

const IntegrationInfoSubtitle = styled.span`
  margin-top: 3px;
  color: ${(props) => props.color || styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeXSmall};
`

const spinAnimation = keyframes`
   0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
`

const pulseAnimation = keyframes`   
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }

`

const fadeIn = keyframes`   
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  `

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: ${(props) => props.width && `${props.width}px`};
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  ${(props) =>
    props.width &&
    css`
      min-width: ${props.width}px;
    `};
  padding: 8px 10px;
  margin-left: ${(props) => props.marginLeft}px;
`

const ActionIcon = styled(Icon)`
  margin-right: 10px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.isSyncing &&
    css`
      animation: ${spinAnimation} 1s ease-in-out infinite;
    `}
`

const IntegrationInfoRowActions = styled.span`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const ActionText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: ${(props) => props.lineHeight}px;
  color: ${(props) => props.color || styles.colors.darkGrey};
  ${(props) =>
    props.animate &&
    css`
      font-style: italic;
      animation: ${pulseAnimation} 1s ease-in-out infinite;
    `};
  ${(props) =>
    props.fadeIn &&
    css`
      animation: ${fadeIn} 1s ease-in-out;
    `};
`

const InvalidCredentialsLabelContainer = styled.div`
  align-items: center;
  color: ${styles.colors.textDarkColor};
  display: flex;
  font-size: 14px;
  line-height: 22px;
  margin-right: 32px;
`

const WarningIcon = styled(Icon)`
  margin-right: 8px;
`

const ONE_MINUTE = 1000 * 60 * 1

const AccountInfo = React.memo(
  ({
    areCredentialsInvalid,
    grantAccessButtonLabel,
    invalidCredentialsLabel,
    name,
    subname,
    picture,
    onSyncAccount,
    onUnsyncAccount,
    openPopup,
    closePopup,
    lastSyncId,
    onClickGrantAccess,
    onClickSecondaryButton,
    secondaryButtonLabel,
    unsyncPopupTitle,
    unsyncPopupSubtitle,
  }) => {
    const [isSyncing, setSyncing] = useState(false)
    const [isUnsyncing, setUnsyncing] = useState(false)
    const [isJustSyncedMessageShown, setJustSyncedMessageShown] = useState(false)
    const [isSyncDisabledMessageShown, setSyncDisabledMessageShown] = useState(false)
    const [showActionsPopup, setShowActionsPopup] = useState(false)

    const disabledMessageTimeoutRef = useRef(null)
    const moreButtonRef = useRef(null)

    const onClickOutsideMoreButton = useCallback(() => setShowActionsPopup(false), [])
    useClickOutside(moreButtonRef, onClickOutsideMoreButton)

    const onClickMoreActionsButton = () => setShowActionsPopup(!showActionsPopup)

    const checkSyncButtonEnabled = () => {
      const lastSync = window.localStorage.getItem(lastSyncId)
      return isSyncing || !lastSync || (Date.now() > lastSync && Date.now() - lastSync > ONE_MINUTE)
    }

    const showSyncedMessage = () => {
      setJustSyncedMessageShown(true)
      setTimeout(() => setJustSyncedMessageShown(false), 2500)
    }

    const showSyncDisabledMessage = () => {
      setSyncDisabledMessageShown(true)
      if (disabledMessageTimeoutRef.current) clearTimeout(disabledMessageTimeoutRef.current)
      disabledMessageTimeoutRef.current = setTimeout(() => {
        setSyncDisabledMessageShown(false)
      }, 3500)
    }

    const syncAccount = async () => {
      const shouldSync = checkSyncButtonEnabled()
      if (shouldSync) {
        setSyncing(true)
        try {
          await onSyncAccount()
          window.localStorage.setItem(lastSyncId, Date.now())
          showSyncedMessage()
        } catch (e) {
          console.log('sync failed')
        } finally {
          setSyncing(false)
        }
      } else {
        showSyncDisabledMessage()
      }
    }

    const onClickUnsyncAccount = () => {
      const onConfirm = async () => {
        closePopup()
        setUnsyncing(true)
        await onUnsyncAccount()
        setUnsyncing(false)
      }
      const onCancel = () => closePopup()

      openPopup({
        title: unsyncPopupTitle,
        centeredTitle: true,
        logo: 'sad',
        component: (
          <ConfirmPanel
            subtitle={unsyncPopupSubtitle}
            confirmLabel={translations.general.yesPlease}
            cancelLabel={translations.general.noKeep}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        ),
      })
    }

    const getSyncButtonMessage = useCallback(() => {
      if (isJustSyncedMessageShown) {
        return translations.general.refreshed
      } else if (isSyncing) {
        return translations.general.refreshing
      } else if (isUnsyncing) {
        return translations.integrations.unsyncing
      } else {
        return translations.general.refresh
      }
    }, [isJustSyncedMessageShown, isSyncing, isUnsyncing])

    return (
      <React.Fragment>
        <IntegrationInfoRow invalid={areCredentialsInvalid}>
          <IntegrationInfoRowWrapper>
            <UserPicture
              backgroundColor='transparent'
              borderRadius='50px'
              size={32}
              sizeDefault={24}
              src={picture}
              style={{ marginRight: 11 }}
            />
            <IntegrationInfoContainer>
              <IntegrationInfoValue>{name}</IntegrationInfoValue>
              <IntegrationInfoSubtitle>{subname}</IntegrationInfoSubtitle>
            </IntegrationInfoContainer>
            <IntegrationInfoRowActions>
              {isSyncDisabledMessageShown && (
                <ActionText fadeIn={true}>{translations.integrations.sync.disabled}</ActionText>
              )}
              {areCredentialsInvalid ? (
                <>
                  <InvalidCredentialsLabelContainer>
                    <WarningIcon fill='none' icon='Warning' size={16} stroke={styles.colors.redColor} />
                    {invalidCredentialsLabel}
                  </InvalidCredentialsLabelContainer>
                  {onClickGrantAccess && (
                    <PrimaryButton
                      onClick={onClickGrantAccess}
                      height={32}
                      width={140}
                      text={grantAccessButtonLabel}></PrimaryButton>
                  )}
                </>
              ) : (
                <>
                  <ActionContainer width={120} onClick={syncAccount}>
                    <ActionIcon
                      size={14}
                      icon={'Refresh'}
                      isSyncing={isSyncing}
                      fill={checkSyncButtonEnabled() ? styles.colors.darkGrey : styles.colors.middleGrey}
                    />
                    <ActionText
                      animate={isSyncing || isUnsyncing}
                      color={
                        isJustSyncedMessageShown
                          ? styles.colors.greenColor
                          : checkSyncButtonEnabled() || isUnsyncing
                          ? styles.colors.darkGrey
                          : styles.colors.middleGrey
                      }>
                      {getSyncButtonMessage()}
                    </ActionText>
                  </ActionContainer>
                  {onClickSecondaryButton && (
                    <SecondaryButton
                      onClick={onClickSecondaryButton}
                      height={32}
                      width={140}
                      text={secondaryButtonLabel}></SecondaryButton>
                  )}
                </>
              )}
              <ActionContainer
                cursor={onUnsyncAccount ? 'pointer' : 'auto'}
                onClick={onUnsyncAccount ? onClickMoreActionsButton : undefined}
                ref={moreButtonRef}
                marginLeft={20}
                width={34}>
                {onUnsyncAccount && <Icon icon='More' size={14} />}
                {showActionsPopup && (
                  <CustomPopup left={`100%`}>
                    <PopupItem
                      hoverBackgroundColor={'white'}
                      textColor={styles.colors.darkGrey}
                      onClick={onClickUnsyncAccount}>
                      {translations.general.disconnect}
                    </PopupItem>
                  </CustomPopup>
                )}
              </ActionContainer>
            </IntegrationInfoRowActions>
          </IntegrationInfoRowWrapper>
        </IntegrationInfoRow>
        <Separator />
      </React.Fragment>
    )
  }
)

export default AccountInfo
