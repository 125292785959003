import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { models, styles, translations } from 'gipsy-misc'

import { Dropdown, DropdownCaret, DropdownTrigger, Label } from './commonUIComponents'
import { TypePreset as DropdownOption } from './RecurrencePresetsPopup'

const { Schedules } = models.recurrency

export default function ScheduleTypeDropdown({ onChange, scheduleType, selectedEvery = 1, shrinked }) {
  const [dropdownShown, setDropdownShown] = useState(false)

  const handleEveryChange = (e) => {
    const value = e.target.value

    if (value === '' || value.match(/^[1-9]{1}$/)) {
      onChange?.(scheduleType, value ? +value : value)
    }
  }

  const handleBlur = () => {
    if (selectedEvery === '') {
      onChange?.(scheduleType, 1)
    }
  }

  const selectMonthlySchedule = (e) => {
    e.stopPropagation()
    setDropdownShown(false)
    onChange?.(Schedules.Monthly, selectedEvery ? +selectedEvery : 1)
  }

  const selectWeeklySchedule = (e) => {
    e.stopPropagation()
    setDropdownShown(false)
    onChange?.(Schedules.Weekly, selectedEvery ? +selectedEvery : 1)
  }

  return (
    <Container className='ScheduleTypeDropdown' shrinked={shrinked}>
      <Label className='ScheduleTypeDropdown__label'>{translations.general.repeatEvery}</Label>
      <Label className='ScheduleTypeDropdown__label'>
        <EveryInput
          max={9}
          min={1}
          onBlur={handleBlur}
          onChange={handleEveryChange}
          type='number'
          value={selectedEvery}
        />
      </Label>
      <DropdownTrigger className='ScheduleTypeDropdown__dropdown-trigger' onClick={() => setDropdownShown(true)}>
        {scheduleType === Schedules.Monthly ? translations.general.month.singular : translations.general.week}
        <DropdownCaret icon='CaretDown' fill={styles.colors.textMediumDarkColor} size={10} />
        {dropdownShown && (
          <Dropdown className='ScheduleTypeDropdown__dropdown' onClickOutside={() => setDropdownShown(false)}>
            <DropdownOption
              onClick={selectWeeklySchedule}
              selected={scheduleType === Schedules.Weekly}
              text={translations.general.week}
            />
            <DropdownOption
              onClick={selectMonthlySchedule}
              selected={scheduleType === Schedules.Monthly}
              text={translations.general.month.singular}
            />
          </Dropdown>
        )}
      </DropdownTrigger>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  ${({ shrinked }) =>
    shrinked &&
    css`
      .ScheduleTypeDropdown__label {
        font-size: 12px;
      }

      .ScheduleTypeDropdown__dropdown-trigger {
        font-size: 12px;
        padding: 0 6px;
      }

      .ScheduleTypeDropdown__dropdown {
        left: unset;
        right: 0;
      }
    `}
`

Container.displayName = 'Container'

const EveryInput = styled.input`
  appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  height: 32px;
  outline: none;
  text-align: center;
  width: 16px;

  ::selection {
    background: ${styles.colors.primaryColor};
    color: white;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :active,
  :focus,
  :hover {
    border-color: ${styles.colors.middleGrey};
    color: ${styles.colors.primaryColor};
  }
`

EveryInput.displayName = 'EveryInput'
