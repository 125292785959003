import React from 'react'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'

import breakpoints, { verticalBreakpoints } from 'features/layout/breakpoints'
import { TimeBlockingVariantComponent } from 'pages/login/components/TimeBlockingExp/components/Header'
import { timeBlockingExpVariants as variants } from 'pages/login/container'
import { getSrc } from 'utils/image'

const imageMap = {
  0: 'testimonial-1.jpg',
  1: 'testimonial-2.jpg',
  2: 'testimonial-3.jpg',
  3: 'testimonial-4.jpg',
}

interface Props {
  testimonialNumber: number
  variant: string
}

export default function Testimonial({ testimonialNumber, variant = variants.TIME_BLOCKING }: Props) {
  const testimonialData = translations.timeBlockingExp[variants.TIME_BLOCKING].testimonials[testimonialNumber]

  return (
    <Container className='Testimonial'>
      <Image
        alt={testimonialData.reviewer}
        className='Testimonial__image'
        src={getSrc(`/images/time-blocking-exp/${imageMap[testimonialNumber]}`)}
        variant={variant}
      />
      <ReviewContainer className='Testimonial__review-container'>
        <Review className='Testimonial__review' variant={variant}>
          {testimonialData.review}
        </Review>
        <Reviewer className='Testimonial__reviewer' variant={variant}>
          {testimonialData.reviewer}
        </Reviewer>
      </ReviewContainer>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
`

Container.displayName = 'Container'

const Image = styled.img<TimeBlockingVariantComponent>`
  border-radius: 50%;
  flex-shrink: 0;
  height: 61px;
  margin-right: 23px;
  width: 61px;

  @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
    height: 71px;
    width: 71px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 94px;
      margin-right: 16px;
      width: 94px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        height: 94px;
        width: 94px;
      }
    `}
`

Image.displayName = 'Image'

const ReviewContainer = styled.div`
  color: ${styles.colors.textDarkColor};
  display: flex;
  flex-flow: column;
  max-width: 464px;
`

ReviewContainer.displayName = 'ReviewContainer'

const Review = styled.p<TimeBlockingVariantComponent>`
  font-size: 15px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;

  @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
    line-height: 22px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      font-size: 17px;
      line-height: 20px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        line-height: 20px;
      }
    `}
`

Review.displayName = 'Review'

const Reviewer = styled.span<TimeBlockingVariantComponent>`
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      font-size: 15px;
      margin-top: 1px;
    `}
`

Reviewer.displayName = 'Reviewer'
