import React from 'react'
import styled, { css } from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { styles } from 'gipsy-misc'

const borderSize = 1
const defaultSize = 18

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
  },
  common: (props) => ({
    backgroundColor: 'white',
    borderRadius: 5,
    width: props.iconSize || defaultSize,
    height: props.iconSize || defaultSize,
    borderStyle: 'solid',
    borderWidth: '1px',
  }),
  icon: {
    borderColor: styles.colors.darkGrey,
    backgroundColor: 'transparent',
  },
  checkedIcon: (props) => ({
    backgroundColor: props.color || styles.colors.primaryColor,
    borderColor: props.color || styles.colors.primaryColor,
    '&:before': {
      display: 'block',
      width: (props.iconSize || defaultSize) - borderSize * 2,
      height: (props.iconSize || defaultSize) - borderSize * 2,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff' /></svg>\")",
      content: '""',
    },
  }),
  intermediateIcon: (props) => ({
    backgroundColor: 'white',
    borderColor: styles.colors.primaryColor,
    '&:before': {
      display: 'block',
      width: (props.iconSize || defaultSize) - borderSize * 2,
      height: (props.iconSize || defaultSize) - borderSize * 2,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='3' y='7' width='10' height='2' rx='1' fill='%23643fdb'/></svg>\")",
      backgroundPosition: 'center',
      content: '""',
    },
  }),
})

const SquareCheckbox = ({
  className,
  disabled,
  id,
  size,
  checked,
  onChange,
  onClick,
  indeterminate,
  isHovered,
  color,
}) => {
  const classes = useStyles({ iconSize: size, isHovered, color })

  const _onChange = (e) => {
    if (onChange) {
      onChange({
        id: id,
        value: (1 + checked) % 2,
      })
    }
  }

  return (
    <StyledCheckbox
      disabled={disabled}
      iconSize={size}
      isHovered={isHovered}
      hoverColor={color}
      className={clsx(classes.root, className)}
      checked={checked}
      onChange={_onChange}
      onClick={onClick}
      checkedIcon={<span className={clsx(classes.common, classes.checkedIcon) + ' checked-icon'} />}
      icon={<span className={clsx(classes.common, classes.icon) + ' icon'} />}
      indeterminate={indeterminate}
      indeterminateIcon={<span className={clsx(classes.common, classes.intermediateIcon) + ' indeterminate-icon'} />}
    />
  )
}

const iconHoverBehavior = css`
  .icon {
    ::before {
      display: block;
      width: ${(props) => (props.iconSize || defaultSize) - borderSize * 2}px;
      height: ${(props) => (props.iconSize || defaultSize) - borderSize * 2}px;
      background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23D1C5F4' /></svg>");
      content: '';
    }
    border-color: ${(props) => computeNewColorValue(props.hoverColor, 0.5) || styles.colors.lightVioletColor};
  }
`

const iconDisabledBehavior = css`
  &,
  &:hover {
    .icon,
    .checked-icon {
      background-color: ${styles.colors.veryLightGrey};
      border-color: ${styles.colors.darkGrey}66;

      ::before {
        background-image: ${(props) =>
          props.checked
            ? `url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23D1C5F4' /></svg>")`
            : 'none'};
        opacity: 0.6;
      }
    }
  }
`

const StyledCheckbox = styled(({ disabled, isHovered, iconSize, hoverColor, ...other }) => <Checkbox {...other} />)`
  padding: 0;
  ${(props) => props.isHovered && iconHoverBehavior}

  :hover {
    ${iconHoverBehavior};
    .indeterminate-icon {
      background-color: ${(props) => computeNewColorValue(props.hoverColor, 0.3) || styles.colors.lightVioletColor};
    }
  }

  ${({ disabled }) => disabled && iconDisabledBehavior}
`

const computeNewColorValue = (hex, opacityValue) => {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacityValue + ')'
  }
  return hex
}

export default SquareCheckbox
