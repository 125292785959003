import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { animate, motion, useMotionValue } from 'framer-motion'
import moment from 'moment'

import { styles, translations, variables } from 'gipsy-misc'
import { Icon } from 'gipsy-ui'

import { hideUndoToast } from 'store/undoToast/actions'

export default function UndoToast() {
  const dispatch = useDispatch()
  const progressWidth = useMotionValue<string>('0%')
  const toastState = useSelector((state) => state.undoToast)
  const { action, shown, timeout, undoAction } = toastState

  const previousAction = useRef<any | null>(null)
  const progressInterval = useRef<number>(0)

  const handleUndo = () => {
    window.clearInterval(progressInterval.current)
    previousAction.current = null
    undoAction?.()
    dispatch(hideUndoToast())
  }

  const handleClose = () => {
    dispatch(hideUndoToast())
  }

  useEffect(() => {
    if (!shown) return

    if (previousAction.current) {
      previousAction.current?.()
    }

    const duration = timeout / 1000
    progressWidth.set('0%')
    animate(progressWidth, '100%', { duration, ease: 'linear' })

    previousAction.current = action
    window.clearInterval(progressInterval.current)
    const afterTimeoutMoment = moment().add(timeout, 'milliseconds')
    progressInterval.current = window.setInterval(() => {
      const now = moment()

      if (now.isSameOrAfter(afterTimeoutMoment)) {
        previousAction.current = null
        action?.()
        dispatch(hideUndoToast())
        window.clearInterval(progressInterval.current)
      }
    })
  }, [action, dispatch, progressWidth, shown, timeout])

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (shown) {
        e.preventDefault()
        e.returnValue = ''
        return
      }

      delete e['returnValue']
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [shown])

  return (
    <Container animate={shown ? 'enter' : 'exit'} initial='exit' variants={variants}>
      <ButtonsRow>
        <ActionMessage>{translations.undoToast.taskMoved}</ActionMessage>
        <UndoButton onClick={handleUndo}>{translations.undoToast.undoLabel}</UndoButton>
        <CloseIcon icon='Close' onClick={handleClose} size={12} />
      </ButtonsRow>
      <ProgressBar>
        <Progress style={{ width: progressWidth }} />
      </ProgressBar>
    </Container>
  )
}

const variants = {
  enter: {
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'linear',
    },
    x: '0%',
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      type: 'linear',
    },
    x: 'calc(-100% - 40px)',
  },
}

const Container = styled(motion.div)`
  background-color: white;
  border: 1px solid ${styles.colors.primaryColor};
  border-radius: 4px;
  bottom: 30px;
  left: 40px;
  min-width: 330px;
  position: fixed;
  z-index: ${variables.zIndex.fixedItem};
`

Container.displayName = 'Container'

const ButtonsRow = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 21px;
  user-select: none;
  width: 100%;
`

ButtonsRow.displayName = 'ButtonsRow'

const ActionMessage = styled.span`
  color: ${styles.colors.textMediumDarkColor};
  font-weight: 500;
`

ActionMessage.displayName = 'ActionMessage'

const UndoButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  font-family: 'NeurialGrotesk', Helvetica, Arial;
  font-weight: 500;
  margin: 0 16px 0 auto;
  outline: none;
`

UndoButton.displayName = 'UndoButton'

const CloseIcon = styled(Icon)`
  cursor: pointer;
`

CloseIcon.displayName = 'CloseIcon'

const ProgressBar = styled.div`
  border: 1px solid ${styles.colors.primaryColor};
  border-radius: 0 0 4px 4px;
  height: 3px;
  width: 100%;
`

ProgressBar.displayName = 'ProgressBar'

const Progress = styled(motion.div)`
  background-color: ${styles.colors.primaryColor};
  height: 100%;
  width: 0;
`

Progress.displayName = 'Progress'
