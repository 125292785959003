import React from 'react'
import styled, { css } from 'styled-components'

import ConnectedTaskFilters from './ConnectedTaskFilters'
import { PaddingContainer, innerLeftPadding, innerRightPadding, paddingContainerCss } from 'features/layout/pages'

import { Separator, SquareCheckbox, ActiveSeparator } from 'gipsy-ui'
import { styles } from 'gipsy-misc'

const marginTopGroupContainer = 28
export const selectCheckboxLateralPaddingOffset = 26

export const GroupContainer = styled.div`
  margin-top: ${marginTopGroupContainer}px;
  display: flex;
  flex-direction: column;
`

const marginBottomGroupHeader = 16
export const GroupHeader = styled(PaddingContainer)`
  margin-bottom: ${marginBottomGroupHeader}px;
`

export const StyledActiveSeparator = styled(ActiveSeparator)`
  flex: 1;
  font-weight: 500;
`

const SubGroupTitleSeparatorContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 15px;
  margin-bottom: ${marginBottomGroupHeader}px;
  width: 100%;
  ${paddingContainerCss};

  ${({ selectMode, withSelect }) =>
    withSelect &&
    css`
      padding-left: ${innerLeftPadding - selectCheckboxLateralPaddingOffset}px;

      .SubGroupTitleSeparator {
        &__checkbox {
          margin-right: 22px;
          pointer-events: ${selectMode ? 'all' : 'none'};
          visibility: ${selectMode ? 'visible' : 'hidden'};
        }
      }

      :hover {
        .SubGroupTitleSeparator {
          &__checkbox {
            pointer-events: all;
            visibility: visible;
          }
        }
      }
    `}

  ${({ isUnselectable }) =>
    isUnselectable &&
    css`
      .SubGroupTitleSeparator {
        &__checkbox {
          margin-right: 22px;
          pointer-events: none;
          visibility: hidden;
        }
      }

      :hover {
        .SubGroupTitleSeparator {
          &__checkbox {
            pointer-events: none;
            visibility: hidden;
          }
        }
      }
    `}
`

SubGroupTitleSeparatorContainer.displayName = 'SubGroupTitleSeparatorContainer'

export const SubGroupTitleSeparator = ({
  groupSelectedItems,
  groupTotalItems,
  onCheckboxClicked,
  onClick,
  title,
  isExpanded,
  selectMode,
  withSelect,
}) => {
  const handleChange = ({ value }) => {
    onCheckboxClicked(!!value)
  }

  const checked = groupSelectedItems === groupTotalItems ? true : false
  const indeterminate = groupSelectedItems > 0 && groupSelectedItems !== groupTotalItems

  return (
    <SubGroupTitleSeparatorContainer
      isUnselectable={groupTotalItems === 0}
      selectMode={selectMode}
      withSelect={withSelect}>
      {withSelect && (
        <SquareCheckbox
          checked={checked}
          className='SubGroupTitleSeparator__checkbox'
          indeterminate={indeterminate}
          onChange={handleChange}
          size={16}
        />
      )}
      <StyledActiveSeparator
        className='SubGroupTitleSeparator__ActiveSeparator'
        onClick={onClick}
        title={title}
        titleFontSize={11}
        titleColor={styles.colors.textMediumDarkColor}
        isExpanded={isExpanded}
      />
    </SubGroupTitleSeparatorContainer>
  )
}

export const DateGroupHeader = styled(PaddingContainer)`
  color: ${styles.colors.primaryColor};
  font-weight: 600;
  font-size: ${styles.fonts.fontSizeSmall};
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 12px 0 0 0;
  cursor: pointer;
`

export const RightTaskFilters = styled(ConnectedTaskFilters)`
  margin-left: auto;
`

export const SelectRowContainer = styled(PaddingContainer)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const SelectModeSeparator = styled(Separator)`
  margin-bottom: 10px;
  margin-left: ${innerLeftPadding}px;
  margin-right: ${innerRightPadding}px;
`

export const TopSectionContainer = styled.div`
  align-items: center;
  display: flex;
  min-height: ${({ minHeight }) => minHeight || 50}px;
  width: 100%;
`

TopSectionContainer.displayName = 'TopSectionContainer'
