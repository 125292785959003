import moment from 'moment'

import { models, translations, utils } from 'gipsy-misc'

const { DailyScheduleOptions, Schedules } = models.recurrency

export const getRecurrencyLabel = ({ customTypeSettings, recurrencyType, sprintStartTime }) => {
  switch (recurrencyType) {
    case DailyScheduleOptions.NoRepeat: {
      return translations.sprint.recurrency.noRepeat
    }

    case DailyScheduleOptions.Daily: {
      return translations.sprint.recurrency.daily
    }

    case DailyScheduleOptions.WeeklyOnCurrentDay: {
      return translations.sprint.recurrency.weeklyOn.replace('__', sprintStartTime.format('dddd'))
    }

    case DailyScheduleOptions.MonthlyOnCurrentDay: {
      const momentStartTime = moment(sprintStartTime)
      const ordinalNumber = utils.recurrency.details.getOrdinalNumberForStartTime(momentStartTime)
      const dayName = momentStartTime.format('dddd')
      let label = translations.sprint.recurrency.monthlyOn.replace('__', dayName)
      label = label.replace('#', translations.sprint.recurrency.dayNumber[ordinalNumber])
      return label
    }

    case DailyScheduleOptions.EveryWeekday: {
      return translations.sprint.recurrency.everyWeekday
    }

    case DailyScheduleOptions.Custom: {
      if (customTypeSettings?.day) {
        return `${translations.general.monthlyOnThe} ${translations.general.day.singular} ${customTypeSettings?.day}`
      } else if (customTypeSettings?.onOrdinal !== undefined) {
        let scheduleDayName = translations.days.label[customTypeSettings?.onSchedule]
        const [firstLetter, ...restOfDayName] = scheduleDayName
        scheduleDayName = firstLetter.toUpperCase() + restOfDayName.join('')
        let label = translations.sprint.recurrency.monthlyOn.replace('__', scheduleDayName)
        label = label.replace('#', translations.sprint.recurrency.dayNumber[customTypeSettings.onOrdinal])
        return label
      } else {
        return customTypeSettings?.days?.length
          ? utils.recurrency.details.getDaysChosenLabel(customTypeSettings.days)
          : translations.sprint.recurrency.noRepeat
      }
    }

    default: {
      return ''
    }
  }
}

export const getRecurrencyDetails = ({ customTypeSettings, every = 1, recurrencyType, sprintStartTime }) => {
  switch (recurrencyType) {
    case DailyScheduleOptions.NoRepeat: {
      return null
    }

    case DailyScheduleOptions.Daily: {
      return {
        schedule: Schedules.Daily,
        dailyOptions: {
          every,
        },
      }
    }

    case DailyScheduleOptions.WeeklyOnCurrentDay: {
      return {
        schedule: Schedules.Weekly,
        weeklyOptions: {
          days: [Number.parseInt(sprintStartTime.format('d'))],
          every,
        },
      }
    }

    case DailyScheduleOptions.EveryWeekday: {
      return {
        schedule: Schedules.Weekly,
        weeklyOptions: {
          days: [1, 2, 3, 4, 5],
          every,
        },
      }
    }

    case DailyScheduleOptions.MonthlyOnCurrentDay: {
      return {
        monthlyOptions: {
          every,
          day: null,
          onOrdinal: utils.recurrency.details.getOrdinalNumberForStartTime(sprintStartTime),
          onSchedule: sprintStartTime.format('dddd').toLowerCase(),
        },
        schedule: Schedules.Monthly,
      }
    }

    case DailyScheduleOptions.Custom: {
      if (customTypeSettings?.onOrdinal !== undefined) {
        return {
          monthlyOptions: {
            every,
            day: null,
            onOrdinal: customTypeSettings.onOrdinal,
            onSchedule: customTypeSettings.onSchedule,
          },
          schedule: Schedules.Monthly,
        }
      } else if (customTypeSettings?.day) {
        return {
          monthlyOptions: {
            day: customTypeSettings.day,
            every,
          },
          schedule: Schedules.Monthly,
        }
      } else if (customTypeSettings?.days?.length) {
        return {
          schedule: Schedules.Weekly,
          weeklyOptions: {
            days: customTypeSettings.days,
            every,
          },
        }
      }

      return null
    }

    default: {
      return null
    }
  }
}

export const getRecurrencyOptionsFromRecurrencyInformation = (item) => {
  const { recurrencyInformation, when } = item

  if (!recurrencyInformation) {
    return {
      customSettings: null,
      every: 1,
      type: DailyScheduleOptions.NoRepeat,
    }
  }

  const { recurrencyDetails } = recurrencyInformation
  const { schedule, dailyOptions, monthlyOptions, weeklyOptions } = recurrencyDetails

  if (schedule === Schedules.Daily) {
    return {
      customSettings: null,
      every: dailyOptions.every,
      schedule: Schedules.Daily,
      type: DailyScheduleOptions.Daily,
    }
  }

  if (schedule === Schedules.Monthly) {
    return {
      customSettings: monthlyOptions,
      every: monthlyOptions.every,
      schedule: Schedules.Monthly,
      type: DailyScheduleOptions.Custom,
    }
  }

  const { days, every } = weeklyOptions

  if (days.length === 1 && Number.parseInt(moment(when.date).format('d')) === days[0]) {
    return {
      customSettings: null,
      every,
      schedule: Schedules.Weekly,
      type: DailyScheduleOptions.WeeklyOnCurrentDay,
    }
  }

  if (days.length === 5 && utils.integers.areElementsUniqueAndMatch(days, [1, 2, 3, 4, 5])) {
    return {
      customSettings: null,
      every,
      schedule: Schedules.Weekly,
      type: DailyScheduleOptions.EveryWeekday,
    }
  }

  return {
    customSettings: {
      days,
    },
    every,
    schedule: Schedules.Weekly,
    type: DailyScheduleOptions.Custom,
  }
}

export const getMonthlyOptionsForStartTime = (startTime) => {
  const ordinalsForMonth = utils.recurrency.details.getOrdinalNumbersForDayInMonth(startTime)
  const momentStartTime = moment(startTime)
  const dayName = momentStartTime.format('dddd')

  const options = ordinalsForMonth.map((ordinal) => ({
    label: `${translations.sprint.recurrency.dayNumber[ordinal]} ${dayName}`,
    value: {
      onOrdinal: ordinal,
      onSchedule: dayName.toLowerCase(),
    },
  }))

  options.push({
    label: `${translations.general.day.singular} ${momentStartTime.date()}`,
    value: {
      day: momentStartTime.date(),
    },
  })

  return options
}
