import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import noop from 'lodash/noop'

import Checkbox from 'Checkbox'
import FixedTooltip from 'FixedTooltip'
import Icon from 'Icon'
import CalendarEventInfo from 'LineView/components/CalendarEventInfo'
import { LineContainer, LineContentContainer, RepeatIcon, UrlAttribute } from 'LineView/components/commonUIComponents'
import { CustomPopup, PopupItem } from 'Popup/components'
import AsanaProjectsAttribute from 'LineComponents/AsanaProjectsAttribute'
import UrlsAttribute from 'LineComponents/UrlsAttribute'
import SideIconContainer from 'LineComponents/SideIconContainer'
import PlayIcon from 'PlayIcon'

import { constants, styles, translations, utils, variables } from 'gipsy-misc'

const dragIconSize = 16
const moreIconSize = 16
const moreIconRightPosition = -25
const dragIconLeftPosition = -25
const completionDuration = 0.3

export default function LineViewCompressed({
  animateComplete,
  draggableProps,
  draggableStyle,
  dragHandleProps,
  editStart,
  endEditing,
  hideDragIcon,
  hideMoreActions,
  ignoreOutsideClicks,
  innerLeftPadding,
  innerRef,
  innerRightPadding,
  isCalendarDraggable,
  isDraggable,
  isDragging,
  isSprintTask,
  item,
  keepJustCompleted,
  lineThrough,
  onComplete: _onComplete,
  onDelete,
  onRemoveFromSprint,
  onSelect,
  onStartFocus,
  selected,
  selectMode,
  session,
  showCalendarEventInfo,
}) {
  const [isHovered, setHovered] = useState(false)
  const [justCompleted, setJustCompleted] = useState(false)
  const [showActionsPopup, setShowActionsPopup] = useState(false)
  const [startButtonHovered, setStartButtonHovered] = useState(false)

  const actionsPopupRef = useRef(null)
  const moreButtonRef = useRef(null)
  const playIconRef = useRef(null)

  useEffect(() => {
    if (item.completed && justCompleted) {
      setJustCompleted(false)
    }
  }, [item.completed, justCompleted])

  const onMouseEnter = () => {
    setHovered(true)
  }

  const onMouseLeave = () => {
    setHovered(false)
  }

  const onClickMoreActionsButton = () => {
    setShowActionsPopup(!showActionsPopup)
  }

  const onPlayHovered = () => {
    setStartButtonHovered(true)
  }

  const onPlayUnhovered = () => {
    setStartButtonHovered(false)
  }

  const onClickStartButton = (e) => {
    const urlsToOpen = (item.urlsInfo || []).map((urlInfoItem) => urlInfoItem.url)
    onStartFocus({ urlsToOpen })
  }

  const onComplete = () => {
    const itemData = {
      id: item.id,
      paramName: 'completed',
      value: 1,
    }

    if (animateComplete) {
      setJustCompleted(true)
      endEditing?.()
      return setTimeout(() => {
        _onComplete(itemData)
        if (!keepJustCompleted) {
          setJustCompleted(false)
        }
      }, completionDuration * 1000)
    }

    return _onComplete(itemData)
  }

  const handleAttributesContainerClicked = (e) => {
    if ((utils.os.isMac() && e.metaKey) || (!utils.os.isMac() && e.ctrlKey)) {
      onSelect?.(item.id)
    } else if (e.shiftKey) {
      document.getSelection().removeAllRanges()
      onSelect?.(item.id, true)
    } else {
      editStart()
    }
  }

  const handlePopupAction = (action, ...params) => {
    action(...params)
    setShowActionsPopup(false)
  }

  useEffect(() => {
    const handleAllClickOutside = (e) => {
      if (ignoreOutsideClicks) return

      if (
        !(moreButtonRef.current && moreButtonRef.current.contains(e.target)) &&
        !(
          actionsPopupRef.current &&
          (actionsPopupRef.current.contains(e.target) || actionsPopupRef.current === e.target)
        )
      ) {
        setShowActionsPopup(false)
      }
    }

    document.addEventListener('mousedown', handleAllClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleAllClickOutside)
    }
  }, [ignoreOutsideClicks])

  const focusedTaskId = session?.focusSession?.taskId
  const isTaskOnFocusSession = focusedTaskId === item.id
  const isStartButtonDisabled = (!!focusedTaskId && !isTaskOnFocusSession) || justCompleted

  const showMoreActionsButton = !hideMoreActions && !isTaskOnFocusSession && onDelete

  const lineContainerClasses = `line-container fs-mask ${isCalendarDraggable ? 'calendar-draggable' : ''} ${
    isDragging ? 'dragging' : ''
  }`
  const isPastItem = utils.task.isPast(item)

  return (
    <StyledLineContainer
      className={lineContainerClasses}
      data-itemid={item.id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      paddingLeft={innerLeftPadding}
      paddingRight={innerRightPadding}
      ref={innerRef}
      {...draggableProps}
      style={draggableStyle}
      {...dragHandleProps}>
      {!hideDragIcon && (
        <DragIconContainer
          className={'drag-icon-container'}
          innerLeftPadding={innerLeftPadding}
          shown={isDraggable && isHovered}>
          <Icon icon='DragHandle' size={dragIconSize} />
        </DragIconContainer>
      )}
      {showCalendarEventInfo && !(isDraggable && isHovered) && (
        <CalendarEventInfo
          pin={item.pin}
          estimatedTime={item.estimatedTime}
          date={item.when?.date}
          isPast={isPastItem}
        />
      )}
      <CompressedContainer
        isDraggable={isDraggable}
        isDragging={isDragging}
        isSprintTask={isSprintTask}
        selected={selected}>
        <Controls
          onClick={!focusedTaskId && startButtonHovered ? onClickStartButton : noop}
          onMouseLeave={onPlayUnhovered}>
          {selected || selectMode ? (
            <PlayControlsPlaceholder />
          ) : (
            <>
              <PlayIconPlaceholder disabled={isStartButtonDisabled} />
              <StartControlBackground
                animate={{ width: !isStartButtonDisabled && startButtonHovered ? '100%' : '0%' }}
                isSprintTask={isSprintTask}
                transition={{ duration: 0.3 }}
              />
              <PlayIconContainer
                animate={{ x: startButtonHovered ? -8 : 0 }}
                onMouseEnter={onPlayHovered}
                ref={playIconRef}>
                {!isStartButtonDisabled && (
                  <PlayIcon
                    fillColor={isSprintTask ? styles.colors.orangeColor : styles.colors.primaryColor}
                    playIconBackground={isSprintTask && styles.colors.lightOrangeColor}
                  />
                )}
              </PlayIconContainer>
              <StartLabel
                animate={startButtonHovered ? 'entering' : 'leaving'}
                variants={{
                  entering: {
                    opacity: 1,
                  },
                  leaving: { opacity: 0 },
                }}>
                {translations.general.start}
              </StartLabel>
            </>
          )}
          <Checkbox
            id={item.id}
            borderWidth={1.5}
            checked={!!item.completed || justCompleted}
            checkmarkBorderWidth={1.5}
            checkmarkHeight={6}
            checkmarkLeft={4.5}
            checkmarkTop={1.2}
            checkmarkWidth={3}
            onChange={onComplete}
            paramName='completed'
            hide={isTaskOnFocusSession ? 1 : 0}
            size={16}
            checkedColor={styles.colors.middleGrey}
            checkMarkColor={styles.colors.darkGrey}
            hoverCheckMarkColor={styles.colors.darkGrey}
            hoverBackgroundColor={'white'}
            hoverBorderColor={styles.colors.darkGrey}
            borderColor={styles.colors.darkGrey}
          />
        </Controls>
        <AttributesContainer onClick={handleAttributesContainerClicked}>
          <Title>
            {item.recurrencyInformation && (
              <FixedTooltip
                content={utils.recurrency.details.getRecurrencyLabel(item.recurrencyInformation?.recurrencyDetails)}
                portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
                style={{ padding: '8px' }}>
                <RepeatIcon fill={styles.colors.textMediumDarkColor} icon='Repeat' size={10} />
              </FixedTooltip>
            )}
            <TitleText justCompleted={lineThrough && justCompleted}>
              {item.when?.date && isPastItem ? '☠️ ' : ''}
              {item.title}
            </TitleText>
          </Title>
          <Links>
            <UrlsAttribute urlsInfo={item.urlsInfo} stopPropagationOnClick={true} />
          </Links>
          <ProjectCell>
            <AsanaProjectsAttribute
              stopPropagationOnClick={true}
              projects={item.projects}
              asanaWorkspaceName={!!item.asanaInfo && item.asanaInfo.workspaceName}
            />
          </ProjectCell>
        </AttributesContainer>
      </CompressedContainer>
      {showMoreActionsButton && (
        <MoreIconContainer
          innerRightPadding={innerRightPadding}
          onClick={onClickMoreActionsButton}
          ref={moreButtonRef}
          shown={isHovered}>
          <Icon icon='More' size={moreIconSize} />
        </MoreIconContainer>
      )}
      {showMoreActionsButton && showActionsPopup && (
        <CustomPopup
          left={`calc(100% - ${innerRightPadding + moreIconRightPosition + 3}px)`}
          ref={actionsPopupRef}
          top={'30px'}>
          {onSelect && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => handlePopupAction(onSelect, item.id)}>
              <span>{selected ? translations.general.unselect : translations.general.select}</span>
            </PopupItem>
          )}
          {onRemoveFromSprint && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => handlePopupAction(onRemoveFromSprint, item)}>
              <span>{translations.general.remove}</span>
            </PopupItem>
          )}
          {onDelete && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => handlePopupAction(onDelete, item.id)}>
              <span>{translations.general.delete}</span>
            </PopupItem>
          )}
        </CustomPopup>
      )}
    </StyledLineContainer>
  )
}

const StyledLineContainer = styled(LineContainer)`
  margin-bottom: 8px;
`

const CompressedContainer = styled(LineContentContainer)`
  align-items: center;
  cursor: ${({ isDraggable }) => (isDraggable ? 'grab' : 'default')};
  display: grid;
  grid-template-columns: 75px auto;
  padding-right: 16px;
  position: relative;

  ${({ isDragging }) =>
    isDragging &&
    css`
      box-shadow: ${styles.shadows.popup};
      z-index: ${variables.zIndex.taskDragged};
    `};
`
const AttributesContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 3fr 2fr 230px;
  position: relative;
`

const Controls = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 31px 16px;
  padding: 10px 12px 10px 16px;
  position: relative;
`

const PlayControlsPlaceholder = styled.div`
  width: 52px;
`

PlayControlsPlaceholder.displayName = 'PlayControlsPlaceholder'

const PlayIconPlaceholder = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 100%;
`

const PlayIconContainer = styled(motion.div)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  padding: 10px 0 10px 16px;
  position: absolute;
  z-index: 1;
`

const StartControlBackground = styled(motion.div)`
  background-color: ${({ isSprintTask }) => (isSprintTask ? styles.colors.orangeColor : styles.colors.primaryColor)};
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  height: 100%;
  left: 0;
  max-width: calc(100% - 4px);
  padding: 10px 0px;
  position: absolute;
  width: 0;
  z-index: 1;
`

const StartLabel = styled(motion.span)`
  color: white;
  display: block;
  font-size: 13px;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

const Title = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin-right: 10px;
  min-width: 0;
`

const TitleText = styled.span`
  display: block;
  font-size: 14px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:after {
    background-color: ${styles.colors.textMediumDarkColor};
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: calc(50% + 1px);
    transition: width ${completionDuration}s ease;
    width: 0;
  }

  ${({ justCompleted }) =>
    justCompleted &&
    css`
      &:after {
        width: 100%;
      }
    `}
`

const Links = styled.div`
  color: ${styles.colors.darkGrey};
  font-size: 12px;
  margin-right: 10px;
  min-width: 0;

  ${UrlAttribute} {
    cursor: pointer;
    max-width: 100%;
    width: auto;
  }
`

const ProjectCell = styled.div`
  display: flex;
  justify-content: flex-end;
`
const DragIconContainer = styled(SideIconContainer)`
  top: calc(50% - ${dragIconSize / 2}px);
  left: ${(props) => `${props.innerLeftPadding + dragIconLeftPosition}px`};
  cursor: ${({ shown }) => (shown ? 'grab' : 'auto')};
`

const MoreIconContainer = styled(SideIconContainer)`
  top: calc(50% - ${moreIconSize / 2}px);
  right: ${(props) => `${props.innerRightPadding + moreIconRightPosition}px`};
  cursor: ${({ shown }) => (shown ? 'pointer' : 'auto')};
`
