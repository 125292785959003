import { models, utils } from 'gipsy-misc'
import moment from 'moment'

import { TaskObjectGroups } from 'logic/today/models'

const isDateEndOfWeek = (now) => {
  const dayOfWeek = now.day()
  return dayOfWeek === 6
}

const getBegOfMonthSection = (now) => {
  let begOfMonthSection
  isDateEndOfWeek(now)
    ? (begOfMonthSection = now.clone().add(8, 'day'))
    : (begOfMonthSection = now.clone().day('Saturday').add(1, 'day'))

  return begOfMonthSection
}

export const computeLastDayOfWeekSection = (now) => {
  return getBegOfMonthSection(now).subtract(1, 'day').format('YYYY-MM-DD')
}

const isRankLower = (item1, item2) => {
  const item1HasRank = Number.isInteger(item1?.todaySectionRank)
  const item2HasRank = Number.isInteger(item2?.todaySectionRank)

  switch (true) {
    case item1HasRank && item2HasRank: {
      return item1.todaySectionRank < item2.todaySectionRank
    }

    case item1HasRank && !item2HasRank: {
      return true
    }

    case !item1HasRank && item2HasRank: {
      return false
    }

    default: {
      return moment(item1.creationTime).isBefore(moment(item2.creationTime))
    }
  }
}

export const sortListByTodayRank = (list) => utils.list.sortListByConditionFn(list, (a, b) => !isRankLower(a, b))

export const groupPositionMap = {
  [TaskObjectGroups.PINNED]: 0,
  [TaskObjectGroups.OTHER]: 1,
  [TaskObjectGroups.COMPLETED]: 2,
}

export const getScheduledGroup = (item) => {
  if (item.completionTime) {
    if (!utils.datetime.isToday(item.completionTime) || item.type !== models.item.type.TASK) return null

    return TaskObjectGroups.COMPLETED
  } else if (!item.completionTime) {
    return item.pin?.time ? TaskObjectGroups.PINNED : TaskObjectGroups.OTHER
  }

  return null
}
