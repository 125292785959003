import React from 'react'
import styled, { css } from 'styled-components'
import parser from 'react-html-parser'

import { styles, translations } from 'gipsy-misc'

import breakpoints, { verticalBreakpoints } from 'features/layout/breakpoints'
import { FeatureItem } from 'pages/login/components/FeaturesColumn'
import { timeBlockingExpVariants as variants } from 'pages/login/container'

import { TimeBlockingVariantComponent } from './Header'

interface Props {
  variant: string
}

export default function Features({ variant = variants.TIME_BLOCKING }: Props) {
  return (
    <Container className='Features' variant={variant}>
      <Title className='Features__title' variant={variant}>
        {parser(translations.timeBlockingExp[variant].features.title)}
      </Title>
      <FeaturesContainer className='Features__features-container' variant={variant}>
        {translations.timeBlockingExp[variant].features.items.map((item, index) => (
          <FeatureItem key={index} text={item} />
        ))}
      </FeaturesContainer>
    </Container>
  )
}

const Container = styled.section<TimeBlockingVariantComponent>`
  background-color: white;
  display: flex;
  flex-flow: column;
  height: 26%;
  min-height: 208px;
  justify-content: center;
  padding: 0 20px;

  @media (min-width: ${breakpoints.desktopLarge}px) {
    min-height: 256px;
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      height: 32%;
      min-height: 272px;

      @media (min-width: ${breakpoints.desktopLarge}px) {
        min-height: 272px;
      }
    `}
`

Container.displayName = 'Container'

const Title = styled.h2<TimeBlockingVariantComponent>`
  &.Features__title {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    margin: 18px auto 10px;
    text-align: center;
    width: auto;

    strong {
      font-weight: 500;
    }
  }

  @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
    &.Features__title {
      margin-top: 22px;
    }
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    &.Features__title {
      font-size: 22px;
      margin: 26px auto 33px;
    }
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      &.Features__title {
        font-size: 20px;
        margin: 29px auto 10px;
      }

      strong {
        color: ${styles.colors.primaryColor};
      }

      @media (min-height: ${verticalBreakpoints.phoneLarge}px) {
        &.Features__title {
          margin-top: 29px;
        }
      }

      @media (min-width: ${breakpoints.desktopLarge}px) {
        &.Features__title {
          font-size: 20px;
          margin: 29px auto 10px;
        }
      }
    `}
`

Title.displayName = 'Title'

const FeaturesContainer = styled.div<TimeBlockingVariantComponent>`
  margin: 0 auto 14px;

  .FeatureItem {
    margin-bottom: 0;

    &__icon {
      margin-right: 12px;
    }

    &__text {
      font-size: 18px;
      line-height: 33px;
    }
  }

  @media (min-width: ${breakpoints.desktopLarge}px) {
    margin: 0 auto 16px;

    .FeatureItem {
      &__icon {
        margin-right: 14px;
      }

      &__text {
        font-size: 22px;
      }
    }
  }

  ${({ variant }) =>
    variant === variants.PRODUCTIVITY &&
    css`
      margin: 0 auto 19px;

      .FeatureItem {
        margin-bottom: 0;

        &__icon {
          margin-right: 12px;
        }

        &__text {
          font-size: 20px;
        }
      }

      @media (min-width: ${breakpoints.desktopLarge}px) {
        margin: 0 auto 19px;

        .FeatureItem {
          margin-bottom: 0;

          &__icon {
            margin-right: 12px;
          }

          &__text {
            font-size: 20px;
          }
        }
      }
    `}
`

FeaturesContainer.displayName = 'FeaturesContainer'
