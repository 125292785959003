import React, { PureComponent } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import qs from 'qs'

import { clearUTMInfoFromLocalStorage, writeUTMInfoToLocalStorage } from 'logic/login'
import { session } from 'gipsy-api'

import TimeBlockingExp from './components/TimeBlockingExp'

const mapStateToProps = (state) => ({
  session: state.session,
})

export const timeBlockingExpVariants = {
  PRODUCTIVITY: 'productivity',
  TIME_BLOCKING: 'timeBlocking',
}

export default function wrapper(Component) {
  class LoginPageContainer extends PureComponent {
    state = {
      multiCalendarSyncExp: false,
      redirect: null,
      timeBlockingExp: null,
    }

    componentDidMount = () => {
      const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      clearUTMInfoFromLocalStorage()
      writeUTMInfoToLocalStorage(searchParams)

      if (searchParams?.timeBlockingExpA === 'true' || searchParams?.utm_content === '135324468688') {
        this.setState({ timeBlockingExp: timeBlockingExpVariants.TIME_BLOCKING })
        return
      }

      if (searchParams?.timeBlockingExpB === 'true' || searchParams?.utm_content === '131314378939') {
        this.setState({ timeBlockingExp: timeBlockingExpVariants.PRODUCTIVITY })
        return
      }

      if (searchParams?.utm_content === '125875786299') {
        this.setState({ multiCalendarSyncExp: true })
      }
    }
    authenticateGoogleWithElectron = async () => {
      window.sessionStorage.setItem('stateOAuth-google', 'isgoogle')
      window.sessionStorage.setItem('nonceOAuth', '')
      const remote = window.require('electron').remote
      const mainProcess = remote.require('./main.js')
      try {
        const result = await mainProcess.googleSignin({
          clientId: process.env.REACT_APP_ELECTRON_GOOGLE_CLIENT_ID,
          authorizationUrl: process.env.REACT_APP_GOOGLE_AUTHORIZATION_URL,
          tokenUrl: process.env.REACT_APP_GOOGLE_TOKEN_URL,
          useBasicAuthorizationHeader: false,
          redirectUri: process.env.REACT_APP_ELECTRON_REDIRECT_URI,
        })

        const redirect = `/oauthcallback?state=isgoogle&code=${result.authorizationCode}&redirectUri=${process.env.REACT_APP_ELECTRON_REDIRECT_URI}`
        this.setState({ redirect })
      } catch (err) {
        console.error(err)
      }
    }
    goToGoogleAuth = () => {
      if (process.env.REACT_APP_ELECTRON === 'true') {
        this.authenticateGoogleWithElectron()
      } else {
        session.getAuthURL('google').then((data) => {
          window.sessionStorage.setItem('stateOAuth-google', data.state)
          window.sessionStorage.setItem('nonceOAuth', data.nonce)
          window.location.href = data.authURL
        })
      }
    }

    goToMicrosoftAuth = () => {
      session.getAuthURL('microsoft').then((data) => {
        window.sessionStorage.setItem('stateOAuth-microsoft', data.state)
        window.sessionStorage.setItem('nonceOAuth', data.nonce)
        window.location.href = data.authURL
      })
    }

    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }

      if (this.state.timeBlockingExp) {
        return (
          <TimeBlockingExp
            goToGoogleAuth={this.goToGoogleAuth}
            goToMicrosoftAuth={this.goToMicrosoftAuth}
            variant={this.state.timeBlockingExp}
          />
        )
      }

      return (
        <Component
          accountDeleted={this.props.session.accountDeleted}
          multiCalendarSyncExp={this.state.multiCalendarSyncExp}
          goToGoogleAuth={this.goToGoogleAuth}
          goToMicrosoftAuth={this.goToMicrosoftAuth}
        />
      )
    }
  }

  const containerRedux = connect(mapStateToProps, null, null, {
    pure: true,
  })(LoginPageContainer)

  return containerRedux
}
