const mixpanel = {
  closedTabsEvent: 'closedTabs',
  usedShortcut: 'usedShortcut',
  navigatedEvent: 'navigated',
  clickedAllTasksEvent: 'Clicked All Tasks',
  clickedTimeReportsEvent: 'Clicked Time Reports',
  clickedWatchYoutubeVideosEvent: 'clickedWatchYoutubeVideos',
  suggestionPopupShownEvent: 'suggestionPopupShown',
  reminderPopupShownEvent: 'reminderPopupShown',
  onboardingFocusSessionStartEvent: 'onboardingFocusSessionStart',
  onboardingCalendarSlotSelectedEvent: 'onboardingCalendarSlotSelected',
  onboardingCalendarCreateBlockEvent: 'onboardingCalendarCreateBlock',
  onboardingCalendarRecurrenceSelectedEvent: 'onboardingCalendarRecurrenceSelected',
  onboardingCalendarHelperContinueEvent: 'onboardingCalendarHelperContinue',
  onboardingVideoPromptActionEvent: 'onboardingVideoPromptAction',
  onboardingVideoContinueClickedEvent: 'onboardingVideoContinueClicked',
  onboardingFirstChromePromptActionEvent: 'onboardingFirstChromePromptAction',
  onboardingFirstTimeCalendarLandedEvent: 'onboardingFirstTimeCalendarLanded',
  daysTasksClickedEvent: 'daysTasksClicked',
  allCalendarsPanelExpandedEvent: 'allCalendarsPanelExpanded',
  disconnectAccountClickedEvent: 'disconnectAccountClicked',
  addAccountClickedEvent: 'addAccountClicked',
  multiCalendarSyncClickedEvent: 'multiCalendarSyncClicked',
  onboardingAboutYourselfNextClickedEvent: 'onboardingAboutYourselfNextClicked',
  onboardingMainAccountAddedAddAccountClickedEvent: 'onboardingMainAccountAddedAddAccountClicked',
  onboardingMainAccountAddedDontSyncClickedEvent: 'onboardingMainAccountAddedDontSyncClicked',
  onboardingGrantAccessClickedEvent: 'onboardingGrantAccessClicked',
  onboardingCongratsStartSyncClickedEvent: 'onboardingCongratsStartSyncClicked',
  onboardingCongratsAddAccountClickedEvent: 'onboardingCongratsAddAccountClicked',
  onboardingStartSyncEvent: 'onboardingStartSync',
  onboardingCongratsDontSyncClickedEvent: 'onboardingCongratsDontSyncClicked',
  onboardingSyncLoadingDoneEvent: 'onboardingSyncLoadingDone',
  onboardingMulticalendarFlowDoneEvent: 'onboardingMulticalendarFlowDone',
  onboardingMulticalendarPromptEvent: 'onboardingMulticalendarPrompt',
  onboardingMasterTimeBlockingEvent: 'onboardingMasterTimeBlocking',
  onboardingCalendarItemCreatedEvent: 'onboardingCalendarItemCreated',
  onboardingFocusBlockPromptEvent: 'onboardingFocusBlockPrompt',
  onboardingAddTaskToFocusBlockClickedEvent: 'onboardingAddTaskToFocusBlockClicked',
  onboardingSaveFocusBlockEvent: 'onboardingSaveFocusBlock',
  onboardingRecurrenceSelectedEvent: 'onboardingRecurrenceSelected',
  onboardingCalendarPermissionPromptEvent: 'onboardingCalendarPermissionPrompt',
  deleteBookTimeClickedEvent: 'deleteBookTimeClicked',
  performedBatchActionEvent: 'performedBatchAction',
}

export default mixpanel
