import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'

import { Dropdown, DropdownCaret, DropdownTrigger, Label } from './commonUIComponents'
import { TypePreset as DropdownOption } from './RecurrencePresetsPopup'

export default function MonthlyOptionsDropdown({ onChange, options, optionSelected, shrinked }) {
  const [dropdownShown, setDropdownShown] = useState(false)

  const handleChange = (e, option) => {
    e.stopPropagation()
    setDropdownShown(false)
    onChange(option)
  }

  return (
    <Container className='MonthlyOptionsDropdown' shrinked={shrinked}>
      <StyledLabel className='MonthlyOptionsDropdown__label'>{translations.general.monthlyOnThe}</StyledLabel>
      <StyledDropdownTrigger
        className='MonthlyOptionsDropdown__dropdown-trigger'
        onClick={() => setDropdownShown(true)}>
        {optionSelected?.label}
        <DropdownCaret icon='CaretDown' fill={styles.colors.textMediumDarkColor} size={10} />
        {dropdownShown && (
          <Dropdown className='MonthlyOptionsDropdown__dropdown' onClickOutside={() => setDropdownShown(false)}>
            {options.map((option, index) => (
              <DropdownOption
                key={index}
                onClick={(e) => handleChange(e, option)}
                selected={option === optionSelected}
                text={option.label}
              />
            ))}
          </Dropdown>
        )}
      </StyledDropdownTrigger>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 12px;

  ${({ shrinked }) =>
    shrinked &&
    css`
      .MonthlyOptionsDropdown__label {
        font-size: 12px;
      }

      .MonthlyOptionsDropdown__dropdown-trigger {
        font-size: 12px;
        min-width: 153px;
        padding: 0 6px;
      }

      .MonthlyOptionsDropdown__dropdown {
        left: unset;
        right: 0;
      }
    `}
`

Container.displayName = 'Container'

const StyledLabel = styled(Label)`
  margin-right: 14px;
`

StyledLabel.displayName = 'StyledLabel'

const StyledDropdownTrigger = styled(DropdownTrigger)`
  min-width: 186px;
`

StyledDropdownTrigger.displayName = 'StyledDropdownTrigger'
