import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { models, styles, translations, utils } from 'gipsy-misc'
import { Project, Sprint, Tag, Task } from 'gipsy-misc/types'
import { Icon } from 'gipsy-ui'

import variables from 'assets/styles/variables.js'
import breakpoints from 'features/layout/breakpoints'
import { innerLeftPadding, innerRightPadding } from 'features/layout/pages'
import { IndeterminateStateMap } from 'logic/batchActions'
import { hideBatchActions } from 'store/batchActions/actions'
import { getFindItemByIdFn } from 'store/items/selectors'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

import Button from './components/Button'
import ProjectsButton from './components/ProjectsButton'
import ScheduleButton from './components/ScheduleButton'
import SprintsButton from './components/SprintsButton'
import TagsButton from './components/TagsButton'

const { calendarPanelWidth, headerNavbarHeight, navbarWidth } = variables

export const batchActionTypes = {
  ADD_TO_FB: 'Add to FB',
  COMPLETE: 'Complete',
  DELETE: 'Delete',
  SCHEDULE: 'Schedule',
  SELECT_PROJECT: 'Select Project',
  SELECT_TAG: 'Select Tag',
}

interface Props {
  onComplete?: () => void
  onDelete?: () => void
  onFocusBlockSelected?: (sprintSelected: Sprint) => void
  onProjectsSelected?: (projectsSelected: Project[], indeterminateIds: IndeterminateStateMap) => void
  onScheduleSelected?: (dateSelected: string) => void
  onTagsSelected?: (tagsSelected: Tag[], indeterminateIds: IndeterminateStateMap) => void
  windowWidth: number
}

const componentName = 'BatchActionsBar'

export default function BatchActionsBar({
  onComplete,
  onDelete,
  onFocusBlockSelected,
  onProjectsSelected,
  onScheduleSelected,
  onTagsSelected,
  windowWidth,
}: Props) {
  const dispatch = useDispatch()
  const activeProjects: Project[] = useSelector((state) => state.projects.items)
  const activeTags: Tag[] = useSelector((state) => state.tags.items)
  const batchItemsIds: string[] = useSelector((state) => state.batchActions.batchItemsIds)
  const findItemById: (id: string) => Sprint | Task | undefined = useSelector((state) => getFindItemByIdFn(state.items))

  const currentSelectionStats = useMemo(() => {
    const stats = {
      tasksPerProject: {},
      tasksPerTag: {},
      totalTasks: 0,
    }

    activeProjects.forEach((p) => {
      stats.tasksPerProject[p.id] = 0
    })

    activeTags.forEach((t) => {
      stats.tasksPerTag[t.id] = 0
    })

    batchItemsIds.forEach((id) => {
      const item = findItemById(id)

      if (!item || item.type === models.item.type.SPRINT) return

      const task = item as Task
      stats.totalTasks += 1

      task.projects?.forEach?.((p) => {
        stats.tasksPerProject[p.id] += 1
      })

      task.tags?.forEach?.((t) => {
        stats.tasksPerTag[t.id] += 1
      })
    })

    return stats
  }, [activeProjects, activeTags, batchItemsIds, findItemById])

  useEffect(() => {
    const nonContiguousShortcut = {
      key: 'click',
      label: translations.batchActions.selectNonContiguous,
      callback: () => {},
      cmdKey: false,
      ctrlKey: false,
    }

    if (utils.os.isMac()) {
      nonContiguousShortcut.cmdKey = true
    } else {
      nonContiguousShortcut.ctrlKey = true
    }

    dispatch(
      pushShortcutsGroup(
        [
          {
            key: 'Escape',
            label: translations.batchActions.endMode,
            callback: () => {
              dispatch(hideBatchActions())
            },
          },
          nonContiguousShortcut,
          {
            key: 'click',
            label: translations.batchActions.selectContiguous,
            shiftKey: true,
            callback: () => {},
          },
        ],
        componentName
      )
    )

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  const shrinked = windowWidth < 1400

  return (
    <Container className='BatchActionsBar'>
      <Content className='BatchActionsBar__content' shrinked={shrinked}>
        {onScheduleSelected && <ScheduleButton onSelect={onScheduleSelected} shrinked={shrinked} />}
        {onTagsSelected && (
          <TagsButton
            activeTags={activeTags}
            onSelect={onTagsSelected}
            selectedItemsPerTag={currentSelectionStats.tasksPerTag}
            shrinked={shrinked}
            totalSelectedItems={currentSelectionStats.totalTasks}
          />
        )}
        {onProjectsSelected && (
          <ProjectsButton
            activeProjects={activeProjects}
            onSelect={onProjectsSelected}
            selectedItemsPerProject={currentSelectionStats.tasksPerProject}
            shrinked={shrinked}
            totalSelectedItems={currentSelectionStats.totalTasks}
          />
        )}
        {onFocusBlockSelected && <SprintsButton onSelect={onFocusBlockSelected} shrinked={shrinked} />}
        {onComplete && (
          <Button
            activeColor={styles.colors.greenPrimaryColor}
            icon='RegularCheckRound'
            onClick={onComplete}
            shrinked={shrinked}
            text={translations.general.complete}
          />
        )}
        {onDelete && (
          <Button
            activeColor={styles.colors.pinkColor}
            fillIcon={false}
            icon='TrashCan'
            onClick={onDelete}
            shrinked={shrinked}
            strokeIcon
            text={translations.general.delete}
          />
        )}
        <CloseIcon
          icon='Close'
          fill={styles.colors.primaryColor}
          onClick={() => dispatch(hideBatchActions())}
          size={12}
        />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${styles.colors.batchActionsBarColor};
  border: 1px solid transparent;
  border-bottom-color: ${styles.colors.lightVioletBorderColor};
  border-top-color: ${styles.colors.batchActionsBarColor};
  height: ${headerNavbarHeight}px;
  padding: 0;
  width: 100%;

  @media (min-width: ${breakpoints.tabletLarge}px) {
    padding-left: ${navbarWidth}px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding-right: ${calendarPanelWidth}px;

    .BatchActionsBar__content {
      padding: 11px ${innerRightPadding - 8}px 11px ${innerLeftPadding - 8}px;
    }
  }
`

Container.displayName = 'Container'

const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 32px;
`

CloseIcon.displayName = 'CloseIcon'

const Content = styled.div<{ shrinked: boolean }>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 900px;
  padding: 11px ${innerRightPadding + 8}px 11px ${innerLeftPadding + 8}px;
  width: 100%;

  ${({ shrinked }) =>
    shrinked &&
    css`
      justify-content: center;

      & > :first-child {
        margin-left: auto;
      }

      ${CloseIcon} {
        margin-left: auto;
      }
    `}
`

Content.displayName = 'Content'
