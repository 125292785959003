import styled, { css } from 'styled-components'

import { styles } from 'gipsy-misc'
import { CustomPopup } from 'gipsy-ui'

export const ButtonContainer = styled.div<{
  active?: boolean
  activeColor?: string
  fillIcon?: boolean
  strokeIcon?: boolean
}>`
  background-color: white;
  border: 1px solid ${styles.colors.darkGrey};
  border-radius: 8px;
  color: ${styles.colors.textMediumDarkColor};
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  user-select: none;

  & .ButtonIcon {
    margin-right: 6px;

    &--shrinked {
      margin-right: 0;
    }

    path {
      fill: ${({ fillIcon }) => (fillIcon ? styles.colors.textMediumDarkColor : 'none')};
      stroke: ${({ strokeIcon }) => (strokeIcon ? styles.colors.textMediumDarkColor : 'none')};
    }
  }

  ${({ activeColor = styles.colors.primaryColor, fillIcon, strokeIcon }) => css`
    :hover {
      border-color: ${activeColor};
      color: ${activeColor};

      & .ButtonIcon {
        path {
          fill: ${fillIcon ? activeColor : 'none'};
          stroke: ${strokeIcon ? activeColor : 'none'};
        }
      }
    }
  `}

  ${({ active, activeColor = styles.colors.primaryColor, fillIcon, strokeIcon }) =>
    active &&
    css`
      border-color: ${activeColor};
      color: ${activeColor};

      & .ButtonIcon {
        path {
          fill: ${fillIcon ? activeColor : 'none'};
          stroke: ${strokeIcon ? activeColor : 'none'};
        }
      }
    `}
`

ButtonContainer.displayName = 'ButtonContainer'

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 13px;
  padding: 9px;
`

ButtonContent.displayName = 'ButtonContent'

export const ButtonPopup = styled(CustomPopup)`
  position: absolute;
  top: calc(100% + 3px);
`

ButtonPopup.displayName = 'ButtonPopup'
