import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

import FixedTooltip from 'FixedTooltip'
import Icon from 'Icon'

import { constants, models, translations, styles, utils } from 'gipsy-misc'

const calendarItemFontSize = 11

const getIconProps = (extendedProps, isPast) => {
  const { item } = extendedProps

  const iconProps = []

  if (extendedProps.isSprint || item.type === models.item.type.SPRINT) {
    if (item.completionTime) {
      iconProps.push({
        icon: 'CheckSquare',
        fill: styles.colors.greenPrimaryColor,
        size: calendarItemFontSize,
      })
    } else if (isPast) {
      iconProps.push({ emoji: '☠️' })
    }

    iconProps.push({
      icon: 'Sprint',
      fill: isPast ? '#ffffff66' : '#fff',
    })
    if (!!item.recurrencyInformation) {
      iconProps.push({
        icon: 'Repeat',
        fill: isPast ? '#ffffff66' : '#fff',
        tooltip: utils.recurrency.details.getRecurrencyLabel(item.recurrencyInformation?.recurrencyDetails),
      })
    }
    return iconProps
  } else if (item.type === models.item.type.TASK) {
    if (item.completed) {
      iconProps.push({
        icon: 'CheckSquare',
        fill: styles.colors.greenPrimaryColor,
        size: calendarItemFontSize,
      })
    } else {
      if (isPast) {
        iconProps.push({ emoji: '☠️' })
      } else if (!extendedProps.isHighlighted) {
        iconProps.push({
          icon: 'Pin',
          fill: styles.colors.pinkColor,
        })
      }
    }

    if (!!item.recurrencyInformation) {
      iconProps.push({
        icon: 'Repeat',
        fill: isPast ? '#4d437566' : '#4d4375',
        tooltip: utils.recurrency.details.getRecurrencyLabel(item.recurrencyInformation?.recurrencyDetails),
      })
    }

    return iconProps
  } else if (item.type === models.item.type.FOCUSSESSION) {
    if (item.mode === models.focussession.CalendarMode.COMPLETED) {
      iconProps.push({
        icon: 'CheckSquare',
        fill: styles.colors.greenPrimaryColor,
        size: calendarItemFontSize,
      })
    } else {
      iconProps.push({ emoji: '⏱️' })
    }
    return iconProps
  }
}

const getHighlightedWrapperProps = (item, estimatedTimeMin) => {
  const iconSize = estimatedTimeMin > 29 ? 13 : 9
  let iconFillColor
  let backgroundColor
  if (item.type === models.item.type.SPRINT) {
    iconFillColor = styles.colors.orangeColor
    backgroundColor = '#edd0ba'
  } else {
    iconFillColor = styles.colors.primaryColor
    backgroundColor = '#d5d0e3'
  }

  return {
    iconSize,
    iconFillColor,
    backgroundColor,
  }
}

export const CalendarItemContent = (eventInfo) => {
  const calendarItemRef = useRef(null)
  const { event, isPast } = eventInfo
  const { extendedProps, allDay } = event
  const iconPropsArr = getIconProps(extendedProps, isPast)
  const estimatedTimeMin = allDay ? 0 : (new Date(event.end).getTime() - new Date(event.start).getTime()) / (1000 * 60)
  const showHighlightWrapper =
    extendedProps.isHighlighted && [models.item.type.TASK, models.item.type.SPRINT].includes(extendedProps.item.type)
  const highlightWrapperProps = getHighlightedWrapperProps(extendedProps.item, estimatedTimeMin)

  useEffect(() => {
    if (extendedProps.isHighlighted) {
      if (calendarItemRef.current) {
        calendarItemRef.current.parentNode.parentNode.parentNode.style.zIndex = 3
      }
    }
  }, [extendedProps.isHighlighted])

  return (
    <React.Fragment>
      {showHighlightWrapper && <HighlightWrapper {...highlightWrapperProps} />}
      <CalendarItem
        isHighlighted={showHighlightWrapper}
        ref={calendarItemRef}
        style={extendedProps.style}
        className='calendar-day-item-content fs-mask'
        data-is-local-event={extendedProps.isLocalEvent ? 'true' : 'false'}
        data-is-placeholder={extendedProps.item?.isPlaceholder ? 'true' : 'false'}
        data-item-id={extendedProps.item?.id}
        isPast={isPast}
        paddingLeft={4}
        paddingTop={allDay ? 3 : estimatedTimeMin > 29 ? 4 : 0}>
        {iconPropsArr &&
          iconPropsArr.length > 0 &&
          iconPropsArr.map((iconProps, idx) =>
            iconProps.icon ? (
              iconProps.tooltip ? (
                <FixedTooltip
                  content={iconProps.tooltip}
                  key={idx}
                  portalSelector={`#${constants.fixedTooltipContainerPortalId}`}
                  style={{ padding: '8px' }}
                  verticalOffset={-2}>
                  <CalendarItemIcon size={calendarItemFontSize} {...iconProps} />
                </FixedTooltip>
              ) : (
                <CalendarItemIcon key={idx} size={calendarItemFontSize} {...iconProps} />
              )
            ) : (
              <EmojiText key={idx} marginRight={5}>
                {iconProps.emoji}
              </EmojiText>
            )
          )}
        <CalendarItemText> {getTitle(event)}</CalendarItemText>
      </CalendarItem>
    </React.Fragment>
  )
}

const HighlightWrapper = (props) => {
  return (
    <React.Fragment>
      <HighlightWrapperIconContainer backgroundColor={props.backgroundColor} className='fs-mask'>
        <Icon icon={props.iconName || 'Pin'} size={props.iconSize} fill={props.iconFillColor} />
      </HighlightWrapperIconContainer>
      <HighlightWrapperBorder borderColor={props.backgroundColor} />
    </React.Fragment>
  )
}

const CalendarItemIcon = styled(Icon)`
  margin-right: 5px;
  height: ${calendarItemFontSize}px;
  pointer-events: none;
`

const CalendarItem = styled.div`
  font-size: ${calendarItemFontSize}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: 100%;
  border-radius: 5px;
  min-width: 0;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-top: ${(props) => props.paddingTop}px;
`

const CalendarItemText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 100;
  height: 100%;
  line-height: ${calendarItemFontSize}px;
  pointer-events: none;
  margin-top: -1px;
`

const HighlightWrapperIconContainer = styled.div`
  position: absolute;
  top: calc(-5px);
  left: 4px;
  background-color: ${(props) => props.backgroundColor || '#d5d0e3'};
  width: 43px;
  height: 25px;
  max-height: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transform: translateX(-100%);
  display: flex;
  align-items: center;
  justify-content: center;
`

const HighlightWrapperBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  width: calc(100%);
  height: calc(100%);
  border-radius: 5px;
  box-shadow: 0 0 0 5px ${(props) => props.borderColor || '#d5d0e3'};
  filter: drop-shadow(0px 33px 58px rgba(3, 0, 141, 0.17));
`

const EmojiText = styled.div`
  height: 100%;
  line-height: 12px;
  margin-right: 3px;
  pointer-events: none;
`

const getTitle = (event) => {
  if (event.extendedProps.isPlaceholder) {
    return ''
  } else if (event.extendedProps.item.title) {
    return event.extendedProps.item.title
  } else {
    return event.extendedProps.isSprint ? 'Focus Block Title' : translations.task.new
  }
}

export function CalendarHeaderContent({ date, now, onClick }) {
  const dateMoment = moment(date)
  const isPast = dateMoment.isBefore(now, 'day')
  const isToday = dateMoment.isSame(now, 'day')

  return (
    <HeaderCell className='CalendarHeaderContent__cell'>
      <DateContainer isPast={isPast} isToday={isToday}>
        {isToday ? (
          <>
            {moment(date).format('ddd,')}
            <TodayDayIndicator>{moment(date).format('D')}</TodayDayIndicator>
          </>
        ) : (
          moment(date).format('ddd, D')
        )}
      </DateContainer>
      {onClick && <PlanMyDayTrigger onClick={() => onClick(date)}>{translations.planMyDay.daysTasks}</PlanMyDayTrigger>}
    </HeaderCell>
  )
}

const PlanMyDayTrigger = styled.span`
  color: ${styles.colors.primaryColor};
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 100%;
  margin-left: auto;
  margin-right: 8px;
  opacity: 0;
  flex-shrink: 0;
  width: 0;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: underline;
  transition: opacity 300ms ease;
`

PlanMyDayTrigger.displayName = 'PlanMyDayTrigger'

const DateContainer = styled.span`
  align-items: center;
  display: flex;
  opacity: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: opacity 300ms ease;
  margin-right: 10px;

  ${({ isPast, isToday }) =>
    (isPast || isToday) &&
    css`
      color: ${isToday ? styles.colors.primaryColor : styles.colors.darkGrey};
    `}
`

DateContainer.displayName = 'DateContainer'

const TodayDayIndicator = styled.div`
  align-items: center;
  background-color: ${styles.colors.primaryColor};
  border-radius: 50%;
  color: white;
  display: flex;
  flex-shrink: 0;
  font-weight: 500;
  height: 28px;
  justify-content: center;
  line-height: 16px;
  margin-left: 3px;
  width: 28px;
`

TodayDayIndicator.displayName = 'TodayDayIndicator'

const HeaderCell = styled.section`
  align-items: center;
  display: flex;

  :hover {
    ${PlanMyDayTrigger} {
      opacity: 1;
      width: auto;
    }
  }
`

HeaderCell.displayName = 'HeaderCell'

export function MoreLink({ eventsCountForDay = 0, hide, maxEvents, onClick, rowHeight }) {
  const [label, setLabel] = useState('')

  const linkRef = useRef(null)

  useEffect(() => {
    // wait for fullcalendar's components to finish rendering/updating
    setTimeout(() => {
      if (!linkRef.current || hide) return

      const td = linkRef.current.closest('td.fc-daygrid-day')

      if (eventsCountForDay < maxEvents - 1 || !td) {
        setLabel('')
        return
      }

      const cellBounds = td.getBoundingClientRect()
      let visibleElements = 0

      for (let i = 0; i < maxEvents - 1; i++) {
        const elementsInRow = document.elementsFromPoint(
          Math.round(cellBounds.x),
          Math.round(cellBounds.y) + rowHeight * i
        )
        const eventElement = elementsInRow.find((el) => el.classList.contains('fc-daygrid-event-harness'))
        visibleElements = eventElement ? visibleElements + 1 : visibleElements
      }

      const invisibleEvents = eventsCountForDay - visibleElements
      const newLabel = invisibleEvents > 0 ? `${eventsCountForDay - visibleElements} ${translations.general.more}` : ''
      setLabel(newLabel)
    })
  }, [eventsCountForDay, hide, maxEvents, rowHeight])

  return (
    <MoreLinkContainer className={moreLinkContainerClassName} isExpanded={hide} onClick={onClick} ref={linkRef}>
      {label}
    </MoreLinkContainer>
  )
}

export const moreLinkContainerClassName = 'gipsy-more-link'

export const MoreLinkContainer = styled.div`
  background-color: white;
  border-left: 1px solid ${styles.colors.middleGrey};
  bottom: -63px;
  color: ${styles.colors.textMediumDarkColor};
  cursor: pointer;
  display: block;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  height: 20px;
  left: -1px;
  line-height: 14px;
  padding-left: 5px;
  padding-top: 2px;
  position: absolute;
  transition: opacity 300ms ease;
  width: calc(100% + 1px);

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : css`
          opacity: 1;
          pointer-events: all;
        `}
`

MoreLinkContainer.displayName = 'MoreLinkContainer'

export function ShowHideAllDays({ calendarRef, date, eventsCount, isExpanded, maxEvents, onChange }) {
  const [shown, setShown] = useState(false)

  const handleOnClick = () => {
    onChange(!isExpanded)
  }

  useEffect(() => {
    if (!calendarRef?.current) return

    const currentDates = Array.from(calendarRef.current.querySelectorAll('th[data-date]'))
    const shouldShow = currentDates.some((dateNode) => {
      const date = dateNode.getAttribute('data-date')
      return eventsCount[date] >= maxEvents
    })

    setShown(shouldShow)
  }, [calendarRef, date, eventsCount, maxEvents])

  return (
    <HideAllDaysContainer isExpanded={isExpanded}>
      {shown && <StyledArrow icon='SingleChevronLeft' onClick={handleOnClick} size={10} />}
    </HideAllDaysContainer>
  )
}

const StyledArrow = styled(Icon)`
  transform: rotate(180deg);

  path {
    fill: #b2aec2;
  }
`

StyledArrow.displayName = 'StyledArrow'

const HideAllDaysContainer = styled.div`
  left: 8px;
  min-height: 60px;
  position: absolute;
  top: 10.88px;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      min-height: 60px;

      ${StyledArrow} {
        transform: rotate(-90deg);
      }
    `}
`

HideAllDaysContainer.displayName = 'HideAllDaysContainer'
